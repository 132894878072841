<template>
  <div class="drawer_video">
    <div v-show="uploadStep === 1">
      <a-upload
        name="file"
        :multiple="false"
        :showUploadList="false"
        :disabled="videoData.uploading"
        :file-list="file_list"
        :accept="videoAcceptList"
        :customRequest="customRequest"
        :beforeUpload="beforeUploadVideo"
        @change="handleChange"
      >
        <a-button type="link" @click="handleTrack('upload_video', { source: '本地上传' })"> 本地上传 </a-button>
      </a-upload>
      <div class="tips">
        <div class="tips_text">请添加视频</div>
        <div class="tips_row">
          <div class="tips_items">
            <p class="items_title">视频大小</p>
            <p class="items_text">支持时长15分钟以内，</p>
            <p class="items_text">最大10GB的视频文件</p>
          </div>
          <div class="tips_items" style="margin: 0 5px">
            <p class="items_title">视频格式</p>
            <p class="items_text">支持常用视频格式，</p>
            <p class="items_text">推荐使用mp4、mov</p>
          </div>
          <div class="tips_items">
            <p class="items_title">视频分辨率</p>
            <p class="items_text">推荐上传720P（1280*720）及以上的视频</p>
          </div>
        </div>
      </div>
    </div>
    <div v-show="uploadStep === 2">
      <a-upload
        v-show="!videoData.uploading && !videoData.isFail"
        :showUploadList="false"
        name="file"
        :multiple="false"
        :disabled="videoData.uploading"
        :file-list="file_list"
        accept=".mp4, .mov"
        :customRequest="customRequest"
        :beforeUpload="beforeUploadVideo"
        @change="handleChange"
      >
        <a-button type="link" icon="redo"> 重新上传 </a-button>
      </a-upload>
      <div class="cover_item" @mouseenter="videoData.showHover = true" @mouseleave="videoData.showHover = false">
        <!-- 上传中 -->
        <template v-if="videoData.uploading">
          <a-progress type="circle" style="margin: 20px auto 0" :width="80" :percent="progress" />
          <a-button type="link" @click="handleCancelUpload">取消上传</a-button>
        </template>
        <!-- 上传失败 -->
        <template v-if="videoData.isFail">
          <div class="close_btn" @click="handleCloseUpload">
            <a-icon type="close" />
          </div>
          <div style="margin-top: 60px; text-align: center">上传失败</div>
          <a-upload
            name="file"
            :multiple="false"
            :disabled="videoData.uploading"
            :file-list="file_list"
            accept=".mp4, .mov"
            :customRequest="customRequest"
            :beforeUpload="beforeUploadVideo"
            @change="handleChange"
          >
            <a-button type="link" icon="redo"> 重新上传 </a-button>
          </a-upload>
        </template>
        <template v-if="!videoData.uploading && !videoData.isFail">
          <img class="cover_img" :src="videoData.cover" referrerpolicy="no-referrer" />
          <div class="hover_item" v-show="videoData.showHover">
            <div class="hover_item_top" @click="handleCloseUpload">
              <a-icon type="close" style="color: #fff" />
            </div>
            <div class="hover_item_bottom" @click="showCoverModal = true">编辑封面</div>
          </div>
        </template>
      </div>
    </div>

    <a-modal
      title="设置封面"
      width="1085px"
      :dialog-style="{ top: '10px' }"
      :visible="showCoverModal"
      @cancel="handleModalClose"
    >
      <a-tabs default-active-key="2" style="margin-top: -25px" :activeKey="tabKey" @change="handleTabChange">
        <a-tab-pane key="1" tab="截取封面"></a-tab-pane>
        <a-tab-pane key="2" tab="上传封面"></a-tab-pane>
      </a-tabs>
      <a-steps progress-dot :current="cutStep">
        <a-step :key="1">
          <template slot="title"> 选取 </template>
        </a-step>
        <a-step title="裁剪" :key="2" />
      </a-steps>
      <div style="display: flex; flex-direction: column" v-show="cutStep == 0 && tabKey == '1'">
        <div class="video_screen_shot">
          <div style="text-align: center">
            <video
              id="screenshot_video"
              style="max-width: 1024px; max-height: 675px"
              :src="videoData.url"
              crossOrigin="anonymous"
              preload="auto"
              controls
            >
              您的浏览器不支持 video 标签。
            </video>
          </div>
          <div style="display: flex; justify-content: center">
            <a-button type="primary" style="margin: 0 10px; width: 200px" @click="handleScreenshot()">截取</a-button>
            <a-button
              v-if="!limited"
              type="primary"
              style="margin: 0 10px; width: 200px"
              @click="handleScreenshot(true)"
              :loading="editLoading"
              >截取并编辑</a-button
            >
          </div>
        </div>
        <canvas v-if="cutStep == 0" id="frameCanvas" style="display: none"></canvas>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center" v-show="tabKey == '2'">
        <a-space>
          <a-upload
            v-show="cutStep == 0"
            name="file"
            accept=".jpg, .jpeg, .png"
            :showUploadList="false"
            :customRequest="localRequest"
          >
            <a-button type="primary">本地上传</a-button>
          </a-upload>
          <a-button v-show="!limited && cutStep == 0" type="primary" @click="showMyPicStoreModal = true"
            >从我的图库添加</a-button
          >
        </a-space>
      </div>
      <div style="text-align: center" v-show="cutStep == 1">
        <vueCropper
          ref="cropper"
          style="width: 630px; height: 385px; margin: 0 auto"
          :img="option.imgUrl"
          :output-size="option.size"
          :output-type="option.outputType"
          :info="false"
          :full="option.full"
          :fixed="option.fixed"
          :fixed-number="option.fixedNumber"
          :can-move="option.canMove"
          :can-move-box="option.canMoveBox"
          :fixed-box="option.fixedBox"
          :original="option.original"
          :auto-crop="option.autoCrop"
          :auto-crop-width="option.autoCropWidth"
          :auto-crop-height="option.autoCropHeight"
          :center-box="option.centerBox"
          :high="option.high"
          :mode="option.mode"
          :max-img-size="option.max"
          :info-true="option.infoTrue"
        ></vueCropper>
        <a-button style="margin: 5px auto 0; width: 200px" @click="cutStep = 0">上一步</a-button>
      </div>
      <div v-show="cutStep == 1">
        <a-divider>裁剪比例</a-divider>
        <a-space size="large">
          <div class="cut_btn" style="width: 40px; height: 52px; line-height: 52px" @click="setFixNumber(originFixed)">
            原始
          </div>
          <div class="cut_btn" style="width: 52px; height: 52px; line-height: 52px" @click="setFixNumber([1, 1])">
            1:1
          </div>
          <div class="cut_btn" style="width: 40px; height: 52px; line-height: 52px" @click="setFixNumber([3, 4])">
            3:4
          </div>
          <div class="cut_btn" style="width: 52px; height: 40px; line-height: 40px" @click="setFixNumber([4, 3])">
            4:3
          </div>
        </a-space>
      </div>
      <template #footer>
        <a-button key="cancel" @click="handleModalClose">取消</a-button>
        <a-button key="ok" type="primary" :disabled="cutStep != 1" :loading="cutLoading" @click="handleModalOk"
          >确定</a-button
        >
      </template>
    </a-modal>

    <!-- 我的图库弹窗 -->
    <MyPicStoreModal
      v-if="showMyPicStoreModal"
      :isReplace="true"
      @addPic="handleAddPic"
      @closeStoreModal="showMyPicStoreModal = false"
    />
  </div>
</template>

<script>
import SparkMD5 from 'spark-md5';
import ossUploadMixin from '@/components/BaseUpload/ossUploadMixin.js';
import { VueCropper } from 'vue-cropper';
import api from '@/api/xhsAgencyApi';
import { trackRequest } from '@/utils/track';
import { base64ToFile } from '@/utils/util.js';
import MyPicStoreModal from './MyPicStoreModal.vue';

export default {
  name: 'drawerVideoComp',
  props: {
    limited: {
      default: false,
    },
  },
  data() {
    const videoAcceptList = '.mp4, .mov, .lfv, .f4v, .mkv, .rm, .rmvb, .m4v, .mpg, .mpeg,';
    return {
      videoAcceptList,
      uploadStep: 1,
      file_list: [],
      videoData: {
        uploading: false,
        isFail: false,
        cover: '',
        url: '',
        showHover: false,
      },
      showCoverModal: false,
      confirmLoading: false,
      cutStep: 0,
      option: {
        imgUrl: '',
        autoCrop: true,
        autoCropWidth: 750,
        autoCropHeight: 340,
        canMoveBox: true,
        canMove: true,
        centerBox: true,
        size: 1,
        full: true,
        outputType: 'png',
        fixed: true,
        fixedBox: false,
        fixedNumber: [3, 4],
        original: false,
        info: false,
        high: false,
        mode: 'contain',
        max: 99999,
        infoTrue: true,
      },
      originFixed: [3, 4],
      tabKey: '1',
      cutLoading: false,
      editLoading: false,
      showMyPicStoreModal: false,
    };
  },
  mixins: [ossUploadMixin],
  components: {
    VueCropper,
    MyPicStoreModal,
  },
  methods: {
    handleTabChange(val) {
      this.tabKey = val;
      this.cutStep = 0;
    },
    handleModalOk() {
      this.$refs.cropper.getCropData(async (data) => {
        this.cutLoading = true;
        const file = await base64ToFile(data);
        try {
          this.normalUploadFile(
            file,
            ({ data }) => {
              this.videoData.cover = data.data.fileUrl;
              this.handleModalClose();
              this.$emit('handleDataChange');
            },
            false
          );
        } catch {
          this.cutLoading = false;
        }
      });
    },
    handleModalClose() {
      this.showCoverModal = false;
      this.tabKey = '1';
      this.originFixed = [3, 4];
      this.cutStep = 0;
      this.cutLoading = false;
    },
    setFixNumber(fixNUmber) {
      this.option.fixedNumber = fixNUmber;
      this.$nextTick(() => {
        this.$refs.cropper.goAutoCrop();
      });
    },
    handleScreenshot(isEdit = false) {
      const videoDom = document.getElementById('screenshot_video');
      videoDom.setAttribute('crossOrigin', 'anonymous'); //解决跨域报错问题,可以在video上加该标签
      videoDom.pause();
      const canvas = document.getElementById('frameCanvas'); // 获取画布元素
      canvas.width = videoDom.clientWidth;
      canvas.height = videoDom.clientHeight;
      this.option.fixedNumber = videoDom.clientWidth > videoDom.clientHeight ? [4, 3] : [3, 4];
      this.originFixed = this.option.fixedNumber;
      canvas.getContext('2d').drawImage(videoDom, 0, 0, videoDom.clientWidth, videoDom.clientHeight);
      const dataUrl = canvas.toDataURL('image/png'); // 转base64
      if (isEdit) {
        this.handleCropEdit(dataUrl);
      } else {
        this.cutStep = 1;
        this.option.imgUrl = dataUrl;
      }
    },
    async handleCropEdit(data) {
      this.editLoading = true;
      const file = await base64ToFile(data);
      try {
        this.normalUploadFile(
          file,
          async ({ data }) => {
            await this.handleEditJump(data.data.fileUrl)
              .then((url) => {
                window.open(url, '_blank');
                this.handleTabChange('2');
              })
              .finally(() => (this.editLoading = false));
          },
          false
        );
      } catch {
        this.editLoading = false;
      }
    },
    handleEditJump(imageUrlList) {
      return new Promise((resolve, reject) => {
        api.saveImageList({ configType: 'imageEdit', data: { imageUrlList: [imageUrlList] } }).then((res) => {
          if (res.code == 200) {
            resolve(`${process.env.VUE_APP_TEMPLATE_EDITOR}?localId=${res.data}`);
          } else {
            this.$message.error(`提交图片数据失败，${res.message}`);
            reject();
          }
        });
      });
    },
    localRequest({ file }) {
      this.cutStep = 0;
      this.option.imgUrl = '';
      const _this = this;
      const reader = new FileReader();
      reader.onload = function (e) {
        const imageDataURL = e.target.result;
        _this.cutStep = 1;
        _this.option.imgUrl = imageDataURL;
      };
      reader.readAsDataURL(file);
    },
    async customRequest(option) {
      const { file, onSuccess } = option;
      await this.multipartUpload(file, onSuccess);
    },
    handleCancelUpload() {
      this.cancelCurrentUpload();
      this.handleCloseUpload();
    },
    handleCloseUpload() {
      this.uploadStep = 1;
      this.file_list = [];
      this.videoData = {
        uploading: false,
        isFail: false,
        cover: '',
        url: '',
        showHover: false,
      };
      this.$emit('handleDataChange');
    },
    async beforeUploadVideo(file) {
      let fileType = /\.(mp4|mov)$/i;
      if (!fileType.test(file.name)) {
        this.$message.error('仅支持视频文件');
        return new Promise((resolve, reject) => {
          reject();
        });
      }
      const isOverLong = await this.testVideoLong(file);
      if (!isOverLong) {
        this.$message.error(`视频不得超过15分钟`);
        return new Promise((resolve, reject) => {
          reject();
        });
      }
      return new Promise((resolve, reject) => {
        const isLimit = file.size / 1024 / 1024 / 1024 < 10;
        if (!isLimit) {
          this.$message.error(`上传文件不超过10GB!`);
          return false;
        }

        let fileReader = new FileReader();
        let spark = new SparkMD5.ArrayBuffer(); //获取文件二进制数据
        fileReader.readAsArrayBuffer(file); //file就是获取到的文件 //异步执行函数
        fileReader.onload = function (e) {
          spark.append(e.target.result);
          let md5 = spark.end();
          file.md5 = md5;
          file.params_data = {
            appId: 'bfz',
            filePath: '/bfz/xhs/',
            identifier: file.md5,
            name: file.name,
            title: file.name.split('.')[0],
          };
          return resolve(true);
        };
      });
    },
    async handleChange(info) {
      if (info.file.status) {
        this.file_list = info.fileList;
        this.file_list = this.file_list.slice(-1);
        if (info.file.status === 'uploading') {
          this.videoData.uploading = true;
          this.uploadStep = 2;
          this.file_list.forEach((val) => {
            if (val.fileId === info.file.fileId) {
              val.status = 'uploading';
            }
          });
        }

        if (info.file.status === 'done') {
          let fileList = [...info.fileList];
          fileList = fileList.map((file) => {
            if (file.response) {
              file.fileId = file.uid;
              file.url = file.response.cdnUrl;
              file.status = 'done';
            }
            return file;
          });
          this.file_list = fileList;
          const cover = `${fileList[0].url}?x-oss-process=video/snapshot,t_1000`;
          this.videoData.url = fileList[0].url;
          this.videoData.cover = cover;
          this.videoData.uploading = false;
          this.videoData.isFail = false;
          this.$emit('handleDataChange');
          //   const base64 = await this.getVideoScreenShot(fileList[0].url); // 获取首帧封面
          //   const file = await base64ToFile(base64); // base64转file格式
          //   // 封面转url
          //   this.normalUploadFile(
          //     file,
          //     ({ data }) => {
          //       this.videoData.cover = data.data.fileUrl;
          //       this.videoData.uploading = false;
          //       this.videoData.isFail = false;
          //       this.videoData.url = fileList[0].url;
          //     },
          //     false
          //   );
        }

        if (info.file.status === 'error') {
          this.videoData.uploading = false;
          this.videoData.isFail = false;
          this.$message.error(`${info.file.name} 文件上传失败`);
        }
      } else {
        this.file_list = [];
        this.videoData.uploading = false;
        return;
      }
    },
    // 获取视频首帧
    getVideoScreenShot(url) {
      let video = document.createElement('video');
      video.style = 'position:fixed; top: 9999px;left:9999px;z-index:-9999';
      video.src = url;
      video.setAttribute('crossOrigin', 'anonymous');
      video.setAttribute('preload', 'auto');
      video.setAttribute('autoplay', true);
      video.setAttribute('muted', true);
      video.preload = 'auto';
      video.autoplay = true;
      video.muted = true;
      video.currentTime = 1; //截取的视频第一秒作为图片
      document.body.appendChild(video);
      let cover = '';
      return new Promise((resolve) => {
        video.onloadeddata = function () {
          let canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext('2d').drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
          cover = canvas.toDataURL('image/png');
          resolve(cover);
        };
      });
    },
    testVideoLong(file) {
      return new Promise((resolve) => {
        let _URL = window.URL || window.webkitURL;
        let videoElement = document.createElement('video');
        videoElement.addEventListener('loadedmetadata', function (_event) {
          let duration = videoElement.duration; // 视频时长
          if (Math.floor(duration) >= 60 * 15) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
        videoElement.src = _URL.createObjectURL(file);
      });
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
    handleAddPic(picList) {
      this.cutStep = 1;
      this.option.imgUrl = picList[0].fileUrl;
    },
  },
};
</script>

<style lang="less" scoped>
.drawer_video {
  padding: 10px;
}

.tips {
  .tips_text {
    height: 80px;
    line-height: 80px;
    text-align: center;
    color: #33333340;
    letter-spacing: 2px;
    font-weight: 600;
  }

  .tips_row {
    display: flex;
    margin-bottom: 20px;

    .tips_items {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 110px;
      line-height: 11px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #eaeaea;

      .items_title {
        font-weight: 600;
        font-size: 12px;
      }

      .items_text {
        font-weight: 500;
        font-size: 12px;
        color: #acacac;
      }
    }
  }
}

.cover_item {
  position: relative;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  width: 120px;
  height: 160px;
  background-color: #f2f2f2;

  .close_btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background-color: #c2c2c2;
    cursor: pointer;
  }

  .cover_img {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }

  .hover_item {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    max-height: 200px;
    border: 2px #cccccc solid;
    background-color: #0000004b;

    .hover_item_top {
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border-radius: 0 0 0 5px;
      background-color: #444d54;
      cursor: pointer;
    }

    .hover_item_bottom {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      background-color: #33333345;
      cursor: pointer;
    }
  }
}

.video_screen_shot {
  display: flex;
  flex-direction: column;
}

.cut_btn {
  color: #ff2442;
  text-align: center;
  border: 1px solid #ff2442;
  cursor: pointer;
  user-select: none;
}

#screenshot_video {
  // 全屏按钮
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  //音量按钮
  video::-webkit-media-controls-mute-button {
    display: none;
  }
  // 画中画
  video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
  }
  //音量的控制条
  video::-webkit-media-controls-volume-slider {
    display: none;
  }
}
</style>
