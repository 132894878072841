<template>
  <div class="WritingAssistantComp">
    <div style="display: flex; justify-content: space-between">
      <a-button icon="loading-3-quarters" type="link" @click="getDataList">换一批</a-button>
      <a-button icon="double-left" type="link" @click="$emit('close')">收起</a-button>
    </div>
    <div style="margin-bottom: 5px">
      <BrandSelectInput
        style="width: 47%"
        placeholder="品牌"
        v-model="principalIdListData"
        show-search
        option-filter-prop="children"
        @onChange="getDataList"
        allowClear
      />
      <CarSeriesSelectInput
        style="width: 48%"
        :principalIds="principalIds"
        :disabled="!principalIdListData || !principalIdListData.length"
        v-model="carSeriesIdListData"
        placeholder="车系"
        show-search
        :filter-option="false"
        @onChange="getDataList"
        allowClear
      />
    </div>
    <a-input-search
      style="width: 95%; margin-bottom: -5px"
      placeholder="搜索笔记标题、正文"
      enter-button
      v-model="searchKeyword"
      allowClear
      @search="getDataList"
    />
    <a-tabs default-active-key="1" @change="handleTabChange">
      <a-tab-pane key="1" tab="运营爆文"></a-tab-pane>
      <a-tab-pane key="2" tab="文案模版"></a-tab-pane>
      <a-tab-pane key="3" tab="平台爆款"></a-tab-pane>
    </a-tabs>
    <WritingTemplateComp
      v-show="currentTab == 2"
      ref="WritingTemplateComp"
      :tagId="tagId"
      :searchKeyword="searchKeyword"
      :principalIdListData="principalIdListData"
      :carSeriesIdListData="carSeriesIdListData"
      @useTemplate="useTemplate"
    />
    <SuggestListComp
      v-show="currentTab == 1 || currentTab == 3"
      ref="SuggestListComp"
      :tagId="tagId"
      :searchKeyword="searchKeyword"
      :principalIdListData="principalIdListData"
      :carSeriesIdListData="carSeriesIdListData"
      @handleRewrite="handleRewrite"
      @close="currentBtn = undefined"
    />
  </div>
</template>

<script>
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';

import WritingTemplateComp from './WrittingTemplateComp';
import SuggestListComp from './SuggestListComp';
export default {
  name: 'WritingAssistantComp',
  props: {
    tagId: {
      default: undefined,
    },
  },
  components: {
    BrandSelectInput,
    CarSeriesSelectInput,
    WritingTemplateComp,
    SuggestListComp,
  },
  data() {
    return {
      currentTab: '1',
      searchKeyword: null,
      principalIdListData: [],
      carSeriesIdListData: [],
    };
  },
  computed: {
    principalIds() {
      if (!this.principalIdListData) return [];
      const isArray = Array.isArray(this.principalIdListData);
      let principalIdListData = this.principalIdListData;
      if (!isArray) {
        principalIdListData = [this.principalIdListData];
      }
      return principalIdListData.length > 0
        ? principalIdListData.map((item) => {
            const arr = item.split(',');
            return arr.length > 0 ? arr[0] : '';
          })
        : [];
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    init() {
      if (this.$refs.SuggestListComp) {
        this.$refs.SuggestListComp.listData = [];
        this.$refs.SuggestListComp.topicSelectionType = 'OPERATE';
        this.$refs.SuggestListComp.getDataList();
      }
    },
    handleTabChange(val) {
      this.currentTab = val;
      if (val == '1') {
        this.$refs.SuggestListComp.listData = [];
        this.$refs.SuggestListComp.topicSelectionType = 'OPERATE';
        this.$refs.SuggestListComp.getDataList();
      }
      if (val == '2') {
        this.$refs.WritingTemplateComp.getDataList();
      }
      if (val == '3') {
        this.$refs.SuggestListComp.listData = [];
        this.$refs.SuggestListComp.topicSelectionType = 'PLATFORM';
        this.$refs.SuggestListComp && this.$refs.SuggestListComp.getDataList();
      }
    },
    getDataList() {
      if (!this.principalIdListData || !this.principalIdListData.length) {
        this.carSeriesIdListData = [];
      }
      this.$nextTick(() => {
        this.handleTabChange(this.currentTab);
      });
    },
    useTemplate(val) {
      this.$emit('useTemplate', val);
    },
    handleRewrite(noteId) {
      this.$emit('handleRewrite', noteId);
    },
  },
};
</script>

<style lang="less" scoped></style>
