<template>
  <div class="ai_writing_drawer">
    <a-drawer class="ai_drawer" title="Ai作文 Pro" width="1181" placement="right" :visible="show" @close="show = false">
      <div class="switch_btn">
        <a-button
          type="link"
          icon="swap"
          size="small"
          style="position: absolute; top: -48px; left: 80px"
          @click="handleSwitchType"
          >{{ params.writingStyle == 'styleType' ? '结构作文' : '文风作文' }}</a-button
        >
      </div>

      <div style="display: flex; height: 91vh; min-height: 700px">
        <div class="drawer_left">
          <div class="left_container">
            <template v-if="params.writingStyle === 'structType'">
              <template>
                <div class="item_title" style="margin-top: 5px">内容方向<span style="color: #d9011c">*</span></div>
                <div>
                  <span
                    :class="['select_item', params.contentDirection === v.value ? 'select_item_active' : '']"
                    v-for="(v, i) in directionList"
                    :key="i"
                    @click="handleDirectionClick(v)"
                    >{{ v.name }}</span
                  >
                </div>
              </template>
              <template v-if="params.contentDirection === 'SUBSTANCE'">
                <div class="item_title">品牌</div>
                <BrandSelectInput
                  style="width: 100%"
                  placeholder="不限品牌"
                  v-model="params.principalIdListData"
                  show-search
                  option-filter-prop="children"
                  allowClear
                  @onChange="handleSubBrandChange"
                />
                <div style="display: flex; align-items: center; color: #7f7f7f">
                  <a-icon type="question-circle" style="margin-right: 5px" />不选品牌则生产通用干货
                </div>
                <div class="item_title">车系</div>
                <CarSeriesSelectInput
                  style="width: 100%"
                  :principalIds="principalIds"
                  v-model="params.carSeriesIdListData"
                  placeholder="不限车系"
                  show-search
                  :filter-option="false"
                  allowClear
                  @onChange="handleSubSeriesChange"
                />
              </template>
              <template v-if="params.contentDirection !== 'SUBSTANCE'">
                <div class="item_title">车系<span style="color: #d9011c">*</span></div>
                <a-select
                  style="width: 100%"
                  :disabled="limited"
                  v-model="params.carSeriesId"
                  placeholder="选择车系"
                  @change="handleSeriesChange"
                >
                  <a-select-option v-for="v in carSeriesList" :key="v.carSeriesId" :value="v.carSeriesId">{{
                    v.carSeriesName
                  }}</a-select-option>
                </a-select>
              </template>
              <template v-if="params.contentDirection !== 'SUBSTANCE'">
                <div class="item_title">
                  文章结构
                  <a-tooltip placement="right">
                    <template slot="title">
                      <div>可拖拽标签调整位置</div>
                    </template>
                    <a-icon type="question-circle" style="margin-left: 5px" />
                  </a-tooltip>
                </div>
                <div style="margin-bottom: 10px; border: 1px solid #d9d9d9; padding: 10px">
                  <span
                    v-show="currentConstructTags.length >= 1 && !showConstructEdit"
                    id="container"
                    ref="sortableBox"
                  >
                    <span v-for="(v, i) in currentConstructTags" :key="v.id">
                      <a-tag
                        color="blue"
                        style="margin: 8px 0 5px; user-select: none; cursor: grab"
                        :closable="currentConstructTags.length > 1"
                        @close="() => handleConstructTagDel(v)"
                      >
                        {{ v.articleStructureTagName }}
                      </a-tag>
                      <span v-show="i !== currentConstructTags.length - 1" class="sort_filter"> + </span>
                    </span>
                  </span>
                  <div style="display: flex; align-items: center" v-if="showConstructEdit">
                    <a-select
                      style="width: 300px"
                      mode="multiple"
                      v-model="currentConstructTagsIds"
                      @blur="handleConstructCancel"
                    >
                      <a-select-option v-for="item in constructList" :key="item.id" :value="item.id">
                        {{ item.articleStructureTagName }}
                      </a-select-option>
                    </a-select>
                    <a-button shape="circle" type="link" icon="close" @click="handleConstructCancel"></a-button>
                    <a-button shape="circle" type="link" icon="check" @click="handleConstructConfirm"></a-button>
                  </div>
                  <a-tag
                    class="sort_filter"
                    v-if="currentConstructTags.length < 7 && !showConstructEdit"
                    style="margin-left: 10px; user-select: none; cursor: pointer; border-style: dashed"
                    @click="handleConstructTagEdit"
                  >
                    &nbsp;&nbsp;+&nbsp;&nbsp;
                  </a-tag>
                </div>
                <div v-show="showPriceTips" style="margin: -10px 0 10px; color: #8e8e8e; font-size: 13px">
                  <a-icon
                    type="question-circle"
                    style="margin-right: 5px"
                  />产品报价参考汽车之家该车系在售车型的最低经销商价
                </div>
              </template>
              <template>
                <div class="item_title">
                  主题<span style="color: #d9011c" v-show="params.contentDirection === 'SUBSTANCE'">*</span>
                </div>
                <div>
                  <div style="position: relative">
                    <a-textarea
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                      :maxLength="40"
                      placeholder="输入文章的主题，可包含营销节点，如“国庆节全家出行”"
                      :disabled="gptLoading"
                      v-model.trim="params.topic"
                      style="position: relative; padding-bottom: 20px"
                    >
                    </a-textarea>
                    <span style="position: absolute; top: -23px; right: 0px; color: #848484; user-select: none"
                      >{{ (params.topic && params.topic.length) || 0 }}/40</span
                    >
                    <div :class="['gpt_btn', { loading: gptLoading }]" @click="handleGptClick(params.topic)">
                      <img class="icons" src="@/assets/icon/chatgpt.svg" alt="chatgpt" />
                      <span style="margin-left: 5px">营销润色</span>
                    </div>
                  </div>
                  <template v-if="params.carSeriesId || params.contentDirection === 'SUBSTANCE'">
                    <div style="color: #b2b2b2" v-show="gptLoading"><a-icon type="loading" /> 主题生成中...</div>
                    <div style="display: flex" v-show="!gptLoading">
                      <span style="width: 85px; flex-shrink: 0; color: #b2b2b2"
                        >营销节点<a-tooltip placement="top">
                          <template slot="title">
                            <div>根据营销日历推荐</div>
                          </template>
                          <a-icon type="question-circle" style="margin-left: 5px" /></a-tooltip
                      ></span>
                      <span style="display: flex; flex-wrap: wrap">
                        <span
                          v-for="(h, i) in holidayList"
                          class="addition_tag"
                          :key="i"
                          @click="handleGptClick(h.dateName)"
                          >+&nbsp;{{ h.showName }}&nbsp;</span
                        >
                      </span>
                    </div>
                  </template>
                </div>
              </template>
              <template v-if="params.contentDirection !== 'SUBSTANCE'">
                <div class="item_title">适用人群</div>
                <div style="position: relative">
                  <a-select
                    ref="peopleSelect"
                    mode="tags"
                    style="width: 100%"
                    placeholder="该车面向的人群，如“宝爸”、“小家庭”，可自定义输入"
                    :maxTagTextLength="20"
                    :open="showPeopleDrawdown"
                    @focus="showPeopleDrawdown = true"
                    @blur="showPeopleDrawdown = false"
                    @select="showPeopleDrawdown = false"
                    v-model="params.suitablePeople"
                    @search="peopleInputKeydown"
                    @change="handlePeopleChange"
                  >
                    <a-select-option v-for="(v, i) in peopleList" :key="i" :value="v">
                      {{ v }}
                    </a-select-option>
                  </a-select>
                  <span
                    style="
                      position: absolute;
                      top: -23px;
                      right: 0;
                      color: #848484;
                      user-select: none;
                      background-color: #fff;
                    "
                    >{{ currentPeopleLength >= 0 ? currentPeopleLength : 0 }}/40</span
                  >
                </div>
              </template>
              <template v-if="params.contentDirection !== 'SUBSTANCE'">
                <div class="item_title">适用场景</div>
                <div style="position: relative">
                  <a-select
                    ref="sceneSelect"
                    class="sceneSelect"
                    mode="tags"
                    style="width: 100%"
                    placeholder="该车的适用场景，如“一家四口出行”，可自定义输入"
                    :maxTagTextLength="40"
                    :disabled="picAiLoading"
                    :open="showSceneDrawdown"
                    @focus="showSceneDrawdown = true"
                    @blur="showSceneDrawdown = false"
                    @select="showSceneDrawdown = false"
                    v-model="params.applicableScene"
                    @search="senseInputKeydown"
                    @change="handleSenseChange"
                  >
                    <a-select-option v-for="(v, i) in senseList" :key="i" :value="v">
                      {{ v }}
                    </a-select-option>
                  </a-select>
                  <span
                    style="
                      position: absolute;
                      top: -23px;
                      right: 0;
                      color: #848484;
                      user-select: none;
                      background-color: #fff;
                    "
                    >{{ currentSenseLength >= 0 ? currentSenseLength : 0 }}/40</span
                  >
                  <div style="position: absolute; bottom: 5px; left: 5px; user-select: none">
                    <div v-show="!picAiLoading">
                      <a-upload
                        name="file"
                        :multiple="false"
                        :showUploadList="false"
                        :file-list="file_list"
                        :accept="imgAcceptList"
                        :customRequest="customRequest"
                        :beforeUpload="beforeUploadImg"
                        @change="handleUploadChange"
                      >
                        <div ref="uploadRef"></div>
                      </a-upload>
                      <a-button icon="cloud-upload" @click="handleUploadClick">上传图片识别场景</a-button>
                    </div>
                    <div v-show="picAiLoading">
                      <a-space>
                        <template v-if="picAiLoading && progress">
                          <span style="color: #818181">图片上传中</span><span style="color: #000">{{ progress }}%</span
                          ><a-button type="link" @click="handlePicCancel">取消</a-button>
                        </template>
                        <template v-if="picAiLoading && !progress">
                          <a-icon type="loading" /><span style="margin-left: 5px; color: #818181">处理中...</span>
                        </template>
                      </a-space>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <template v-if="params.writingStyle === 'styleType'">
              <div class="item_title">文风模式<span style="color: #d9011c">*</span></div>
              <template>
                <div class="item_title">车系<span style="color: #d9011c">*</span></div>
                <a-select
                  style="width: 100%"
                  :disabled="limited"
                  v-model="params.carSeriesId"
                  placeholder="选择车系"
                  @change="handleSeriesChange"
                >
                  <a-select-option v-for="v in carSeriesList" :key="v.carSeriesId" :value="v.carSeriesId">{{
                    v.carSeriesName
                  }}</a-select-option>
                </a-select>
              </template>
              <div class="writer-row">
                <span
                  :class="['writer-column', { 'active-column': params.writingStyleId == val.id }]"
                  v-for="val in writingStyleList"
                  :key="val.id"
                  @click="params.writingStyleId = val.id"
                >
                  <a-badge>
                    <a-tooltip slot="count" placement="rightBottom">
                      <template slot="title">
                        <div style="white-space: break-spaces">{{ val.example }}</div>
                      </template>
                      <a-icon
                        v-if="val.example"
                        type="info-circle"
                        theme="filled"
                        style="color: #58a0ff; background: #ffffff"
                      />
                    </a-tooltip>
                    <div style="width: 100%; height: 100%; padding: 5px 15px">
                      <img class="avatar" referrerpolicy="no-referrer" :src="val.roleAvatar" alt="avatar" />
                      <span>{{ val.role }}</span>
                    </div>
                  </a-badge>
                </span>
              </div>
            </template>
            <template v-if="showSellPoint">
              <div class="item_title">
                卖点<a-icon
                  v-show="sellingPointList.length"
                  style="margin-left: 10px; color: #1890ff; user-select: none; cursor: pointer"
                  type="arrows-alt"
                  @click="openSellingDetail('sell', {})"
                />
              </div>
              <div style="display: flex; flex-wrap: wrap; border: 1px solid #d9d9d9; padding: 10px 10px 5px">
                <a-tag
                  style="margin-bottom: 5px; user-select: none; letter-spacing: 1px; white-space: normal"
                  color="#6a9fff"
                  v-for="v in sellingPointList"
                  :key="v.id"
                  closable
                  @close="() => handleSellTagClose(v)"
                  >{{ v.productFeature }}</a-tag
                ><a-tag
                  style="margin-bottom: 5px; user-select: none; cursor: pointer; border-style: dashed"
                  v-show="sellingPointList.length < 5"
                  @click="handleAddSellPoint"
                >
                  &nbsp;&nbsp;+&nbsp;&nbsp;
                </a-tag>
                <a-tooltip placement="right">
                  <template slot="title">
                    <div>清空卖点</div>
                  </template>
                  <a-button
                    v-show="sellingPointList.length"
                    type="link"
                    icon="delete"
                    style="line-height: 20px; height: 20px; color: #7f7f7f"
                    @click="sellingPointList = []"
                  ></a-button>
                </a-tooltip>
              </div>
              <template v-if="sellingPointList.length < 5 && params.writingStyle === 'structType'">
                <div style="color: #b2b2b2" v-show="suggestSearchLoading"><a-icon type="loading" /> 智能推荐中...</div>
                <div
                  style="display: flex"
                  v-show="!suggestSearchLoading && params.applicableScene && params.carSeriesId"
                >
                  <span style="width: 85px; flex-shrink: 0; color: #b2b2b2"
                    >智能推荐<a-tooltip placement="top">
                      <template slot="title">
                        <div>根据适用场景和车系推荐</div>
                      </template>
                      <a-icon type="question-circle" style="margin-left: 5px" /></a-tooltip
                  ></span>
                  <span style="display: flex; flex-wrap: wrap">
                    <span
                      v-for="(p, i) in suggestSellPointList"
                      class="addition_tag"
                      :key="i"
                      @click="handleAdditionSellPointClick(p)"
                      >+&nbsp;{{ p.productFeature }}&nbsp;</span
                    >
                  </span>
                </div>
              </template>
            </template>
            <template v-if="showActivity">
              <div class="item_title">
                优惠活动<a-icon
                  v-show="activityList.length"
                  style="margin-left: 10px; color: #1890ff; user-select: none; cursor: pointer"
                  type="arrows-alt"
                  @click="openSellingDetail('activity', {})"
                />
              </div>
              <div style="display: flex; flex-wrap: wrap; border: 1px solid #d9d9d9; padding: 10px 10px 5px">
                <a-tag
                  style="margin-bottom: 5px; user-select: none; letter-spacing: 1px; white-space: normal"
                  color="#87d068"
                  v-for="v in activityList"
                  :key="v.id"
                  closable
                  @close="() => handleActivityTagClose(v)"
                  >{{ v.promotionName }}</a-tag
                ><a-tag
                  style="margin-bottom: 5px; user-select: none; cursor: pointer; border-style: dashed"
                  v-show="activityList.length < 5"
                  @click="handleAddActivity"
                >
                  &nbsp;&nbsp;+&nbsp;&nbsp;
                </a-tag>
              </div>
            </template>
          </div>
          <div class="footer_bar">
            <div v-if="params.carSeriesId || params.contentDirection === 'SUBSTANCE'">
              <a-button type="primary" :loading="submitLoading" @click="handleSubmitClick">生成文案</a-button>
              <a-input
                type="number"
                :min="1"
                :max="5"
                addon-before="生成数"
                style="margin-left: 5px; width: 130px"
                v-model="submitNum"
                @blur="handleNumCheck"
              ></a-input>
            </div>
            <div v-else>
              <a-tooltip placement="top" title="先完善车系">
                <a-button type="primary" disabled>生成文案</a-button>
              </a-tooltip>
            </div>
            <a-tooltip placement="top" title="清空输入项">
              <a-icon type="delete" style="color: #1890ff; cursor: pointer" @click="handleInitParams" />
            </a-tooltip>
          </div>
        </div>

        <!-- drawer_right -->
        <AIWritingListComp
          ref="writingListComp"
          :articleList="articleList"
          @openSellingDetailFromComp="(v) => openSellingDetail(undefined, v)"
          @chooseTextFromComp="handleChooseText"
        />
      </div>

      <AIWritingSellingDrawerComp
        ref="sellingDrawer"
        :currentSeriesName="currentSeriesName"
        :currentBrandName="currentBrandData.principalName"
        :carSeriesId="params.carSeriesId"
        :sellingPointList="sellingPointList"
        @addSellingConfirm="addSellingConfirm"
      />

      <AIWritingActivityDrawerComp
        ref="activityDrawer"
        :currentSeriesName="currentSeriesName"
        :currentBrandData="currentBrandData"
        :carSeriesId="params.carSeriesId"
        :activityList="activityList"
        @handleAddActivityConfirm="handleAddActivityConfirm"
      />
    </a-drawer>

    <a-modal
      v-model="showSellingDetailModal"
      title="详情"
      @close="showSellingDetailModal = false"
      :dialog-style="{ top: '20px' }"
      width="900px"
      :zIndex="1002"
      :footer="false"
      :afterClose="handleDetailClose"
    >
      <div class="modal_detail">
        <template v-if="sellingDetailList.length">
          <div class="modal_title">卖点</div>
          <a-table
            :pagination="false"
            :row-key="(record) => record.id"
            :columns="sellingDetailColumns"
            :data-source="sellingDetailList"
            bordered
          >
          </a-table>
        </template>
        <template v-if="activityDetailList && activityDetailList.length">
          <div class="modal_title" style="margin-top: 10px">活动</div>
          <a-table
            :pagination="false"
            :row-key="(record) => record.id"
            :columns="activityDetailColumns"
            :data-source="activityDetailList"
            bordered
          >
            <div slot="dealerArrString" slot-scope="text">
              {{ text }}
            </div>
          </a-table>
        </template>
      </div>
    </a-modal>
  </div>
</template>

<script>
import MessageTextCompVue from '@/components/MessageTextComp.vue';
import AIWritingListComp from './AIWritingListComp.vue';
import AIWritingSellingDrawerComp from './AIWritingSellingDrawerComp.vue';
import AIWritingActivityDrawerComp from './AIWritingActivityDrawerComp.vue';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';

import { debounce, cloneDeep } from 'lodash';
import api from '@/api/xhsAgencyApi';
import moment from 'moment';
import Sortable from 'sortablejs';
import SparkMD5 from 'spark-md5';
import ossUploadMixin from '@/components/BaseUpload/ossUploadMixin.js';
import {
  peopleList,
  directionList,
  recommendDefaultList,
  quotedDefaultList,
  detailDrawerColumns,
} from '../constant.js';

export default {
  name: 'AiWritingDrawerComp',
  props: {
    limited: {
      default: false,
    },
    limitedName: {
      default: undefined,
    },
  },
  components: {
    MessageTextCompVue,
    AIWritingListComp,
    AIWritingSellingDrawerComp,
    AIWritingActivityDrawerComp,
    BrandSelectInput,
    CarSeriesSelectInput,
  },
  mixins: [ossUploadMixin],
  data() {
    return {
      senseList: [],
      imgAcceptList: '.png, .jpeg, .jpg,',
      show: false,
      params: {
        carSeriesId: undefined,
        contentDirection: 'RECOMMEND',
        sellingPointDescription: 'SIMPLE',
        topic: undefined,
        applicableScene: undefined,
        copyWritingRequirement: undefined,
        suitablePeople: undefined,
        writingStyle: 'structType', // 'styleType'
        writingStyleId: '',
        principalIdListData: undefined,
        carSeriesIdListData: undefined,
      },
      sellingPointList: [],
      showSellingDetailModal: false,
      sellingDetailList: [],
      activityDetailList: [],
      articleList: [],
      carSeriesList: [],
      submitLoading: false,
      activityList: [],
      currentSenseLength: 0,
      currentConstructTags: [],
      constructList: [],
      showConstructEdit: false,
      currentConstructTagsIds: [],
      gptLoading: false,
      holidayList: [],
      currentPeopleLength: 0,
      showPeopleDrawdown: false,
      showSceneDrawdown: false,
      file_list: [],
      suggestSellPointList: [],
      suggestSearchLoading: false,
      picAiLoading: false,
      writingStyleList: [],
      submitNum: 2,
    };
  },
  computed: {
    detailDrawerColumns: () => detailDrawerColumns,
    peopleList: () => peopleList,
    directionList: () => directionList,
    recommendDefaultList: () => recommendDefaultList,
    quotedDefaultList: () => quotedDefaultList,
    sellingDetailColumns() {
      const list = [
        'dealerArrString',
        'promotionName',
        'promotionStartTime',
        'promotionEndTime',
        'promotionDescription',
      ];
      return this.detailDrawerColumns.filter((v) => list.indexOf(v.dataIndex) === -1);
    },
    activityDetailColumns() {
      const list = ['coreSellingPoint', 'productFeature', 'productDescription'];
      return this.detailDrawerColumns.filter((v) => list.indexOf(v.dataIndex) === -1);
    },

    showSellPoint() {
      if (this.params.writingStyle === 'styleType' && this.params.contentDirection !== 'SUBSTANCE') return true;
      const saleId = this.constructList.find((v) => v.articleStructureTagName === '产品卖点')?.id || undefined;
      if (this.params.carSeriesId && saleId) {
        const index = this.currentConstructTags.findIndex((c) => c.id === saleId);
        if (index === -1) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    showActivity() {
      if (this.params.writingStyle === 'styleType' && this.params.contentDirection !== 'SUBSTANCE') return true;
      const activityId = this.constructList.find((v) => v.articleStructureTagName === '优惠活动')?.id || undefined;
      if (this.params.carSeriesId && activityId) {
        const index = this.currentConstructTags.findIndex((c) => c.id === activityId);
        if (index === -1) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    currentBrandData() {
      if (this.params.carSeriesId) {
        const car = this.carSeriesList.find((v) => v.carSeriesId === this.params.carSeriesId);
        console.log('car ->', car);
        return car;
      }
      return {};
    },
    currentSeriesName() {
      if (this.params.carSeriesId) {
        const car = this.carSeriesList.find((v) => v.carSeriesId === this.params.carSeriesId);
        return (car && car.carSeriesName) || '';
      }
      return '';
    },
    showPriceTips() {
      const priceId =
        this.constructList.find((v) => v.articleStructureTagName === '产品报价（有金额）')?.id || undefined;
      if (priceId) {
        const index = this.currentConstructTags.findIndex((c) => c.id === priceId);
        if (index === -1) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    principalIds() {
      if (!this.params.principalIdListData) return [];
      const isArray = Array.isArray(this.params.principalIdListData);
      let principalIdListData = this.params.principalIdListData;
      if (!isArray) {
        principalIdListData = [this.params.principalIdListData];
      }
      return principalIdListData.length > 0
        ? principalIdListData.map((item) => {
            const arr = item.split(',');
            return arr.length > 0 ? arr[0] : '';
          })
        : [];
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.getConstructList();
      this.getSceneList();
      this.getHolidayList();
      this.getWritingStyleList();
      this.getCarSeriesList();
    },

    async getCarSeriesList() {
      const { code, data, message } = await api.getXhsSellPointCarSeriesList();
      if (code === 200) {
        this.carSeriesList = data;
      } else {
        this.$message.error(message);
      }
    },

    async getConstructList() {
      const { code, data, message } = await api.getAiContentArticleStructure();
      if (code === 200) {
        this.constructList = data;
        this.recommendDefaultList.forEach((r) => {
          this.constructList.forEach((v) => {
            if (r === v.articleStructureTagName) {
              this.currentConstructTags.push(v);
            }
          });
        });
        this.currentConstructTagsIds = this.currentConstructTags.map((item) => item.id);
      } else {
        this.$message.error(message);
      }
    },

    async getSceneList() {
      const { code, data, message } = await api.getAiContentApplicableSceneList();
      if (code === 200) {
        this.senseList = data.map((v) => v.applicableScene);
      } else {
        this.$message.error(message);
      }
    },

    handleSellTagClose({ id }) {
      const index = this.sellingPointList.findIndex((v) => v.id === id);
      if (index !== -1) {
        this.sellingPointList.splice(index, 1);
      }
    },

    async handleAddSellPoint() {
      if (!this.params.carSeriesId) return this.$message.info('请先选择车系');
      this.$refs.sellingDrawer.handleOpenDrawer();
    },

    async handleAddActivity() {
      if (!this.params.carSeriesId) return this.$message.info('请先选择车系');
      this.$refs.activityDrawer.handleOpenDrawer();
    },

    handleActivityTagClose({ id }) {
      const index = this.activityList.findIndex((v) => v.id === id);
      if (index !== -1) {
        this.activityList.splice(index, 1);
      }
    },

    openDrawer(carSeries) {
      this.show = true;
      if (!this.limited) {
        if (carSeries) {
          const isArrayP = Array.isArray(carSeries);
          const carSeriesData = isArrayP ? carSeries[0] : carSeries;
          const id = carSeriesData.split(',')[0];
          const name = carSeriesData.split(',')[1];
          const car = this.carSeriesList.find((v) => v.carSeriesId === id);
          if (!car) {
            this.$message.info(`${name}车系暂时无法生产文案`);
            this.useLocalSeries();
          } else {
            this.params.carSeriesId = id;
            this.$nextTick(async () => {
              this.$refs.activityDrawer.getCarSeriesMemberList();
              await this.$refs.activityDrawer.getCityList();
              this.$refs.activityDrawer.getAllActivityList();
            });
          }
        } else {
          if (!this.params.carSeriesId) {
            this.useLocalSeries();
          }
        }
      } else {
        if (this.limitedName == '猛士运营') {
          this.params.carSeriesId = this.carSeriesList.find((v) => v.carSeriesName === '猛士917').carSeriesId;
        }
        if (this.params.carSeriesId) {
          this.$nextTick(async () => {
            this.$refs.activityDrawer.getCarSeriesMemberList();
            await this.$refs.activityDrawer.getCityList();
            this.$refs.activityDrawer.getAllActivityList();
          });
        }
      }
      this.defineSortable();
    },

    handleInitParams() {
      Object.assign(this.params, {
        contentDirection: 'RECOMMEND',
        sellingPointDescription: 'SIMPLE',
        topic: undefined,
        applicableScene: undefined,
        copyWritingRequirement: undefined,
        suitablePeople: undefined,
        principalIdListData: undefined,
        carSeriesIdListData: undefined,
      });
      // localStorage.removeItem('AiSubBrandNSeries')
      this.handleDirectionClick({ value: 'RECOMMEND' });
      this.sellingPointList = [];
      this.activityList = [];
    },

    handleSeriesChange(id) {
      if (id) {
        localStorage.setItem('localCarSeriesId', id);
        this.$refs.activityDrawer.getCarSeriesMemberList();
        this.$refs.activityDrawer.getCityList();
        this.$refs.activityDrawer.getAllActivityList();
      }
      this.sellingPointList = [];
      this.activityList = [];
      this.currentActivityChooseList = [];
      this.suggestSellPointList = [];
      this.params.applicableScene = undefined;
      this.params.suitablePeople = [];
      this.params.topic = undefined;

      this.$refs.activityDrawer.activitySearchParams = {
        des: undefined,
        checkTimeRange: [moment().startOf('month'), moment().endOf('month')],
      };
      this.$refs.sellingDrawer.currentSellingChooseList = [];
    },

    openSellingDetail(type, data) {
      if (data?.id) {
        this.sellingDetailList = data.aiProductDescriptionArr.map((v) => {
          v.productDescription = v.originProductDescription;
          return v;
        });
        this.activityDetailList = data.promotionArr.map((v) => {
          let dealerArrString = '全国通用';
          let areaList = '';
          let dealerList = '';
          let strArr = [];
          if (v.promotionRange === 'PROVINCE') {
            v.cityArr.forEach((value) => {
              strArr.push(value.cityName);
            });
            if (strArr.length > 0) {
              areaList = strArr.reduce((prev, item) => {
                prev += `、${item}`;
                return prev;
              });
            }
            dealerArrString = areaList;
          }
          if (v.promotionRange === 'DEALER') {
            v.dealerArr.forEach((value) => {
              strArr.push(value.memberName);
            });
            if (strArr.length > 0) {
              dealerList = strArr.reduce((prev, item) => {
                prev += `、${item}`;
                return prev;
              });
            }
            dealerArrString = dealerList;
          }
          return { ...v, dealerArrString };
        });
      } else {
        if (type === 'activity') {
          this.activityDetailList = this.activityList.map((v) => {
            let dealerArrString = '全国通用';
            let areaList = '';
            let dealerList = '';
            let strArr = [];
            if (v.promotionRange === 'PROVINCE') {
              v.cityArr.forEach((value) => {
                strArr.push(value.cityName);
              });
              if (strArr.length > 0) {
                areaList = strArr.reduce((prev, item) => {
                  prev += `、${item}`;
                  return prev;
                });
              }
              dealerArrString = areaList;
            }
            if (v.promotionRange === 'DEALER') {
              v.dealerArr.forEach((value) => {
                strArr.push(value.memberName);
              });
              if (strArr.length > 0) {
                dealerList = strArr.reduce((prev, item) => {
                  prev += `、${item}`;
                  return prev;
                });
              }
              dealerArrString = dealerList;
            }
            return { ...v, dealerArrString };
          });
          this.sellingDetailList = [];
        }
        if (type === 'sell') {
          this.sellingDetailList = this.sellingPointList;
          this.activityDetailList = [];
        }
      }
      this.showSellingDetailModal = true;
    },

    handleDetailClose() {
      this.sellingDetailList = [];
      this.activityDetailList = [];
    },

    handleStructSubmit: debounce(
      async function () {
        const { carSeriesName, principalName, principalId } = this.carSeriesList.find(
          (v) => v.carSeriesId === this.params.carSeriesId
        );
        const sellingPointIdArr =
          this.showSellPoint && this.sellingPointList?.length ? this.sellingPointList.map((v) => v.id) : [];
        const promotionIdArr = this.showActivity && this.activityList?.length ? this.activityList.map((v) => v.id) : [];
        const articleStructureTagIdList = this.currentConstructTagsIds;
        if (this.showActivity && !promotionIdArr.length) return this.$message.info('未填写优惠活动');
        if (this.showSellPoint && !sellingPointIdArr.length) return this.$message.info('未填写卖点');
        const params = {
          ...this.params,
          carSeriesName,
          principalName,
          principalId,
          sellingPointIdArr,
          promotionIdArr,
          articleStructureTagIdList,
          maxResult: this.submitNum || 1,
        };
        params.writingStyle && delete params.writingStyle;
        params.writingStyleId && delete params.writingStyleId;
        if (params.applicableScene && params.applicableScene.length) {
          params.applicableScene = params.applicableScene[0];
        } else {
          params.applicableScene = '';
        }
        if (params.suitablePeople && params.suitablePeople.length) {
          params.suitablePeople = params.suitablePeople[0];
        } else {
          params.suitablePeople = '';
        }
        console.log('params ->', params);
        const strId = new Date().getTime();
        this.articleList.push({
          strId,
          status: 'LOADING',
        });
        this.$nextTick(() => {
          this.$refs.writingListComp && this.$refs.writingListComp.scrollToBottom();
        });
        const articleItem = this.articleList.find((v) => v.strId === strId);
        const { code, data, message } = await api.createAiCompositionTask(params).catch((err) => {
          articleItem.status = 'FAILED';
          articleItem.message = err.data;
          return;
        });
        if (code && code === 200) {
          articleItem.status = 'DONE';
          const index = this.articleList.findIndex((item) => item.strId === strId);
          let newArticle = data.map((item) => {
            const strId = Math.random().toString(36).substring(3, 10);
            return {
              strId,
              status: 'DONE',
              text: item.finalAiContent,
              id: item.id,
              tagContentArr: item.tagContentArr,
              aiProductDescriptionArr: item.aiProductDescriptionArr,
              promotionArr: item.promotionArr,
            };
          });
          this.articleList.splice(index, 1, ...newArticle);
        } else {
          articleItem.status = 'FAILED';
          articleItem.message = message || '生成错误';
        }
      },
      2000,
      { leading: true }
    ),

    handleValueStructSubmit: debounce(
      async function () {
        const params = {
          topic: this.params.topic,
          additionalParamMap: {},
          articleStructureTagIdList: ['1808783997263921155'],
          maxResult: this.submitNum || 1,
          brandId: this.params.principalIdListData ? this.params.principalIdListData.split(',')[0] : '',
          carSeriesId: this.params.carSeriesIdListData ? this.params.carSeriesIdListData.split(',')[0] : '',
        };
        console.log(params);
        const strId = new Date().getTime();
        this.articleList.push({
          strId,
          status: 'LOADING',
        });
        this.$nextTick(() => {
          this.$refs.writingListComp && this.$refs.writingListComp.scrollToBottom();
        });
        const articleItem = this.articleList.find((v) => v.strId === strId);
        const { code, data, message } = await api.generateAiArticle(params).catch((err) => {
          articleItem.status = 'FAILED';
          articleItem.message = err.data;
          return;
        });
        if (code && code === 200) {
          articleItem.status = 'DONE';
          const index = this.articleList.findIndex((item) => item.strId === strId);
          let newArticle = data.map((item) => {
            const strId = Math.random().toString(36).substring(3, 10);
            return {
              strId,
              status: 'DONE',
              text: item.finalAiContent,
              id: item.id,
              tagContentArr: item.tagContentArr,
              aiProductDescriptionArr: item.aiProductDescriptionArr,
              promotionArr: item.promotionArr,
            };
          });
          this.articleList.splice(index, 1, ...newArticle);
        } else {
          articleItem.status = 'FAILED';
          articleItem.message = message || '生成错误';
        }
        
      },
      2000,
      { leading: true }
    ),

    handleStyleSubmit: debounce(
      async function () {
        if (!this.params.writingStyleId) return this.$message.info('请先选择文风');
        const { carSeriesName, principalName, principalId } = this.carSeriesList.find(
          (v) => v.carSeriesId === this.params.carSeriesId
        );
        const sellingPointIdArr =
          this.showSellPoint && this.sellingPointList?.length ? this.sellingPointList.map((v) => v.id) : [];
        const promotionIdArr = this.showActivity && this.activityList?.length ? this.activityList.map((v) => v.id) : [];
        const params = {
          carSeriesId: this.params.carSeriesId,
          carSeriesName,
          principalName,
          principalId,
          sellingPointIdArr,
          promotionIdArr,
          writingStyleId: this.params.writingStyleId,
          maxResult: this.submitNum || 1,
        };
        console.log('params ->', params);
        const strId = new Date().getTime();
        this.articleList.push({
          strId,
          status: 'LOADING',
        });
        this.$nextTick(() => {
          this.$refs.writingListComp && this.$refs.writingListComp.scrollToBottom();
        });
        const articleItem = this.articleList.find((v) => v.strId === strId);
        const { code, data, message } = await api.generateStructureTag(params).catch((err) => {
          articleItem.status = 'FAILED';
          articleItem.message = err.data;
          return;
        });
        if (code && code === 200) {
          articleItem.status = 'DONE';
          const index = this.articleList.findIndex((item) => item.strId === strId);
          let newArticle = data.map((item) => {
            const strId = Math.random().toString(36).substring(3, 10);
            return {
              strId,
              status: 'DONE',
              text: item.finalAiContent,
              id: item.id,
              tagContentArr: item.tagContentArr,
              aiProductDescriptionArr: item.aiProductDescriptionArr,
              promotionArr: item.promotionArr,
            };
          });
          this.articleList.splice(index, 1, ...newArticle);
        } else {
          articleItem.status = 'FAILED';
          articleItem.message = message || '生成错误';
        }
      },
      2000,
      { leading: true }
    ),

    handleChooseText(data) {
      this.$emit('chooseText', data);
      this.show = false;
    },

    handleSenseChange(v) {
      this.showSceneDrawdown = false;
      this.$refs.sceneSelect.blur();
      if (v.length) {
        if (v.length > 1) {
          if (v[1].length > 40) this.$message.info('卖点最多只能输入40个字，已截取');
          this.params.applicableScene = [v[1].slice(0, 40)];
        } else if (v[0].length > 40) {
          v[0] = v[0].slice(0, 40);
          this.$message.info('卖点最多只能输入40个字，已截取');
        }
        this.currentSenseLength = this.params.applicableScene[0].length;
        this.getSuggestSellPointList();
      } else {
        this.currentSenseLength = 0;
      }
    },

    senseInputKeydown(v) {
      this.currentSenseLength = v.length;
    },

    handleDirectionClick({ value }) {
      this.params.contentDirection = value;
      this.currentConstructTags = [];
      if (value === 'RECOMMEND') {
        this.recommendDefaultList.forEach((r) => {
          this.constructList.forEach((v) => {
            if (r === v.articleStructureTagName) {
              this.currentConstructTags.push(v);
            }
          });
        });
      }
      if (value === 'QUOTED_PRICE') {
        this.quotedDefaultList.forEach((r) => {
          this.constructList.forEach((v) => {
            if (r === v.articleStructureTagName) {
              this.currentConstructTags.push(v);
            }
          });
        });
      }
      if (value === 'SUBSTANCE') {
        if (!this.params.principalIdListData) {
          const item = localStorage.getItem('AiSubBrandNSeries');
          if (item) {
            const data = JSON.parse(item);
            this.params.principalIdListData = data.principalId;
            this.params.carSeriesIdListData = data.seriesId;
          }
        }
        // AiSubBrandNSeries
      }
      this.currentConstructTagsIds = this.currentConstructTags.map((item) => item.id);
      this.handleConstructCancel();
    },

    defineSortable() {
      this.$nextTick(() => {
        const el = this.$refs.sortableBox;
        const that = this;
        if (el) {
          Sortable.create(el, {
            filter: '.sort_filter',
            animation: 100, //拖拽动画过渡时间
            group: 'name',
            sort: true,
            onEnd: function (evt) {
              const listData = cloneDeep(that.currentConstructTags);
              listData.splice(evt.newIndex, 0, listData.splice(evt.oldIndex, 1)[0]);
              const newArray = listData.slice(0);
              that.$nextTick(() => {
                that.currentConstructTags = newArray;
                that.currentConstructTagsIds = that.currentConstructTags.map((item) => item.id);
              });
            },
          });
        }
      });
    },

    handleConstructTagDel({ id }) {
      this.currentConstructTags.splice(
        this.currentConstructTags.findIndex((item) => item.id === id),
        1
      );
      this.currentConstructTagsIds = this.currentConstructTags.map((item) => item.id);
    },

    handleConstructTagEdit() {
      this.currentConstructTagsIds = this.currentConstructTags.map((item) => item.id);
      this.showConstructEdit = true;
    },

    handleConstructConfirm() {
      if (this.currentConstructTagsIds.length < 1) return this.$message.info('至少选择一个结构');
      if (this.currentConstructTagsIds.length > 7) return this.$message.info('最多选择7个结构');
      this.currentConstructTags = [];
      this.currentConstructTagsIds.forEach((id) => {
        this.constructList.forEach((item) => {
          if (item.id === id) {
            this.currentConstructTags.push(item);
          }
        });
      });
      this.showConstructEdit = false;
      this.defineSortable();
    },

    handleConstructCancel() {
      this.currentConstructTagsIds = this.currentConstructTags.map((item) => item.id);
      this.showConstructEdit = false;
    },

    async getTextFromGPT(params) {
      if (this.gptLoading) return;
      this.gptLoading = true;
      const { code, data, message } = await api.aiMarketingRefinement(params).finally(() => {
        this.gptLoading = false;
      });
      if (code === 200) {
        this.params.topic = data.response_text;
      } else {
        this.$message.error(message);
      }
    },

    handleGptClick(marketingPoint) {
      if (!marketingPoint) return this.$message.info('主题不能为空');
      if (this.gptLoading) return;
      const carSeriesName =
        this.params.contentDirection === 'SUBSTANCE'
          ? this.params.carSeriesIdListData?.split(',')[1] || ''
          : this.currentSeriesName;
      if (!carSeriesName) return this.$message.info('请选择车系');
      const params = { carSeriesName, marketingPoint };
      if (this.params.topic?.length > 0) {
        const _this = this;
        this.$confirm({
          title: '将会替换当前主题，是否继续?',
          okText: '继续',
          onOk() {
            _this.getTextFromGPT(params);
          },
        });
      } else {
        this.getTextFromGPT(params);
      }
    },

    peopleInputKeydown(v) {
      this.currentPeopleLength = v.length;
    },

    handlePeopleChange(v) {
      this.showOpen = false;
      this.$refs.peopleSelect.blur();
      if (v.length) {
        if (v.length > 1) {
          this.params.suitablePeople = [v[1].slice(0, 20)];
        }
        if (v[0].length > 20) {
          v[0] = v[0].slice(0, 20);
          this.$message.info('适用人群最多只能输入20个字，已截取');
        }
        this.currentPeopleLength = this.params.suitablePeople[0].length;
      } else {
        this.currentPeopleLength = 0;
      }
    },

    async customRequest(option) {
      const { file, onSuccess } = option;
      await this.multipartUpload(file, onSuccess);
    },

    beforeUploadImg(file) {
      return new Promise((resolve, reject) => {
        let fileType = /\.(jpeg|jpg|png)$/i;
        if (!fileType.test(file.name)) {
          this.$message.error('仅支持jpeg、jpg、png格式文件');
          return false;
        }
        const isLimit = file.size / 1024 / 1024 < 40;
        if (!isLimit) {
          this.$message.error('图片大小不超过40MB');
          return false;
        }
        let fileReader = new FileReader();
        let spark = new SparkMD5.ArrayBuffer(); //获取文件二进制数据
        fileReader.readAsArrayBuffer(file); //file就是获取到的文件 //异步执行函数
        fileReader.onload = function (e) {
          spark.append(e.target.result);
          let md5 = spark.end();
          file.md5 = md5;
          file.params_data = {
            appId: 'bfz',
            filePath: '/bfz/xhs/',
            identifier: file.md5,
            name: file.name,
            title: file.name.split('.')[0],
          };
          return resolve(true);
        };
      });
    },

    handleUploadChange(info) {
      if (info.file.status) {
        this.file_list = info.fileList;

        if (info.file.status === 'uploading') {
          this.picAiLoading = true;
          this.file_list.forEach((val) => {
            if (val.fileId === info.file.fileId) {
              val.status = 'uploading';
            }
          });
        }

        if (info.file.status === 'done') {
          let fileList = [...info.fileList];
          fileList = fileList.map((file) => {
            if (file.response) {
              file.fileId = file.uid;
              file.url = file.response.cdnUrl;
              file.status = 'done';
            }
            return file;
          });
          this.file_list = fileList;
          console.log('this.file_list ->', this.file_list);
          this.getRecognizeAiPic(this.file_list[0].url);
        }

        if (info.file.status === 'error') {
          this.picAiLoading = false;
          this.$message.error(`${info.file.name} 文件上传失败`);
        }
      } else {
        return;
      }
    },

    handleUploadClick() {
      if (this.params.applicableScene?.length) {
        const _this = this;
        this.$confirm({
          title: '解析后将会替换现有场景，是否继续?',
          okText: '继续',
          onOk() {
            _this.$refs.uploadRef.click();
          },
        });
      } else {
        this.$refs.uploadRef.click();
      }
    },

    handleAdditionSellPointClick(data) {
      const index = this.sellingPointList.findIndex((v) => v.id === data.id);
      if (index !== -1) {
        this.sellingPointList.splice(index, 1);
      } else {
        if (this.sellingPointList.length < 5) {
          this.sellingPointList.push(data);
        } else {
          return this.$message.warning('最多只能选择5个卖点');
        }
      }
    },

    async getSuggestSellPointList() {
      if (this.params.applicableScene && this.params.carSeriesId) {
        this.suggestSearchLoading = true;
        const params = {
          carSeriesId: this.params.carSeriesId,
          maxResult: 5,
          query: this.params.applicableScene[0],
        };
        const { code, data, message } = await api
          .getSuggestSellPointList(params)
          .finally(() => (this.suggestSearchLoading = false));
        if (code === 200) {
          this.suggestSellPointList = data;
        } else {
          this.$message.error(message);
        }
      }
    },

    async getRecognizeAiPic(picUrl) {
      let imageUrl = picUrl;
      await this.checkUploadPicPress(picUrl).then(({ imgUrl }) => {
        imageUrl = imgUrl;
      });
      const { code, data, message } = await api.recognizeAiPic({ imageUrl }).finally(() => {
        this.picAiLoading = false;
        this.file_list = [];
      });
      if (code === 200) {
        if (data.description) {
          this.params.applicableScene = [data.description];
          this.currentSenseLength = this.params.applicableScene[0]?.length || 0;
          this.getSuggestSellPointList();
        } else {
          this.$message.info('未识别到场景，请换一张图片尝试');
        }
      } else {
        this.$message.error(message);
      }
    },

    handlePicCancel() {
      this.picAiLoading = false;
      this.cancelCurrentUpload();
      this.file_list = [];
    },

    useLocalSeries() {
      const localCarSeriesId = localStorage.getItem('localCarSeriesId');
      const find = this.carSeriesList.find((item) => item.carSeriesId === localCarSeriesId);
      if (find && localCarSeriesId && !this.limited) {
        this.params.carSeriesId = localCarSeriesId;
        this.$nextTick(() => {
          this.$refs.activityDrawer.getCarSeriesMemberList();
          this.$refs.activityDrawer.getCityList();
          this.$refs.activityDrawer.getAllActivityList();
        });
      }
    },

    async getHolidayList() {
      const { code, data, message } = await api.getSellHolidayList();
      if (code === 200) {
        this.holidayList = data.map((v) => {
          const date = moment(v.marketingDate).format('MM-DD');
          const showName = `${date}${v.dateName}`;
          return {
            ...v,
            showName,
          };
        });
      } else {
        this.$message.error(message);
      }
    },

    getActivityRangeName(arr) {
      let rtName = '';
      let strArr = [];
      arr.forEach((value) => {
        const string = value.memberCode ? `${value.memberName}(${value.memberCode})` : value.memberName;
        strArr.push(string);
      });
      rtName = strArr.reduce((prev, item) => {
        prev += `、${item}`;
        return prev;
      });
      return rtName;
    },

    async checkUploadPicPress(imageUrl) {
      return new Promise(async (resolve, reject) => {
        const { code, data, message } = await api.imageDto({ imageUrl });
        if (code === 200) {
          let imgUrl = imageUrl;
          if (data.width > 4096 || data.height > 4096) {
            if (imgUrl.indexOf('x-oss-process') === -1) {
              imgUrl = `${imgUrl}?x-oss-process=image`;
            }
            const fileWidth = data.orientation === '6' || data.orientation === '8' ? data.height : data.width;
            const fileHeight = data.orientation === '6' || data.orientation === '8' ? data.width : data.height;
            // 横图过大
            if (fileWidth > fileHeight && fileWidth > 4096) {
              imgUrl += `/resize,w_${4096}`;
            }
            // 竖图过大
            if (fileWidth < fileHeight && fileHeight > 4096) {
              imgUrl += `/resize,h_${4096}`;
            }
          }
          resolve({ imgUrl });
        } else {
          this.$message.error(message);
          reject();
        }
      });
    },

    handleSwitchType() {
      this.params.writingStyle = this.params.writingStyle === 'styleType' ? 'structType' : 'styleType';
      if (this.params.writingStyle === 'structType') this.defineSortable();
    },

    addSellingConfirm(newList) {
      this.sellingPointList = newList;
    },

    handleAddActivityConfirm(newList) {
      this.activityList = newList;
    },

    async getWritingStyleList() {
      const { code, data, message } = await api.getWritingStyleList();
      if (code === 200) {
        this.writingStyleList = data;
        if (data.length) {
          this.params.writingStyleId = data[0].id;
        }
      } else {
        this.$message.error(message);
      }
    },

    handleSubBrandChange(principalId) {
      this.params.carSeriesIdListData = undefined;
      if (principalId) {
        localStorage.setItem('AiSubBrandNSeries', JSON.stringify({ principalId, seriesId: undefined }));
      } else {
        localStorage.removeItem('AiSubBrandNSeries');
      }
    },
    handleSubSeriesChange(seriesId) {
      localStorage.setItem(
        'AiSubBrandNSeries',
        JSON.stringify({
          principalId: this.params.principalIdListData,
          seriesId,
        })
      );
    },
    handleNumCheck() {
      this.submitNum = Math.floor(this.submitNum);
      if (this.submitNum <= 0) this.submitNum = 1;
      if (this.submitNum >= 5) this.submitNum = 5;
    },
    handleSubmitClick() {
      if (this.params.writingStyle === 'structType') {
        if (this.params.contentDirection === 'SUBSTANCE') {
          // 干货类型
          if (!this.params.topic) return this.$message.info('请输入主题');
          this.handleValueStructSubmit();
          return;
        }
        this.handleStructSubmit();
        return;
      }
      this.handleStyleSubmit(); // 文风作文
    },
  },
};
</script>

<style lang="less" scoped>
.ai_drawer {
  ::v-deep .ant-drawer-body {
    padding: 10px 14px 10px 24px;
  }
}
.drawer_left {
  position: relative;
  margin-right: 10px;
  padding: 15px;
  width: 452px;
  height: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 8px;

  .left_container {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 68px;

    &::-webkit-scrollbar {
      width: 0;
      height: 10px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #00152900;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #00152900;
    }

    &::-webkit-scrollbar-corner {
      background: #fff;
    }
  }

  .item_title {
    margin: 15px 0 8px;
    font-weight: bold;
  }

  .select_item {
    padding: 5px 15px;
    border: 1px solid #c0c0c0;
    cursor: pointer;
    user-select: none;
  }

  .select_item_active {
    color: #2a98ff;
    border: 1px solid #54adff;
  }

  .footer_bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #d7d7d7;
    border-radius: 0 0 8px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.modal_detail {
  min-height: 100px;
  max-height: 80vh;
  overflow-y: auto;

  .modal_title {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: bolder;
    color: #7f7f7f;
  }
}

.disable_select {
  cursor: not-allowed !important;
  color: #ccc;
}

.gpt_btn {
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  width: 100px;
  border: 1px solid #d7d7d7;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;

  &.loading {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
}

.icons {
  width: 15px;
  height: 15px;
}

.addition_tag {
  margin-right: 10px;
  color: #1890ff;
  cursor: pointer;
  user-select: none;
}

.sceneSelect {
  ::v-deep .ant-select-selection__rendered {
    height: 70px !important;
  }

  ::v-deep .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    top: 20% !important;
  }
}

.writer-row {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;

  .writer-column {
    margin: 0 10px 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    user-select: none;

    .avatar {
      width: 45px;
      height: 45px;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }

    &.active-column {
      color: #1990ff;
      border: 1px solid #1990ff;

      img {
        transform: scale(1.1);
        border: 1px solid #1990ff;
      }
    }
  }
}

.switch_btn {
  position: relative;
}
</style>
