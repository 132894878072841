<template>
  <div class="note_drawer">
    <a-drawer
      class="drawer_contain"
      title="历史记录"
      width="450"
      placement="right"
      :visible="show"
      @close="show = false"
    >
      <a-spin :spinning="loading">
        <template v-if="dataList.length > 0">
          <div class="list_item" v-for="(val, ind) in dataList" :key="ind" @click="handleCheck(val.id)">
            <div class="item_left">
              <div class="list_title">{{ val.atime }} <span v-show="ind === 0">(当前版本)</span></div>
              <div>
                by <span>{{ val.actor }}</span>
              </div>
            </div>
            <a-button class="show_btn" type="link" size="small">查看</a-button>
          </div>
        </template>
        <template v-else>
          <div style="text-align: center; padding: 20px 0">暂无数据</div>
        </template>
      </a-spin>

      <!-- 查看/编辑抽屉 -->
      <writtingDrawer
        v-if="showDrawer"
        @closeDrawer="showDrawer = false"
        :historyId="historyId"
        @useDraft="handleUseDraft"
      />
    </a-drawer>
  </div>
</template>

<script>
import writtingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';
import api from '@/api/xhsAgencyApi';

export default {
  name: 'NoteHistoryListComp',
  components: { writtingDrawer },
  data() {
    return {
      show: false,
      loading: false,
      showDrawer: false,
      dataList: [],
      historyId: undefined,
    };
  },
  methods: {
    async openDrawer(objectId) {
      this.show = true;
      this.loading = true;
      this.dataList = [];
      const { code, data, message } = await api.getNoteOperatorHistory({ page: 1, size: 999, objectId }).finally(() => {
        this.loading = false;
      });
      if (code === 200) {
        this.dataList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    handleCheck(id) {
      this.historyId = id;
      this.showDrawer = true;
    },
    handleUseDraft(data) {
      this.showDrawer = false;
      this.show = false;
      this.$emit('useDraft', data)
    },
  },
};
</script>

<style lang="less" scoped>
.list_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;

  .item_left {
    .list_title {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .show_btn {
    opacity: 0;
  }

  &:hover {
    .show_btn {
      opacity: 1;
    }
  }
}
</style>
