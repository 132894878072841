<template>
  <a-drawer
    class="sell_drawer"
    title="选择卖点"
    width="900"
    :closable="true"
    :visible="showSellingDrawer"
    @close="handleAddSellingClose"
  >
    <div class="current_car_series">
      当前车系：{{ currentBrandName ? `${currentBrandName} - ` : '' }}{{ currentSeriesName }}
    </div>
    <div style="display: flex; margin-bottom: 8px">
      <span style="width: 55px; flex-shrink: 0">已选 {{ sellingSelectedRowKeys.length || 0 }}/5</span>：
      <span>
        <a-tag
          style="margin-bottom: 5px; user-select: none; letter-spacing: 1px"
          color="#6a9fff"
          v-for="v in currentSellingChooseList"
          :key="v.id"
          closable
          @close="() => handleCurrentSellTagClose(v)"
          >{{ v.productFeature }}</a-tag
        >
        <a-button
          v-show="currentSellingChooseList.length"
          type="link"
          icon="delete"
          style="line-height: 20px; height: 20px; color: #7f7f7f"
          @click="handleSellPointClear"
          >清空已选</a-button
        >
      </span>
    </div>
    <a-input-search
      style="margin-bottom: 15px"
      placeholder="输入关键字搜索"
      v-model.trim="sellingSearchKeyWord"
      allowClear
      @search="getSellingPointList"
    ></a-input-search>
    <a-table
      :pagination="false"
      :row-selection="sellingRowSelection"
      :row-key="(record) => record.id"
      :columns="sellingColumns"
      :data-source="sellingTableList"
      :loading="sellingTableLoading"
      bordered
      :scroll="{ y: '64vh' }"
    >
      <div slot="coreSellingPoint" slot-scope="text" style="cursor: pointer" @click="handleSellingColumnClick(text)">
        <a-tooltip title="此卖点已使用较多，可能生产出相似文案">
          <a-icon v-show="!text.usable" type="info-circle" style="color: #dec19a; margin-right: 5px" theme="filled" />
        </a-tooltip>
        {{ text.coreSellingPoint }}
      </div>
      <div slot="productFeature" slot-scope="text" style="cursor: pointer" @click="handleSellingColumnClick(text)">
        {{ text.productFeature }}
      </div>
      <div
        class="des_column"
        slot="productDescription"
        slot-scope="text"
        style="cursor: pointer"
        @click="handleSellingColumnClick(text)"
      >
        <div class="des_short">{{ text.productDescription }}</div>
        <div class="des_long">{{ text.productDescription }}</div>
      </div>
    </a-table>
    <div
      :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }"
    >
      <a-button style="margin-right: 8px" @click="handleAddSellingClose"> 取消 </a-button>
      <a-button type="primary" :disabled="!sellingSelectedRowKeys.length" @click="handleAddSellingConfirm">
        确定
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import {
  sellingColumns,
} from '../constant.js';
import api from '@/api/xhsAgencyApi';

export default {
  name: 'AIWritingSellingDrawerComp',
  props: {
    currentSeriesName: {
      default: '',
    },
    currentBrandName: {
      default: '',
    },
    carSeriesId: {
      default: '',
    },
    sellingPointList: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      sellingTableList: [],
      sellingSelectedRowKeys: [],
      allSellingPointList: [],
      showSellingDrawer: false,
      sellingTableLoading: false,
      currentSellingChooseList: [],
      sellingSearchKeyWord: undefined,
    };
  },
  computed: {
    sellingRowSelection() {
      const { sellingSelectedRowKeys } = this;
      return {
        selectedRowKeys: sellingSelectedRowKeys,
        onChange: this.onSellingSelectChange,
      };
    },
    sellingColumns: () => sellingColumns,
  },
  methods: {
    async handleOpenDrawer() {
      this.showSellingDrawer = true;
      this.sellingPointList.map((v) => {
        this.sellingSelectedRowKeys.push(v.id);
      });
      this.allSellingPointList = await this.getSellingPointList();
      this.currentSellingChooseList = this.allSellingPointList.filter(
        (x) => this.sellingSelectedRowKeys.indexOf(x.id) > -1
      );
    },

    handleAddSellingConfirm() {
      const newList = [];
      this.sellingSelectedRowKeys.map((v) => {
        const item = this.allSellingPointList.find((v1) => v1.id === v);
        item?.id && newList.push(item);
      });
      this.handleAddSellingClose();
      this.$emit('addSellingConfirm', newList);
    },

    handleAddSellingClose() {
      this.showSellingDrawer = false;
      this.sellingSearchKeyWord = undefined;
      this.sellingSelectedRowKeys = [];
      this.allSellingPointList = [];
    },

    handleCurrentSellTagClose({ id }) {
      const currentChooseIndex = this.sellingSelectedRowKeys.indexOf(id);
      this.sellingSelectedRowKeys.splice(currentChooseIndex, 1);
      this.currentSellingChooseList = this.allSellingPointList.filter(
        (x) => this.sellingSelectedRowKeys.indexOf(x.id) > -1
      );
    },

    async getSellingPointList() {
      return new Promise(async (resolve) => {
        this.sellingTableLoading = true;
        const params = {
          page: 1,
          size: 999,
          search: this.sellingSearchKeyWord,
          carSeriesId: this.carSeriesId,
        };
        const { code, data, message } = await api.getXhsCarSeriesSellPointList(params).finally(() => {
          this.sellingTableLoading = false;
        });
        if (code === 200) {
          this.sellingTableList = data.list;
          resolve(data.list);
        } else {
          this.$message.error(message);
          resolve([]);
        }
      });
    },

    handleSellingColumnClick({ id }) {
      const currentChooseIndex = this.sellingSelectedRowKeys.indexOf(id);
      if (currentChooseIndex !== -1) {
        this.sellingSelectedRowKeys.splice(currentChooseIndex, 1);
        this.currentSellingChooseList = this.allSellingPointList.filter(
          (x) => this.sellingSelectedRowKeys.indexOf(x.id) > -1
        );
      } else {
        if (this.sellingSelectedRowKeys.length < 5) {
          this.sellingSelectedRowKeys.push(id);
          this.onSellingSelectChange(this.sellingSelectedRowKeys);
        } else {
          return this.$message.warning('最多只能选择5个卖点');
        }
      }
    },

    onSellingSelectChange(sellingSelectedRowKeys) {
      this.sellingSelectedRowKeys = sellingSelectedRowKeys;

      if (this.sellingSelectedRowKeys.length > 5) {
        this.sellingSelectedRowKeys = this.sellingSelectedRowKeys.slice(0, 5);
        this.$message.warning('最多只能选择5个卖点');
      }

      this.currentSellingChooseList = this.allSellingPointList.filter(
        (x) => this.sellingSelectedRowKeys.indexOf(x.id) > -1
      );
    },
    
    handleSellPointClear() {
      this.currentSellingChooseList = [];
      this.sellingSelectedRowKeys = [];
    },
  },
};
</script>

<style lang="less" scoped>
.sell_drawer {
  ::v-deep .ant-drawer-body {
    padding-bottom: 60px;
  }
}


.des_column {
  position: relative;
  width: 316px;

  .des_short {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .des_long {
    display: none;
    position: absolute;
    top: -16px;
    left: -15px;
    padding: 10px;
    width: 110%;
    min-height: 54px;
    background-color: #fff;
    border: 1px solid #1e6fff;
    z-index: 1;
  }

  &:hover {
    .des_long {
      display: block;
    }
  }
}

.current_car_series {
  margin-bottom: 15px;
}
</style>
