<template>
  <a-drawer
    class="activity_drawer"
    title="选择活动"
    width="900"
    :closable="true"
    :visible="showActivityDrawer"
    @close="handleActivityClose"
  >
    <div class="current_car_series">
      <span
        >当前车系：{{ currentBrandData.principalName ? `${currentBrandData.principalName} - ` : ''
        }}{{ currentSeriesName }}</span
      >
      <a-button type="primary" @click="handleAddActivity">添加活动</a-button>
    </div>
    <div style="display: flex; margin-bottom: 8px">
      <span style="width: 55px; flex-shrink: 0">已选 {{ activitySelectedRowKeys.length || 0 }}/5</span>：
      <span>
        <a-tag
          style="margin-bottom: 5px; user-select: none; letter-spacing: 1px"
          color="#87d068"
          v-for="v in currentActivityChooseList"
          :key="v.id"
          closable
          @close="() => handleCurrentActivityTagClose(v)"
          >{{ v.promotionName }}</a-tag
        >
        <a-button
          v-show="currentActivityChooseList.length"
          type="link"
          icon="delete"
          style="line-height: 20px; height: 20px; color: #7f7f7f"
          @click="handleActivityClear"
          >清空已选</a-button
        >
      </span>
    </div>
    <a-space style="margin-bottom: 15px">
      <a-input-search
        placeholder="输入活动名称、活动描述"
        v-model.trim="activitySearchParams.des"
        style="width: 200px"
        allowClear
        @search="getActivityList"
      ></a-input-search>
      <a-select
        style="width: 150px"
        placeholder="选择范围"
        v-model="activitySearchParams.promotionRange"
        allowClear
        @change="handleTypeChange"
      >
        <a-select-option v-for="item in rangeList" :key="item.value" :value="item.value"
          >{{ item.label }}
        </a-select-option>
      </a-select>
      <a-select
        v-if="activitySearchParams.promotionRange == 'PROVINCE'"
        v-model="activitySearchParams.cityId"
        :default-active-first-option="false"
        show-search
        :filter-option="activityFilterOption"
        allowClear
        option-label-prop="label"
        placeholder="选择地区"
        style="width: 200px"
        @change="getActivityList"
      >
        <a-select-option v-for="item in cityList" :key="item.cityId" :label="item.cityName" :value="item.cityId">
          <div>{{ item.cityName }}</div>
        </a-select-option>
      </a-select>
      <a-select
        v-if="activitySearchParams.promotionRange == 'DEALER'"
        v-model="activitySearchParams.memberId"
        :default-active-first-option="false"
        show-search
        :filter-option="activityFilterOption"
        allowClear
        option-label-prop="label"
        placeholder="选择经销商"
        style="width: 200px"
        @change="getActivityList"
      >
        <a-select-option
          v-for="item in activityGroupList"
          :key="item.memberId"
          :label="item.memberName"
          :value="item.memberId"
        >
          <div>{{ item.memberName }}</div>
          <div
            v-show="item.memberCode"
            style="font-size: 12px; color: #cccccc; overflow: hidden; text-overflow: ellipsis"
          >
            {{ item.memberCode }}
          </div>
        </a-select-option>
      </a-select>
      <a-range-picker :value="activitySearchParams.checkTimeRange" :ranges="timeRange" @change="onActivityTimeChange" />
    </a-space>
    <a-table
      :pagination="false"
      :row-selection="activityRowSelection"
      :row-key="(record) => record.id"
      :columns="activityColumns"
      :data-source="activityTableList"
      :loading="activityTableLoading"
      bordered
      :scroll="{ y: '64vh' }"
    >
      <div slot="dealerArr" slot-scope="text" class="dealerArr_class" @click="handleActivityColumnClick(text)">
        <a-tooltip title="已过活动截止时间，请注意活动有效期。">
          <a-icon
            v-show="checkOverTime(text.promotionEndTime)"
            type="info-circle"
            style="color: #dec19a; margin-right: 5px"
            theme="filled"
          />
        </a-tooltip>
        <a-tooltip :title="text.dealerArrString" placement="topLeft">
          {{ text.dealerArrString }}
        </a-tooltip>
      </div>
      <div slot="promotionName" slot-scope="text" style="cursor: pointer" @click="handleActivityColumnClick(text)">
        {{ text.promotionName }}
      </div>
      <div slot="promotionStartTime" slot-scope="text" style="cursor: pointer" @click="handleActivityColumnClick(text)">
        {{ text.promotionStartTime }}
      </div>
      <div slot="promotionEndTime" slot-scope="text" style="cursor: pointer" @click="handleActivityColumnClick(text)">
        {{ text.promotionEndTime }}
      </div>
      <div
        class="des_column"
        style="width: 245px; cursor: pointer"
        slot="promotionDescription"
        slot-scope="text"
        @click="handleActivityColumnClick(text)"
      >
        <div class="des_short">{{ text.promotionDescription }}</div>
        <div class="des_long" style="top: -26px">{{ text.promotionDescription }}</div>
      </div>
    </a-table>
    <div
      :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }"
    >
      <a-button style="margin-right: 8px" @click="handleActivityClose"> 取消 </a-button>
      <a-button type="primary" :disabled="!activitySelectedRowKeys.length" @click="handleAddActivityConfirm">
        确定
      </a-button>
    </div>

    <ActivityUpdateModalComp ref="ActivityUpdateModalComp" :fixedBrand="true" @success="handleAddDone" />
  </a-drawer>
</template>

<script>
import moment from 'moment';
import { activityColumns } from '../constant.js';
import api from '@/api/xhsAgencyApi';
import ActivityUpdateModalComp from '@/pages/app/xhsAgency/aigc/components/ActivityUpdateModalComp.vue';

export default {
  name: 'AIWritingActivityDrawerComp',
  props: {
    currentSeriesName: {
      default: '',
    },
    currentBrandData: {
      default: () => {},
    },
    carSeriesId: {
      default: '',
    },
    activityList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ActivityUpdateModalComp,
  },
  data() {
    return {
      showActivityDrawer: false,
      activitySelectedRowKeys: [],
      currentActivityChooseList: [],
      activitySearchParams: {
        des: undefined,
        checkTimeRange: [moment().startOf('month'), moment().endOf('month')],
        promotionRange: undefined,
        cityId: undefined,
      },
      activityGroupList: [],
      activityTableList: [],
      activityTableLoading: false,
      timeRange: {
        本月: [moment().startOf('month'), moment().endOf('month')],
      },
      allActivityList: [],
      rangeList: [
        {
          value: 'NATIONWIDE',
          label: '全国',
        },
        {
          value: 'PROVINCE',
          label: '省份-城市',
        },
        {
          value: 'DEALER',
          label: '门店',
        },
      ],
      cityList: [],
    };
  },
  computed: {
    activityRowSelection() {
      const { activitySelectedRowKeys } = this;
      return {
        selectedRowKeys: activitySelectedRowKeys,
        onChange: this.onActivitySelectChange,
      };
    },
    activityColumns: () => activityColumns,
  },
  methods: {
    async handleOpenDrawer() {
      this.activityList.map((v) => {
        this.activitySelectedRowKeys.push(v.id);
      });
      this.showActivityDrawer = true;

      await this.getActivityList();
      this.currentActivityChooseList = this.allActivityList.filter(
        (x) => this.activitySelectedRowKeys.indexOf(x.id) > -1
      );
    },

    handleActivityClose() {
      this.showActivityDrawer = false;
      this.activitySelectedRowKeys = [];
    },

    handleCurrentActivityTagClose({ id }) {
      const currentChooseIndex = this.activitySelectedRowKeys.indexOf(id);
      this.activitySelectedRowKeys.splice(currentChooseIndex, 1);
    },

    handleActivityClear() {
      this.currentActivityChooseList = [];
      this.activitySelectedRowKeys = [];
    },

    getActivityList() {
      return new Promise(async (resolve) => {
        this.activityTableLoading = true;
        const params = {
          page: 1,
          size: 999,
          search: this.activitySearchParams.des || undefined,
          carSeriesId: this.carSeriesId || undefined,
          memberId: this.activitySearchParams.memberId || undefined,
          promotionRange: this.activitySearchParams.promotionRange || undefined,
          cityId: this.activitySearchParams.cityId || undefined,
          onShelves: true,
        };
        if (this.activitySearchParams.checkTimeRange?.length) {
          params.startDate = moment(this.activitySearchParams.checkTimeRange[0]).format('YYYY-MM-DD');
          params.endDate = moment(this.activitySearchParams.checkTimeRange[1]).format('YYYY-MM-DD');
        }
        const { code, data, message } = await api.getXhsActivityInfoList(params).finally(() => {
          this.activityTableLoading = false;
        });
        if (code === 200) {
          this.activityTableList = data.list.map((v) => {
            let dealerArrString = '全国通用';
            let areaList = '';
            let dealerList = '';
            let strArr = [];
            if (v.promotionRange === 'PROVINCE') {
              v.cityArr.forEach((value) => {
                strArr.push(value.cityName);
              });
              if (strArr.length > 0) {
                areaList = strArr.reduce((prev, item) => {
                  prev += `、${item}`;
                  return prev;
                });
              }
              dealerArrString = areaList;
            }
            if (v.promotionRange === 'DEALER') {
              v.dealerArr.forEach((value) => {
                strArr.push(value.memberName);
              });
              if (strArr.length > 0) {
                dealerList = strArr.reduce((prev, item) => {
                  prev += `、${item}`;
                  return prev;
                });
              }
              dealerArrString = dealerList;
            }
            return { ...v, dealerArrString };
          });
          resolve(data.list);
        } else {
          this.$message.error(message);
          resolve([]);
        }
      });
    },

    async getAllActivityList() {
      const params = {
        page: 1,
        size: 999,
      };
      this.activityTableLoading = true;
      const { code, data, message } = await api
        .getXhsActivityInfoList(params)
        .finally(() => (this.activityTableLoading = false));
      if (code === 200) {
        this.allActivityList = data.list.map((v) => {
          let dealerArrString = '全国通用';
          if (v.dealerArr?.length) dealerArrString = this.getActivityRangeName(v.dealerArr);
          return { ...v, dealerArrString };
        });
      } else {
        this.$message.error(message);
      }
    },

    getActivityRangeName(arr) {
      let rtName = '';
      let strArr = [];
      arr.forEach((value) => {
        const string = value.memberCode ? `${value.memberName}(${value.memberCode})` : value.memberName;
        strArr.push(string);
      });
      rtName = strArr.reduce((prev, item) => {
        prev += `、${item}`;
        return prev;
      });
      return rtName;
    },

    activityFilterOption(inputValue, option) {
      return option.componentOptions.propsData.label.indexOf(inputValue) > -1;
    },

    async getCarSeriesMemberList() {
      this.activitySearchParams.memberId = undefined;
      const { code, data, message } = await api.getCarSeriesMemberList({ carSeriesId: this.carSeriesId });
      if (code === 200) {
        this.activityGroupList = data;
      } else {
        this.$message.error(message);
      }
    },

    async getCityList() {
      this.activitySearchParams.cityId = undefined;
      const { code, data, message } = await api.getCityList({ carSeriesId: this.carSeriesId });
      if (code === 200) {
        this.cityList = data;
      } else {
        this.$message.error(message);
      }
    },

    onActivitySelectChange(activitySelectedRowKeys) {
      this.activitySelectedRowKeys = activitySelectedRowKeys;

      if (this.activitySelectedRowKeys.length > 5) {
        this.activitySelectedRowKeys = this.activitySelectedRowKeys.slice(0, 5);
        this.$message.warning('最多只能选择5个优惠活动');
      }

      this.currentActivityChooseList = this.allActivityList.filter(
        (x) => this.activitySelectedRowKeys.indexOf(x.id) > -1
      );
    },

    checkOverTime(time) {
      const nowDate = moment();
      const selectTime = moment(time);
      return selectTime.isBefore(nowDate);
    },

    handleActivityColumnClick({ id }) {
      const currentChooseIndex = this.activitySelectedRowKeys.indexOf(id);
      if (currentChooseIndex !== -1) {
        this.activitySelectedRowKeys.splice(currentChooseIndex, 1);
        this.currentActivityChooseList = this.allActivityList.filter(
          (x) => this.activitySelectedRowKeys.indexOf(x.id) > -1
        );
      } else {
        if (this.activitySelectedRowKeys.length < 5) {
          this.activitySelectedRowKeys.push(id);
          this.onActivitySelectChange(this.activitySelectedRowKeys);
        } else {
          return this.$message.warning('最多只能选择5个活动');
        }
      }
    },

    onActivityTimeChange(dates, dateStrings) {
      if (dates && dates.length) {
        this.activitySearchParams.checkTimeRange = [dates[0], dates[1]];
      } else {
        this.activitySearchParams.checkTimeRange = [];
      }
      this.getActivityList();
    },

    handleAddActivityConfirm() {
      const newList = [];
      this.activitySelectedRowKeys.map((v) => {
        const item = this.allActivityList.find((v1) => v1.id === v);
        item?.id && newList.push(item);
      });
      this.$emit('handleAddActivityConfirm', newList);
      this.handleActivityClose();
    },

    handleAddActivity() {
      this.$refs.ActivityUpdateModalComp.handleAddFixedActivity({ ...this.currentBrandData });
    },

    handleTypeChange(val) {
      if (val !== 'DEALER') this.activitySearchParams.memberId = undefined;
      if (val !== 'PROVINCE') this.activitySearchParams.cityId = undefined;
      this.getActivityList();
    },
    async handleAddDone() {
      this.activityTableLoading = true;
      await this.getAllActivityList().finally(() => (this.activityTableLoading = false));
      this.getActivityList();
    },
  },
};
</script>

<style lang="less" scoped>
.activity_drawer {
  ::v-deep .ant-drawer-body {
    padding-bottom: 60px;
  }
}

.current_car_series {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dealerArr_class {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.des_column {
  position: relative;
  width: 316px;

  .des_short {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .des_long {
    display: none;
    position: absolute;
    top: -16px;
    left: -15px;
    padding: 10px;
    width: 110%;
    min-height: 54px;
    background-color: #fff;
    border: 1px solid #1e6fff;
    z-index: 1;
  }

  &:hover {
    .des_long {
      display: block;
    }
  }
}
</style>
