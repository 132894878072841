<template>
  <!-- 运营爆文/平台爆款 -->
  <div class="suggest_list">
    <div class="scroll_wrap higher_scroll">
      <a-spin :spinning="spinning" size="large" tip="加载中...">
        <div
          class="writtingPreview"
          v-for="(record, index) in listData"
          :key="index"
          @click="handleOpenNotePreview(record)"
        >
          <div class="previewLeft">
            <img :src="record.previewImageUrl" referrerpolicy="no-referrer" alt="" />
          </div>
          <div class="previewRight">
            <div class="rightTitle" :title="record.noteTitle">
              {{ record.noteTitle }}
            </div>
            <div class="rightId">
              <div class="rightIdL">ID：{{ record.id }}</div>
              <a-icon
                @click.stop="() => {}"
                type="copy"
                v-clipboard:copy="record.id"
                v-clipboard:success="onCopy"
                v-clipboard:error="onCopyError"
              />
            </div>
          </div>
        </div>
        <div style="text-align: center; color: #ccc; height: 100px; line-height: 100px" v-show="!listData.length">
          该选题暂无笔记
        </div>
      </a-spin>
    </div>

    <!-- 查看/编辑抽屉 -->
    <WrittingDrawer
      ref="writtingDrawer"
      v-if="showDrawer"
      :readOnly="true"
      :topicId="topicId"
      @closeDrawer="closeWrittingDrawer"
      @handleRewrite="handleRewrite"
    />
  </div>
</template>

<script>
import WrittingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';

import api from '@/api/xhsAgencyApi';

export default {
  components: { WrittingDrawer },
  props: {
    tagId: {
      default: undefined,
    },
    searchKeyword: {
      default: '',
    },
    principalIdListData: {
      default: () => [],
    },
    carSeriesIdListData: {
      default: () => [],
    },
  },
  data() {
    return {
      listData: [],
      spinning: false,
      // 查看详情相关变量
      showDrawer: false,
      readOnly: false,
      topicId: '',
      topicSelectionType: '',
    };
  },
  computed: {},
  methods: {
    async getDataList() {
      if (this.spinning) return;
      if (!this.tagId) return (this.listData = []);
      this.spinning = true;
      const principalId = this.principalIdListData?.length ? this.principalIdListData.split(',')[0] : undefined;
      const carSeriesId = this.carSeriesIdListData?.length ? this.carSeriesIdListData.split(',')[0] : undefined;
      const params = { tagId: this.tagId, limit: 10, keyword: this.searchKeyword, principalId, carSeriesId };

      const { code, data, message } = await api
        .getRandomNoteList(params, this.topicSelectionType)
        .finally(() => (this.spinning = false));
      if (code == 200) {
        this.listData = data.map((v) => {
          // oss图片
          let previewImageUrl = v.materialArr[0]?.previewUrl || '';
          if (
            v.materialArr[0].previewUrl &&
            (v.materialArr[0].previewUrl.indexOf('afanticar') > -1 ||
              v.materialArr[0].previewUrl.indexOf('afanti') > -1)
          ) {
            // 不处理视频截图
            if (v.materialArr[0].previewUrl.indexOf('snapshot') == -1) {
              if (v.materialArr[0].previewUrl.indexOf('x-oss-process') > -1) {
                previewImageUrl = v.materialArr[0].previewUrl += `/resize,l_120,m_lfit`;
              } else {
                previewImageUrl = v.materialArr[0].previewUrl += `?x-oss-process=image/resize,l_120,m_lfit`;
              }
            }
          }
          return { ...v, previewImageUrl };
        });
      } else {
        this.$message.error(message);
      }
    },
    handleOpenNotePreview(record) {
      this.topicId = record.id;
      this.readOnly = true;
      this.showDrawer = true;
    },
    closeWrittingDrawer() {
      this.readOnly = false;
      this.topicId = undefined;
      this.showDrawer = false;
    },
    handleRewrite(noteId) {
      this.$emit('handleRewrite', noteId);
    },
    onCopy() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.error('复制失败，该浏览器不支持自动复制');
    },
  },
};
</script>

<style lang="less" scoped>
.writtingPreview {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;

  .previewLeft {
    margin-right: 5px;
    width: 50px;
    height: 50px;
    background-color: #80808033;
    background-size: cover;
    background-position: center;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .previewRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .rightTitle {
      width: 260px;
      font-weight: 600;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .rightId {
      display: flex;
      align-items: center;
      color: #ae7983;

      .rightIdL {
        width: 245px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.scroll_wrap {
  max-height: 713px;
  overflow-y: auto;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}

.higher_scroll {
  max-height: 900px;
}
</style>
