<template>
  <a-radio-group default-value="AI_GENERATED_MINIMAL_HUMAN_EDITING" @change="handleChange">
    <a-radio class="radio-item" value="AI_GENERATED_MINIMAL_HUMAN_EDITING"> Ai生成，没有或极少人工修改</a-radio>
    <a-radio class="radio-item" value="AI_GENERATED_SIGNIFICANT_HUMAN_EDITING"> Ai生成，人工修改较多 </a-radio>
    <a-radio class="radio-item" value="FULLY_HUMAN_CREATED"> 完全人工完成 </a-radio>
  </a-radio-group>
</template>

<script>
export default {
  name: 'AiChooseComp',
  methods: {
    handleChange(val) {
      this.$emit('change', val);
    },
  },
};
</script>

<style lang="less" scoped>
.radio-item {
  display: block;
  height: 30px;
  line-height: 30px;
}
</style>
