<template>
  <!-- 媒体号弹窗 使用:运营计划/爱写笔记 -->
  <div>
    <div class="title">快捷选择：</div>
    <a-spin :spinning="spinning" size="large" tip="加载中...">
      <div class="media-list">
        <div class="account-content" v-for="value in mediaList" :key="value.id" @click="handleMediaClick(value)">
          <div class="account-info">
            <div class="account-name">{{ value.nickname || '-' }}</div>
            <div>
              {{ value.matrixMemberName || '-' }}
            </div>
          </div>
          <div>
            <a-tooltip>
              <template slot="title">
                <div>授权时间：{{ value.p1OauthTime || '-' }}</div>
              </template>
              <div class="sync-succ" v-show="value.p1OauthStatus === 1">
                笔记数据
                <a-icon style="margin-left: 5px" type="check-circle" />
              </div>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                <div>失效时间：{{ value.p1OauthExpireTime || '-' }}</div>
              </template>
              <div class="sync-err" v-show="value.p1OauthStatus !== 1">
                笔记数据<a-icon style="margin-left: 5px" type="exclamation-circle" />
              </div>
            </a-tooltip>
          </div>
        </div>
        <!-- <div
          :class="['media-item', { 'current-item': currentMedia.id === value.id }]"
          v-for="value in mediaList"
          :key="value.id"
          @click="handleMediaClick(value)"
        >
          <div class="media-left">
            <div class="left-left" v-show="value.avatar">
              <img :src="value.avatar" alt="头像" referrerpolicy="no-referrer" />
            </div>
            <div class="left-right">
              <div style="margin-left: 15px">{{ value.nickname }}</div>
              <div class="line-flex right-txt">
                <div class="logo" v-show="value.code">
                  <img src="@/assets/icon/xhs_logo.png" alt="" />
                </div>
                <div>{{ value.code || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="media-right">
            <a-tooltip>
              <template slot="title">
                <div>授权时间：{{ value.p1OauthTime || '-' }}</div>
              </template>
              <div class="sync-succ" v-show="value.p1OauthStatus === 1">
                笔记数据
                <a-icon style="margin-left: 5px" type="check-circle" />
              </div>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                <div>失效时间：{{ value.p1OauthExpireTime || '-' }}</div>
              </template>
              <div class="sync-err" v-show="value.p1OauthStatus !== 1">
                笔记数据<a-icon style="margin-left: 5px" type="exclamation-circle" />
              </div>
            </a-tooltip>
          </div>
        </div> -->
      </div>
    </a-spin>
  </div>
</template>

<script>
import { personalityTypeList } from '../constant';
import api from '@/api/xhsAgencyApi';

export default {
  props: {
    limited: {
      default: false,
    },
    limitedName: {
      default: undefined,
    },
  },
  data() {
    return {
      personalityTypeList,
      showMedia: true,
      mediaList: [],
      creatorList: [],
      currentMedia: {},
      currentManagerList: [],
      allGroupList: [],
      form: {},
      spinning: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // this.form.creator = this.$router.app.user.username;
      this.form.masterAccountUsername = this.$router.app.user.username || undefined;
      this.getCreatorList();
      this.getCurrentManagerList();
      await this.getAllGroupList();
      if (this.limited) {
        const find = this.allGroupList.find((v) => v.groupName == this.limitedName);
        if (find?.id) this.form.groupId = find.id;
      }
      this.getMediaList();
    },
    async getMediaList() {
      const params = {
        page: 1,
        size: 100,
        status: 'NORMAL',
        ...this.form,
      };
      this.spinning = true;
      await this.handleRequest(
        'getAgencyList',
        (data) => {
          this.mediaList = data.list;
        },
        params
      ).finally(() => (this.spinning = false));
    },
    handleMediaCancel() {
      this.showMedia = false;
      this.$emit('closeModal');
    },
    handleMediaClick(value) {
      // if (value.p1OauthStatus !== 1) {
      //   return;
      // } else {
      this.currentMedia = value;
      this.$emit('mediaConfirm', this.currentMedia);
      // }
    },
    handleSearch() {
      this.currentMedia = {};
      this.getMediaList();
    },
    async getCreatorList() {
      this.handleRequest('getMediaCreatorList', (data) => {
        this.creatorList = data;
      });
    },
    getCurrentManagerList() {
      this.handleRequest('getCurrentManagerList', (data) => {
        this.currentManagerList = data;
      });
    },
    getAllGroupList() {
      return new Promise((resolve) => {
        const params = {
          pageNum: 1,
          pageSize: 9999,
          groupScene: 6,
        };
        this.handleRequest(
          'getMediaGroupList',
          (data) => {
            this.allGroupList = data.list;
          },
          params
        ).finally(() => resolve());
      });
    },
    async handleMediaOk() {
      if (!this.currentMedia.code) return this.$message.info('请选择媒体号');
      this.$emit('mediaConfirm', this.currentMedia);
      this.handleMediaCancel();
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin: 10px 0;
}

.media-list {
  display: flex;
  flex-direction: column;

  .account-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    padding: 8px 16px;
    margin-bottom: 5px;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    cursor: pointer;
    &:hover {
      border-color: #40a9ff;
    }
  }
  .account-info {
    display: flex;
    flex-direction: column;
    .account-name {
      margin-bottom: 4px;
      color: #40a9ff;
    }
  }
}

.sync-err {
  margin: 0 auto;
  width: 100px;
  padding-left: 15px;
  border-radius: 5px;
  color: #f59a23;
  background-color: #facd91;
}

.sync-succ {
  margin: 0 auto;
  width: 100px;
  padding-left: 15px;
  border-radius: 5px;
  color: #5ac822;
  background-color: #caf982;
}
</style>
