<template>
  <div class="articleDetail" :style="`position: ${showAddDraftModal ? 'fixed' : ''};`">
    <div class="article_left">
      <div class="btn_col">
        <div
          :class="[
            'btns',
            { current_btn: btn.value == currentBtn },
            { ai_loading: btn.value === 5 && !currentBtn && suggestLoading },
          ]"
          v-for="btn in btnsList"
          :key="btn.value"
          @click="handleBtnClick(btn)"
        >
          <template v-if="btn.value === 5">
            <!-- 智能推荐 -->
            <a-icon type="loading" style="margin-right: 10px" v-show="!currentBtn && suggestLoading" />
            <a-badge :dot="suggestNew">
              {{ btn.text }}
            </a-badge>
          </template>
          <template v-else>
            {{ btn.text }}
          </template>
        </div>
      </div>
      <div class="tool_col" v-show="currentBtn">
        <div v-show="currentBtn == 1" :class="['scroll_wrap', { higher_scroll: showMore }]">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="font-weight: bold">笔记信息</div>
            <a-button icon="double-left" type="link" @click="currentBtn = undefined">收起</a-button>
          </div>
          <a-form-model :model="detailForm">
            <a-form-model-item label="草稿名称" v-show="editType === '草稿'">
              <a-input v-model.trim="detailForm.noteName" placeholder="请输入名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="运营计划">
              <div>
                <a-range-picker
                  v-if="detailForm.publishAccount"
                  style="width: 100%"
                  :placeholder="['开始时间', '结束时间']"
                  :value="checkTimeRange"
                  :allowClear="false"
                  :disabled="isBeforePlan"
                  @change="handleCheckTimeChange"
                />
                <div v-else class="color-gray">请先选择发布账号</div>
              </div>
              <template v-if="planList.length > 0">
                <div class="plan-list">
                  <div
                    v-for="val in planList"
                    :key="val.id"
                    :class="[
                      'plan-item',
                      { 'current-item': isBeforePlan || notePublishPlanId === val.id },
                      val.isRelationNote ? 'unable-item' : 'able-item',
                    ]"
                    @click="handlePlanClick(val)"
                  >
                    <span>{{ val.planPublishDate }}</span>
                    <a-tag
                      :color="val.colorHex || '#ccc'"
                      style="min-height: 23px; max-width: 138px; white-space: break-spaces"
                      >{{ val.tagName }}</a-tag
                    >

                    <span style="width: 100px" v-if="val.isRelationNote">已关联其他笔记</span>
                    <span style="width: 100px" v-else-if="notePublishPlanId === val.id"
                      ><a-icon type="check" style="margin-right: 5px" />已选择</span
                    >
                    <span style="width: 100px" v-else></span>
                  </div>
                </div>
              </template>
              <div v-if="detailForm.publishAccount && planList.length === 0" class="color-gray">
                该账号在日期范围内无计划数据
              </div>
            </a-form-model-item>
            <a-form-model-item label="选题">
              <TopicTreeInput
                ref="TopicTreeInput"
                v-model="detailForm.topicSelectionTagIdList"
                :disabled="!!notePublishPlanId && !!tagId"
                show-search
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :replaceFields="{ title: 'name' }"
                placeholder="选题"
                allow-clear
                tree-default-expand-all
                @change="
                  (value, tagName) => {
                    tagId = value;
                    currentTagName = tagName;
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item label="品牌">
              <BrandSelectInput
                style="width: 100%"
                placeholder="品牌"
                v-model="detailForm.principalIdListData"
                show-search
                option-filter-prop="children"
                allowClear
                @onChange="detailForm.carSeriesIdListData = undefined"
              />
            </a-form-model-item>
            <a-form-model-item label="车系">
              <CarSeriesSelectInput
                style="width: 100%"
                :principalIds="principalIds"
                v-model="detailForm.carSeriesIdListData"
                placeholder="车系"
                show-search
                :filter-option="false"
                allowClear
              />
            </a-form-model-item>
          </a-form-model>
        </div>
        <div v-show="currentBtn == 4" :class="['scroll_wrap', { higher_scroll: showMore }]">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="font-weight: bold">发布信息</div>
            <a-button icon="double-left" type="link" @click="currentBtn = undefined">收起</a-button>
          </div>
          <a-form-model :model="detailForm">
            <a-form-model-item required :colon="false" style="width: 100%">
              <template slot="label">
                <div style="width: 300px; display: inline-flex; align-items: center; justify-content: space-between">
                  <span>发布账号:</span>
                  <a-button type="primary" size="small" @click="showAddMedia = true" v-if="!isFromExecution">{{
                    detailForm.publishAccount ? '更换媒体号' : '选择媒体号'
                  }}</a-button>
                </div>
              </template>
              <!-- 添加媒体号弹窗 -->
              <AddMediaComp
                v-if="showAddMedia"
                :limited="limited"
                :limitedName="limitedName"
                @closeModal="showAddMedia = false"
                @mediaConfirm="(val) => mediaConfirm(val.authorId)"
              />
            </a-form-model-item>
            <a-icon type="loading" v-show="mediaSearchLoading" />
            <SendAccountDetail
              v-if="
                mediaDetail.authorId || (mediaDetail.authorDetailInfoDTO && mediaDetail.authorDetailInfoDTO.authorId)
              "
              :mediaDetail="mediaDetail"
            />
            <template v-if="!detailForm.publishAccount">
              <span class="color-gray">请选择发布账号</span>
              <QuickAddMedia
                :limited="limited"
                :limitedName="limitedName"
                @closeModal="showAddMedia = false"
                @mediaConfirm="(val) => mediaConfirm(val.authorId)"
              />
            </template>
            <a-form-model-item label="添加地点">
              <a-select
                v-model="detailForm.position"
                placeholder="支持搜索相关地点信息"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allowClear
                @search="handlePositionSearch"
                @change="handleGetPosition"
                :loading="positionLoading"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(value, index) in positionList"
                  :key="index"
                  :label="value.name"
                  :value="value.poiId"
                >
                  <div>{{ value.name }}</div>
                  <div style="font-size: 12px; color: #cccccc; overflow: hidden; text-overflow: ellipsis">
                    {{ value.subname }}
                  </div>
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="权限设置" prop="privacy">
              <a-radio-group v-model="detailForm.privacy">
                <a-radio value="PUBLIC">
                  <span>公开</span>
                  <span style="color: rgba(128, 128, 128, 0.661)">(所有人可见)</span>
                </a-radio>
                <a-radio value="PRIVATE">
                  <span>私密</span>
                  <span style="color: rgba(128, 128, 128, 0.661)">(仅自己可见)</span>
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="发布方式" prop="publishType">
              <a-radio-group v-model="detailForm.publishType">
                <a-radio :value="1"> 立即发布 </a-radio>
                <a-radio :value="2"> 定时发布 </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item v-if="detailForm.publishType === 2" label="发布时间" prop="postTime">
              <a-date-picker
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD HH:mm"
                :disabledDate="disabledDate"
                placeholder="请选择发布时间"
                v-model="detailForm.postTime"
              >
                <template #renderExtraFooter>
                  <a-button style="width: 100%" type="link" @click="handleAddTime">1.5h后</a-button>
                </template>
              </a-date-picker>
            </a-form-model-item>
            <a-form-model-item label="IP地址">
              <div style="line-height: 20px">百夫长发布是广东</div>
              <div style="line-height: 20px">推送后是风火轮APP端所在IP</div>
            </a-form-model-item>
          </a-form-model>
        </div>
        <template v-if="currentBtn == 2">
          <WritingAssistantComp
            ref="WritingAssistantComp"
            :tagId="tagId"
            @useTemplate="handleUseTemplate"
            @handleRewrite="handleTemplateRewrite"
            @close="currentBtn = undefined"
          />
        </template>
      </div>
    </div>

    <div class="article_right">
      <div class="right_wrap">
        <!-- 上传 -->
        <div class="upload_wrap">
          <div class="upload_top">
            <div class="tab-line">
              <a-space>
                <a-popconfirm
                  v-show="showImgType"
                  title="切换为图片笔记?"
                  ok-text="确认"
                  placement="bottomLeft"
                  cancel-text="取消"
                  :disabled="noteType === 'IMAGE'"
                  @confirm="handleTabChange('IMAGE')"
                >
                  <div :class="['tab_item', { currentTab: noteType === 'IMAGE' }]">上传图片</div>
                </a-popconfirm>
                <a-popconfirm
                  v-show="showVideoType"
                  title="切换为视频笔记?"
                  ok-text="确认"
                  placement="bottomLeft"
                  cancel-text="取消"
                  :disabled="noteType === 'VIDEO'"
                  @confirm="handleTabChange('VIDEO')"
                >
                  <div :class="['tab_item', { currentTab: noteType === 'VIDEO' }]">上传视频</div>
                </a-popconfirm>
              </a-space>
            </div>
            <a-button type="link" slot="tabBarExtraContent" @click="showMore = !showMore">{{
              showMore ? '收起' : '展开'
            }}</a-button>
          </div>
          <div class="upload_bottom" v-show="showMore">
            <template v-if="prePicData.show && noteType === 'IMAGE'">
              <div class="pre_pic">
                <template v-if="prePicData.status == 'IN_PRODUCTION'">
                  <div class="pre_loading"><a-icon style="margin-right: 5px" type="loading" />正在生产内容</div>
                </template>
                <template v-if="prePicData.status == 'FAIL'">
                  <div class="pre_fail">
                    <div style="font-size: 20px; color: #f00">生产失败</div>
                    <!-- <a-button style="margin: 20px 0" type="link" @click="handleRedoPreData">再次生产</a-button> -->
                    <a-button style="margin: 20px 0" type="link" @click="prePicData.show = false">人工撰写</a-button>
                    <div style="text-align: center">{{ prePicData.errMessage }}</div>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <!-- 图片 -->
              <drawerPicComp
                v-show="noteType === 'IMAGE'"
                ref="drawerPicComp"
                :picList="picList"
                :showTxt2ImgTip="showTxt2ImgTip"
                :chooseTagList="chooseTagList"
                :principalInfoList="principalInfoList"
                :vehicleSeriesInfoList="vehicleSeriesInfoList"
                :readOnly="false"
                :disTxt2Img="disTxt2Img"
                @updatePicList="updatePicList"
                @handleTextToImg="handleTextToImg"
                @handlePageOpen="handlePageOpen"
                @handlePicRefresh="handlePicRefresh"
                @handleRedoText="handleRedoText"
                :limited="limited"
              />
            </template>
            <!-- 视频 -->
            <drawerVideoComp
              ref="drawerVideoComp"
              :limited="limited"
              v-show="noteType === 'VIDEO'"
              @handleDataChange="handleSaveTempData"
            />
          </div>
        </div>

        <!-- 笔记 -->
        <div class="note_wrap">
          <div style="display: flex; align-items: center">
            <div style="font-weight: bold">笔记文案</div>
            <div>
              <a-button type="link" @click="handlePreNote" :disabled="currentDataIndex == 1"
                ><a-icon type="left"
              /></a-button>
              <span style="color: #adadad; font-weight: bold"
                ><span style="color: #333; font-weight: bolder">{{ currentDataIndex }}</span
                ><span style="margin: 0 5px">/</span>{{ noteList.length }}</span
              >
              <a-button type="link" @click="handleNextNote" :disabled="currentDataIndex == noteList.length"
                ><a-icon type="right"
              /></a-button>
              <a-tooltip placement="right">
                <template slot="title"> 请先选择【选题】以及【发布账号】 </template>
                <a-button
                  v-if="!limited"
                  size="small"
                  type="link"
                  :disabled="!tagId || !detailForm.publishAccount"
                  @click="handleMakePre"
                  >再来一篇</a-button
                >
              </a-tooltip>
            </div>
          </div>
          <div>
            <!-- 预生产物料 -->
            <div class="pre_data_wrap" v-if="currentNoteData.isPreData && !currentNoteData.isDone">
              <div class="pre_text" v-show="currentNoteData.preLoading">
                <div style="font-size: 20px"><a-icon type="loading" style="margin-right: 10px" />正在生产内容</div>
                <div style="margin: 20px 0">请耐心等待系统生成</div>
              </div>
              <div class="pre_text" v-show="currentNoteData.preError">
                <div style="font-size: 20px; color: #f00">生产失败</div>
                <a-button style="margin: 20px 0" type="link" @click="handleAddNote" v-show="!currentNoteData.hideAgain"
                  >人工撰写</a-button
                >
                <a-button
                  style="margin: 20px 0"
                  type="link"
                  icon="block"
                  @click="jumpToTopic"
                  v-show="currentNoteData.showJumpBtn"
                  >前往选题库</a-button
                >
                <div style="text-align: center">
                  {{ currentNoteData.preErrorMsg }}
                </div>
              </div>
            </div>
            <!-- 编辑器 -->
            <drawerNoteComp
              ref="drawerNoteComp"
              :readOnly="false"
              v-show="currentNoteData.isDone"
              @editorChange="handleEditorChange"
              @titleChange="handleTitleChange"
              @tagListChange="handleTagListChange"
              @aiRewrite="handleAiRewrite"
              @closeRepeat="handleCloseRepeat"
              @closeError="handleCloseError"
              :rewriteTypeList="rewriteTypeList"
            />
            <!-- AI改写 -->
            <div class="pre_data_wrap" v-if="currentNoteData.isAiStory">
              <div class="pre_text" v-show="currentNoteData.aiLoading">
                <div style="font-size: 20px"><a-icon type="loading" style="margin-right: 10px" />正在生成</div>
                <div style="margin: 20px 0">请耐心等待系统生成</div>
              </div>
              <div class="pre_text" v-show="currentNoteData.aiError">
                <div style="font-size: 20px; color: #f00">生产失败</div>
                <div style="margin: 20px 0; text-align: center">
                  {{ currentNoteData.aiErrorMsg }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 按钮 -->
        <div style="padding: 5px; background: #fff" v-show="currentNoteData.isDone">
          <a-space>
            <template v-if="!$route.query.pushId">
              <template>
                <a-tooltip placement="left">
                  <template slot="title">
                    <p>推送后客户可通过风火轮、分享链接任一渠道发布到小红书</p>
                    <div>若需内容还需要审核，请保存草稿后复制链接给客户查看</div>
                  </template>
                  <a-popconfirm
                    placement="top"
                    :visible="pushPopConfirm"
                    title="确认推送到风火轮和分享链接？"
                    ok-text="确认"
                    cancel-text="取消"
                    @confirm="handleNotePushConfirm"
                    @cancel="pushPopConfirm = false"
                  >
                    <a-button
                      style="color: #fff; background-color: #73d13d; border: 0px"
                      :loading="saveLoading"
                      @click="handlePushClick"
                      >推送 <a-icon style="margin-left: 5px" type="question-circle" />
                    </a-button>
                  </a-popconfirm>
                </a-tooltip>
              </template>
              <a-popconfirm
                placement="top"
                title="确认百夫长发布？"
                :visible="publishPopConfirm"
                ok-text="确认"
                cancel-text="取消"
                @confirm="handlePublishConfirm"
                @cancel="publishPopConfirm = false"
              >
                <a-button v-if="!limited" style="color: #1890ff" :loading="saveLoading" @click="handlePublishClick"
                  >百夫长发布</a-button
                >
              </a-popconfirm>
            </template>
            <template v-if="$route.query.pushId">
              <template>
                <a-popconfirm
                  placement="top"
                  title="确认保存？风火轮和分享链接的内容将会同步修改"
                  ok-text="确认"
                  cancel-text="取消"
                  :visible="saveNotePopConfirm"
                  @confirm="handleNoteSaveConfirm"
                  @cancel="saveNotePopConfirm = false"
                >
                  <a-button
                    type="primary"
                    style="color: #fff; background-color: #73d13d; border: 0px"
                    :loading="saveLoading"
                    @click="handleNoteSaveClick"
                    >保存</a-button
                  >
                </a-popconfirm>
              </template>
            </template>
            <a-button v-if="!$route.query.pushId" @click="handleSaveDraftClick" :loading="saveLoading"
              >保存到草稿箱</a-button
            >
            <a-button @click="showChildrenDrawer">预览</a-button>
            <a-button @click="showHistoryDrawer">历史记录</a-button>
          </a-space>
        </div>
      </div>
    </div>

    <!-- 预览 -->
    <a-drawer title="预览" width="360" :closable="false" :visible="childrenDrawer" @close="childrenDrawer = false">
      <div class="previewContainer">
        <div class="pBottom" v-if="this.noteType == 'IMAGE'">
          <div class="picArousal">
            <a-carousel arrows v-if="picList.length">
              <div slot="prevArrow" style="z-index: 1; left: 0">
                <a-icon type="left" />
              </div>
              <div slot="nextArrow">
                <a-icon type="right" style="z-index: 1; right: 0" />
              </div>
              <div class="picArousalItems" v-for="(val, index) in picList" :key="index">
                <img :src="val.fileUrl" alt="" referrerpolicy="no-referrer" />
              </div>
            </a-carousel>
          </div>
          <div class="previewTitle">{{ previewTitle ? previewTitle : '请先填写标题' }}</div>
          <div class="previewContent">{{ previewContent }}</div>
          <div class="previewTag">
            <span class="pTags" v-for="(val, index) in previewTags" :key="index">#{{ val.name }}</span>
          </div>
          <div class="previewTime">{{ previewTime }} 09:14</div>
          <div class="fakeComment">
            <img src="@/assets/images/xhs_bg_bt.png" alt="" />
          </div>
        </div>
        <div v-if="this.noteType == 'VIDEO'">
          <video class="pBottom" :src="previewVideoUrl" controls></video>
        </div>
      </div>
    </a-drawer>

    <div class="upload_dialog" v-show="showVideoPublish" style="user-select: none">
      <div class="upload_tips">
        <div class="title">正在发布</div>
        <div class="text">
          <a-icon type="sync" style="font-size: 36px; margin-right: 50px" spin />
          <span>发布视频时间较长，请勿关闭此页面</span>
        </div>
      </div>
    </div>

    <div class="upload_dialog" v-show="showAddDraftModal">
      <div class="upload_tips" style="width: 450px">
        <div class="title" style="font-size: 16px">新建草稿，请填写基础信息</div>
        <template v-if="addDraftStep === 1">
          <div class="add_form">
            <a-form-model :model="detailForm">
              <a-form-model-item label="发布账号" required>
                <a-select
                  v-model="detailForm.publishAccount"
                  @change="handleMediaChange"
                  placeholder="请选择发布账号"
                  show-search
                  option-filter-prop="children"
                >
                  <a-select-option v-for="val in mediaList" :key="val.authorId" :value="val.authorId">{{
                    val.nickname
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <AccountDetailComp
                v-if="
                  mediaDetail.authorId || (mediaDetail.authorDetailInfoDTO && mediaDetail.authorDetailInfoDTO.authorId)
                "
                :showMore="false"
                :mediaDetail="mediaDetail"
              />
            </a-form-model>
            <template>
              <a-spin :spinning="searchMediaLoading">
                <div>
                  <span style="font-weight: bolder"> 计划日期： </span>
                  <a-range-picker
                    style="width: 80%"
                    :placeholder="['开始时间', '结束时间']"
                    :value="checkTimeRange"
                    :allowClear="false"
                    @change="handleCheckTimeChange"
                  />
                </div>
                <template v-if="planList.length > 0">
                  <div style="margin: 10px 0">该媒体号已设置以下运营计划，是否关联</div>
                  <div class="plan-list">
                    <div
                      v-for="val in planList"
                      :key="val.id"
                      :class="[
                        'plan-item',
                        { 'current-item': notePublishPlanId === val.id },
                        val.isRelationNote ? 'unable-item' : 'able-item',
                      ]"
                      @click="handlePlanClick(val)"
                    >
                      <span>{{ val.planPublishDate }}</span>
                      <a-tag
                        :color="val.colorHex || '#ccc'"
                        style="min-height: 23px; max-width: 138px; white-space: break-spaces"
                        >{{ val.tagName }}</a-tag
                      >
                      <span style="width: 100px" v-if="val.isRelationNote">已关联其他笔记</span>
                      <span style="width: 100px" v-else-if="notePublishPlanId === val.id"
                        ><a-icon type="check" style="margin-right: 5px" />已选择</span
                      >
                      <span style="width: 100px" v-else></span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="tips-line">该账号在日期范围内无计划数据</div>
                </template>
              </a-spin>
            </template>
          </div>
        </template>
        <template v-if="addDraftStep === 2">
          <div class="add_form">
            <a-form-model :model="detailForm">
              <a-form-model-item label="选题" required>
                <TopicTreeInput
                  :disabled="!!notePublishPlanId && !!tagId"
                  v-model="detailForm.topicSelectionTagIdList"
                  show-search
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :replaceFields="{ title: 'name' }"
                  placeholder="选题"
                  allow-clear
                  tree-default-expand-all
                  @change="
                    (value, tagName) => {
                      tagId = value;
                      currentTagName = tagName;
                    }
                  "
                />
              </a-form-model-item>
              <a-form-model-item label="品牌" required>
                <BrandSelectInput
                  style="width: 100%"
                  placeholder="品牌"
                  v-model="detailForm.principalIdListData"
                  show-search
                  option-filter-prop="children"
                  allowClear
                  @onChange="detailForm.carSeriesIdListData = undefined"
                />
              </a-form-model-item>
              <a-form-model-item label="车系">
                <CarSeriesSelectInput
                  style="width: 100%"
                  :principalIds="principalIds"
                  v-model="detailForm.carSeriesIdListData"
                  placeholder="车系"
                  show-search
                  :filter-option="false"
                  allowClear
                />
              </a-form-model-item>
              <a-form-model-item label="发布方式" prop="publishType">
                <a-radio-group v-model="detailForm.publishType">
                  <a-radio :value="1"> 立即发布 </a-radio>
                  <a-radio :value="2"> 定时发布 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item v-if="detailForm.publishType === 2" label="发布时间" prop="postTime">
                <a-date-picker
                  :show-time="{ format: 'HH:mm' }"
                  format="YYYY-MM-DD HH:mm"
                  :disabledDate="disabledDate"
                  placeholder="请选择发布时间"
                  v-model="detailForm.postTime"
                >
                  <template #renderExtraFooter>
                    <a-button style="width: 100%" type="link" @click="handleAddTime">1.5h后</a-button>
                  </template>
                </a-date-picker>
              </a-form-model-item>
            </a-form-model>
          </div>
        </template>
        <div style="text-align: right; padding: 10px 15px">
          <div style="display: flex; justify-content: flex-end; align-items: center">
            <div style="text-align: left; flex: 1" v-show="!$route.query.planId">
              不关联计划无法统计发布数据<br />确定关联后无法修改！
            </div>
            <div style="display: flex; justify-content: flex-end">
              <a-button
                :loading="addDraftLoading"
                v-show="addDraftStep === 2 && !$route.query.planId"
                :disabled="!detailForm.publishAccount"
                style="margin-right: 5px"
                @click="addDraftStep = 1"
                >上一步</a-button
              >
              <a-button
                type="primary"
                :loading="addDraftLoading"
                v-show="addDraftStep === 1"
                :disabled="!detailForm.publishAccount"
                @click="handleAddNext"
                >下一步</a-button
              >
              <a-button type="primary" :loading="addDraftLoading" v-show="addDraftStep === 2" @click="handleAddDraft"
                >创建草稿</a-button
              >
            </div>
          </div>
        </div>
      </div>
      <div style="height: 120px"></div>
    </div>

    <AiWritingDrawerComp
      :limited="limited"
      :limitedName="limitedName"
      ref="AiWritingDrawerComp"
      @chooseText="(d) => handleUseTemplate(d, true)"
    />

    <AISuggestNoteDrawerComp
      ref="AISuggestNoteDrawerComp"
      @updateLoading="updateLoading"
      @getMore="handleGetMore"
      @updateNew="handleUpdateNew"
    />

    <NoteHistoryListComp ref="NoteHistoryListComp" @useDraft="handleUseDraft" />

    <div class="fix_tips">当前编辑：{{ editType }}</div>
  </div>
</template>

<script>
import moment from 'moment';
import { handleNoteCheck } from '@/pages/app/xhsAgency/utils.js';
import { highlightString } from '@/utils/index';

import AccountDetailComp from './components/AccountDetailComp';
import SendAccountDetail from './components/SendAccountDetail';
import QuickAddMedia from './components/QuickAddMedia';
import drawerPicComp from '@/components/xhsAgencyTool/drawerPicComp.vue';
import drawerVideoComp from '@/components/xhsAgencyTool/drawerVideoComp.vue';
import drawerNoteComp from '@/components/xhsAgencyTool/drawerNoteComp.vue';
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';
import MessageTextComp from '@/components/MessageTextComp.vue';
import AiChooseComp from '@/components/AiChooseComp.vue';
import AddMediaComp from '@/pages/app/xhsAgency/aigc/components/AddMediaComp.vue';
import AiWritingDrawerComp from '@/pages/app/xhsAgency/aigc/components/AiWritingDrawerComp.vue';
import WritingAssistantComp from './components/WritingAssistantComp.vue';
import AISuggestNoteDrawerComp from '@/pages/app/xhsAgency/aigc/components/AiSuggestNoteDrawerComp.vue';
import NoteHistoryListComp from '@/pages/app/xhsAgency/aigc/components/NoteHistoryListComp.vue';

import Cookies from 'js-cookie';
import { getTopDomain } from '@/utils/auth.js';
import { trackRequest } from '@/utils/track';
import { debounce, cloneDeep } from 'lodash';

import api from '@/api/xhsAgencyApi';

export default {
  name: 'aigcArticleDetail',
  components: {
    AccountDetailComp,
    SendAccountDetail,
    QuickAddMedia,
    drawerPicComp,
    drawerVideoComp,
    drawerNoteComp,
    TopicTreeInput,
    BrandSelectInput,
    CarSeriesSelectInput,
    MessageTextComp,
    AiChooseComp,
    AddMediaComp,
    AiWritingDrawerComp,
    WritingAssistantComp,
    AISuggestNoteDrawerComp,
    NoteHistoryListComp,
  },
  data() {
    return {
      currentBtn: undefined,
      detailForm: {
        name: undefined,
        principalIdListData: [],
        carSeriesIdListData: [],
        publishType: 1,
        privacy: 'PUBLIC',
        position: undefined,
        postTime: null,
        topicSelectionTagIdList: null,
      },
      tagId: undefined,
      principalInfoList: [{ principalId: undefined }],
      vehicleSeriesInfoList: [{ vehicleSeriesId: undefined }],
      storyText: '',
      storyRole: '',
      wordCount: 0,
      storyData: { id: null },
      isNew: false,
      mediaList: [],
      mediaDetail: {
        authorDetailInfoDTO: {},
      },
      positionList: [],
      positionLoading: false,
      currentPosition: {},
      saveLoading: false,
      childrenDrawer: false,
      previewTitle: '',
      previewContent: '',
      previewTags: [],
      previewTime: moment().format('YYYY-MM-DD'),
      mediaSearchLoading: false,
      preWaitIdList: [],
      timerInstance: null,
      notePublishPlanId: undefined,
      rewriteTypeList: [],
      pushType: null, //发布渠道 XIAOHONGSHU=小红书，BAIFUZHANG=百夫长，FENGHUOLUN=风火轮,SHARE_PUBLISH_LINK=分享链接
      // --------------------------------------------------------------------------------图片相关
      picList: [],
      chooseTagList: [],
      showMore: true,
      // --------------------------------------------------------------------------------文案相关
      noteList: [],
      currentDataIndex: 1,
      currentNoteData: {},
      initNoteParams: {
        isDone: true, // 展示文案富文本
        isPreData: false, // 是否预生产内容
        preLoading: false, // 预生产状态
        preError: false, // 预生产错误
        preErrorMsg: '', // 错误信息
        isAiStory: false, // 是否AI内容
        aiLoading: false, // AI状态
        aiError: false, // AI错误
        aiErrorMsg: '', // 错误信息
        noteTitle: '', // 文案标题
        noteDetail: '', // 文案内容
        noteDetailHtml: '', // 文案HTML
        xhsTopicList: [], // 话题
        currentNoteData: false, // 风险词检测
        isNoteCheckRepeat: false, // 插入模板高亮
        checkRepeatNote: '', // 高亮修改校验字符
        xhsPreCopywritingId: '', // 预生产文案ID
        xhsAiContentId: '', // AI作文生成ID
      },
      fromRouterName: '',
      noteType: 'IMAGE', // 上传类型 IMAGE VIDEO
      showAddMedia: false,
      previewVideoUrl: undefined,
      showImgType: true, // 已发布/推送的笔记不能切换类型
      showVideoType: true, // 已发布/推送的笔记不能切换类型
      showVideoPublish: false, // 上传视频蒙层
      disTxt2Img: true,
      preCopywritingId: undefined,
      showTxt2ImgTip: false,
      currentTagName: undefined,
      firstClickPrice: true,
      firstClickAi: true,
      limited: false,
      limitedName: undefined,
      startLog: false, //开始记录缓存
      preNodeDone: true,
      prePicData: {
        show: false,
        status: '',
        errMessage: '',
      },
      showAddDraftModal: false,
      gptLoading: false,
      holidayList: [],
      addDraftLoading: false,
      suggestLoading: false,
      suggestNew: false,
      addDraftStep: 1,
      searchMediaLoading: false,
      checkTimeRange: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
      planList: [],
      isFromExecution: false, // 已有执行计划
      isBeforePlan: false,
      saveNotePopConfirm: false,
      pushPopConfirm: false,
      publishPopConfirm: false,
      publishParams: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name) vm.fromRouterName = from.name;
    });
  },
  created() {
    this.init();
  },
  mounted() {
    // 未保存提醒
    const _this = this;
    window.addEventListener('beforeunload', function (e) {
      const index = _this.getLocalDraftIndex();
      if (_this.$route.name === 'articleDetail' && index !== -1) {
        e.preventDefault();
        e.returnValue = '确定要离开此页面吗?';
      }
    });
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        const saveId = _this.$route.query.draftId
          ? _this.$route.query.draftId
          : _this.$route.query.pushId
          ? _this.$route.query.pushId
          : undefined;
        const list_s = window.localStorage.getItem('tempNote_list');
        const list_p = list_s ? JSON.parse(list_s) : undefined;
        if (!saveId || !list_s || !list_p?.length) {
          return;
        }
        const index = list_p.findIndex((v) => v.saveId === saveId);
        if (index !== -1) _this.useLocalData(list_p[index]);
      }
    });
  },
  beforeDestroy() {
    if (this.timerInstance) {
      clearInterval(this.timerInstance);
    }
  },
  methods: {
    async handleCheckLocalSave() {
      // 校验权限
      const { limited, limitedName } = await this.checkLimit();
      this.limitedName = limitedName;
      this.limited = limited;
      const saveId = this.$route.query.draftId
        ? this.$route.query.draftId
        : this.$route.query.pushId
        ? this.$route.query.pushId
        : undefined;
      const list_s = window.localStorage.getItem('tempNote_list');
      const list_p = list_s ? JSON.parse(list_s) : undefined;
      if (!saveId || !list_s || !list_p?.length) {
        return;
      }
      const needHandle = await this.checkTempData(list_p, saveId);
      if (needHandle) {
        // 使用缓存
        const index = list_p.findIndex((v) => v.saveId === saveId);
        if (index !== -1) this.useLocalData(list_p[index]);
      } else {
        this.handleClearLocalStorageData();
      }
    },
    /**
     * 初始化数据
     */
    async init() {
      this.isFromExecution = this.$route.query.principalId || this.$route.query.pushId ? true : false;
      this.getRewriteTypeList();
      if (this.$route.query.draftId) {
        // -------------------------------------草稿回显
        await this.initDraftEdit();
        this.handleCheckLocalSave();
        this.currentBtn = 4;
        return;
      }
      if (this.$route.query.planId) {
        // -------------------------------------运营计划回显
        this.notePublishPlanId = this.$route.query.planId;
        this.detailForm.principalIdListData = this.$route.query.principalId + ',' + this.$route.query.principalName;
        await this.initPlanEdit();
        await this.getMediaList();
        // 设置媒体号会被覆盖，重新设置
        this.notePublishPlanId = this.$route.query.planId;
        await this.handleAddDraft();
        // this.showAddDraftModal = true;
        // this.addDraftStep = 2;
        return;
      }
      if (this.$route.query.pushId) {
        // -------------------------------------已推送成员笔记回显
        await this.initPushEdit();
        this.handleCheckLocalSave();
        return;
      }
      if (this.$route.query.noteId) {
        this.handleAddNote();
        this.handleTemplateRewrite(this.$route.query.noteId);
        return;
      }
      if (this.$route.query.tempId) {
        this.handleGetLocalImg();
        this.handleAddNote();
      }
      // ---------------------------------------新增草稿/笔记
      if (!Object.keys(this.$route.query).length) {
        await this.getMediaList();
        // this.showAddDraftModal = true;
        // this.getLocalNewDraftParams();
        this.detailForm.principalIdListData = undefined;
        this.detailForm.carSeriesIdListData = undefined;
        this.handleAddDraft();
        this.currentBtn = 4;
        return;
      }
    },
    async getRewriteTypeList() {
      const { code, data, message } = await api.getToolTypeList();
      if (code === 200) {
        this.rewriteTypeList = data;
      } else {
        this.$message.error(message);
      }
    },
    async getMediaList() {
      const params = {
        page: 1,
        size: 200,
        status: 'NORMAL',
      };
      await this.handleRequest(
        'getAgencyList',
        (data) => {
          this.mediaList = data.list;
        },
        params
      );
    },
    async getMediaData(authorId, searchLoc = false) {
      this.mediaSearchLoading = true;
      await this.handleRequest(
        'getMediaDetailById',
        (data) => {
          this.mediaDetail = data;
          // 地址回显
          if (data.authorDetailInfoDTO?.postLoc?.poiId && searchLoc) {
            this.getMediaLocation(data.authorDetailInfoDTO.postLoc);
          }
        },
        { authorId }
      ).finally(() => (this.mediaSearchLoading = false));
    },
    async getMediaLocation(pData) {
      if (pData.name) {
        this.positionLoading = true;
        this.handleRequest(
          'getPublishLocation',
          (data) => {
            this.positionList = data;
            data.map((val) => {
              if (val.poiId == pData.poiId) {
                this.detailForm.position = val.poiId;
                this.currentPosition = val;
              }
            });
          },
          {
            keyword: pData.name,
          }
        ).finally(() => (this.positionLoading = false));
      }
    },
    /**
     * 发布账号变化
     */
    async handleMediaChange(val) {
      this.mediaDetail = {};
      // 媒体号信息回显
      this.detailForm.position = null;
      this.currentPosition = {};
      this.planList = [];
      this.notePublishPlanId = undefined;
      if (val) {
        this.addDraftLoading = true;
        await this.getMediaData(val, true).finally(() => (this.addDraftLoading = false));
      }
      this.searchMediaPlanList();
    },
    /**
     * 选择媒体号
     */
    async mediaConfirm(authorId) {
      this.mediaDetail = {};
      this.detailForm.publishAccount = authorId;
      // 媒体号信息回显
      this.detailForm.position = null;
      this.currentPosition = {};
      authorId && (await this.getMediaData(authorId, true));
      // 来自运营执行，无法修改计划
      if (this.isFromExecution) return;
      this.searchMediaPlanList();
    },

    handleGetAiCheck() {
      return new Promise(async (resolve) => {
        let result = 'AI_GENERATED_MINIMAL_HUMAN_EDITING';
        const changeHandle = ({ target: { value } }) => {
          result = value;
        };
        const messageComp = this.$createElement(AiChooseComp, {
          on: { change: changeHandle },
        });
        this.$confirm({
          title: '请问笔记内容是否有AI参与?',
          content: messageComp,
          onOk() {
            console.log('result ->', result);
            resolve(result);
          },
          onCancel() {
            resolve(false);
          },
        });
      });
    },
    showChildrenDrawer() {
      this.handleTrack('preview_note');
      if (this.noteType == 'IMAGE') {
        if (!this.picList.length) return this.$message.error('至少需要一张图片');
        this.childrenDrawer = true;
        this.previewTitle = this.$refs.drawerNoteComp.noteTitle;
        this.previewContent = this.$refs.drawerNoteComp.noteString;
        this.previewTags = this.$refs.drawerNoteComp.tagList;
      }
      if (this.noteType == 'VIDEO') {
        this.previewVideoUrl = this.$refs.drawerVideoComp.videoData.url;
        if (!this.previewVideoUrl) return this.$message.error('请先上传视频');
        this.childrenDrawer = true;
      }
    },
    async handleSaveDraftClick() {
      if (this.saveLoading) return;
      if (this.$refs.drawerNoteComp.btnLoading) return this.$message.info('请稍后');

      this.saveLoading = true;
      if (this.$refs.drawerNoteComp.noteString) {
        handleNoteCheck(this.$refs.drawerNoteComp.noteString)
          .then(({ newNote, oldNote }) => {
            // 编辑器赋值
            const noteContent = newNote.replace(/\n/g, '<br />') || '';
            this.$refs.drawerNoteComp.noteContent = noteContent;
            this.$refs.drawerNoteComp.editor.txt.html(noteContent);
            this.$refs.drawerNoteComp.isNoteCheckRepeat = false;
            this.noteList.forEach((val) => {
              if (val.id == this.currentNoteData.id) val.isNoteCheckRepeat = false;
            });

            if (newNote !== oldNote) {
              this.$refs.drawerNoteComp.isNoteCheckError = true;
            } else {
              this.$refs.drawerNoteComp.isNoteCheckError = false;
            }
            this.handleSaveDraftConfirm();
          })
          .catch(() => (this.saveLoading = false));
      } else {
        this.handleSaveDraftConfirm();
      }
    },

    handleAddNote() {
      console.log('handleAddNote:');
      const id = Date.now().toString();
      const newNote = Object.assign({}, this.initNoteParams, { id });
      const isEmpty =
        !this.currentNoteData?.noteDetailHtml &&
        !this.currentNoteData?.noteTitle &&
        !this.currentNoteData?.xhsTopicList?.length;
      if (isEmpty) {
        this.noteList[this.currentDataIndex - 1] = newNote;
      } else {
        this.noteList.push(newNote);
        this.currentDataIndex = this.noteList.length;
      }
      this.currentNoteData = newNote;
      this.$nextTick(() => {
        this.renderEditor(this.currentNoteData);
      });
    },
    async handleRedoPreData() {
      this.preCopywritingId = this.currentNoteData.id;
      if (this.preNodeDone) {
        // 重新生产图片
        api.handleRedoPrePic({ id: this.currentNoteData.id }).then(async () => {
          this.prePicData.status = 'IN_PRODUCTION';
          this.getPlanPicList();
        });
      } else {
        // 重新生产文案
        api.handleRedoPreNote({ preCopywritingId: this.currentNoteData.id }).then(() => {
          if (this.prePicData.show) {
            this.prePicData.status = 'IN_PRODUCTION';
          }
        });
        let value = this.noteList.find((note) => note.id == this.currentNoteData.id);
        value.preError = false;
        value.preErrorMsg = '';
        value.preLoading = true;
        this.currentNoteData = JSON.parse(JSON.stringify(value));
        // 执行定时
        this.preWaitIdList = [...new Set([...this.preWaitIdList, this.currentNoteData.id])];
        !this.timerInstance && this.openUpdateInterval();
      }
    },
    jumpToTopic() {
      const { href } = this.$router.resolve({
        name: 'xhsTopicList',
      });
      window.open(href, '_blank');
    },
    // --------------------------------------------------------------------------------左侧按钮相关
    handleBtnClick(val) {
      if (val.value == 1 || val.value == 4) {
        this.currentBtn = this.currentBtn == val.value ? undefined : val.value;
        return;
      }
      if (val.value == 2) {
        if (!this.tagId) {
          this.$message.info('请先在【笔记信息】选择当前选题');
          return;
        }
        this.currentBtn = this.currentBtn == val.value ? undefined : val.value;
        if (!this.currentBtn) return;
        this.$nextTick(() => {
          this.$refs.WritingAssistantComp & this.$refs.WritingAssistantComp.init();
        });
        return;
      }
      if (val.value === 3) {
        this.currentBtn = undefined;
        let currentCarSeries = undefined;
        if (this.firstClickAi && this.detailForm.carSeriesIdListData?.length && !this.limited) {
          currentCarSeries = this.detailForm.carSeriesIdListData;
        }
        this.$refs.AiWritingDrawerComp && this.$refs.AiWritingDrawerComp.openDrawer(currentCarSeries);
        if (this.firstClickAi) this.firstClickAi = false;
        return;
      }
      if (val.value === 5) {
        this.currentBtn = undefined;
        this.$refs.AISuggestNoteDrawerComp.openDrawer();
        return;
      }
    },
    handlePositionSearch(value) {
      if (value) {
        this.positionLoading = true;
        this.handleRequest(
          'getPublishLocation',
          (data) => {
            this.positionList = data;
          },
          {
            keyword: value,
          }
        ).finally(() => (this.positionLoading = false));
      }
    },
    handleGetPosition(value) {
      this.currentPosition = value ? this.positionList.find((val) => val.poiId === value) : {};
    },
    disabledDate(current) {
      const today = moment();
      const nextWeek = moment().add(14, 'days');
      return current && (current < today || current > nextWeek);
    },
    // 插入模板
    handleUseTemplate(noteDetail, noHighLight = false) {
      const id = Date.now().toString();
      // 首尾高亮
      let highlightRange = [];
      let checkRepeatNote = '';
      if (noteDetail.content.length <= 20) {
        highlightRange = [[0, noteDetail.content.length - 1]];
        checkRepeatNote = noteDetail.content;
      }
      if (noteDetail.content.length > 20) {
        highlightRange = [
          [0, 9],
          [noteDetail.content.length - 11, noteDetail.content.length - 1],
        ];
        checkRepeatNote = `${noteDetail.content.slice(0, 10)}${noteDetail.content.slice(
          noteDetail.content.length - 11,
          noteDetail.content.length - 1
        )}`;
      }
      const noteDetailHtml = noHighLight
        ? `<p>${noteDetail.content}</p>`
        : `<p>${highlightString(noteDetail.content, highlightRange, '#e6f7ff')}</p>`;
      const newNote = Object.assign({}, this.initNoteParams, {
        id,
        noteDetail: noteDetail.content,
        noteTitle: noteDetail.title || '',
        noteDetailHtml,
        isNoteCheckRepeat: !noHighLight,
        checkRepeatNote,
        xhsAiContentId: noteDetail.AiId ? noteDetail.AiId : '',
      });
      const isEmpty =
        !this.currentNoteData.noteDetailHtml &&
        !this.currentNoteData.noteTitle &&
        !this.currentNoteData.xhsTopicList.length;
      if (isEmpty) {
        this.noteList[this.currentDataIndex - 1] = newNote;
      } else {
        this.noteList.push(newNote);
        this.currentDataIndex = this.noteList.length;
      }
      this.currentNoteData = newNote;
      this.$nextTick(() => {
        this.renderEditor(this.currentNoteData);
      });
      this.currentBtn = undefined;
    },
    // 爆款笔记AI改写
    handleTemplateRewrite(noteId) {
      api
        .getOriginXhsNote({ noteParam: noteId })
        .then(({ code, data, message }) => {
          if (code == 200) {
            if (!data[0].id) return this.$message.error('查询不到改笔记');
            const id = Date.now().toString();
            const newNote = Object.assign({}, this.initNoteParams, {
              isDone: true,
              id,
              noteTitle: data[0].noteTitle, // 文案标题
              noteDetail: data[0].noteDetail, // 文案内容
              noteDetailHtml: data[0].noteDetail.replace(/\n/g, '<br />'), // 文案HTML
            });
            const isEmpty =
              !this.currentNoteData.noteDetailHtml &&
              !this.currentNoteData.noteTitle &&
              !this.currentNoteData.xhsTopicList.length;
            if (isEmpty) {
              this.noteList[this.currentDataIndex - 1] = newNote;
            } else {
              this.noteList.push(newNote);
              this.currentDataIndex = this.noteList.length;
            }
            this.currentNoteData = newNote;
            this.renderEditor(this.currentNoteData);
            this.currentBtn = 4;
          } else {
            this.$message.error(message);
          }
        })
        .catch(() => (this.showNoteFail = true))
        .finally(() => (this.searchNoteLoading = false));
      console.log(noteId, 'noteId');
    },
    // --------------------------------------------------------------------------------文案相关
    handlePreNote() {
      const currentIndex = this.noteList.findIndex((val) => val.id == this.currentNoteData.id);
      if (currentIndex !== 0) {
        this.currentDataIndex = this.currentDataIndex - 1;
        this.currentNoteData = this.noteList[currentIndex - 1];
        if (this.currentNoteData.isDone) this.renderEditor(this.currentNoteData);
      }
    },
    handleNextNote() {
      const currentIndex = this.noteList.findIndex((val) => val.id == this.currentNoteData.id);
      if (currentIndex + 1 < this.noteList.length) {
        this.currentDataIndex = this.currentDataIndex + 1;
        this.currentNoteData = this.noteList[currentIndex + 1];
        if (this.currentNoteData.isDone) this.renderEditor(this.currentNoteData);
      }
    },
    renderEditor(data) {
      if (this.$refs.drawerNoteComp) {
        this.$refs.drawerNoteComp.editor && this.$refs.drawerNoteComp.editor.destroy();
        this.$refs.drawerNoteComp.editorId = data.id;
        this.$refs.drawerNoteComp.initEditor();
        this.$refs.drawerNoteComp.noteTitle = data.noteTitle;
        this.$refs.drawerNoteComp.noteString = data.noteDetail;
        const noteContent = data.noteDetailHtml
          ? data.noteDetailHtml.replace(/\n/g, '<br />')
          : data.noteDetail.replace(/\n/g, '<br />');
        this.$refs.drawerNoteComp.noteContent = noteContent;
        this.$refs.drawerNoteComp.editor.txt.html(noteContent);
        this.$refs.drawerNoteComp.tagList = data.xhsTopicList || [];
        this.$refs.drawerNoteComp.isNoteCheckError = data.isNoteCheckError;
        this.$refs.drawerNoteComp.isNoteCheckRepeat = data.isNoteCheckRepeat;
      }
    },
    handleEditorChange(string, html) {
      this.disTxt2Img = !string?.trim() ? true : false;
      let value = this.noteList.find((val) => val.id === this.currentNoteData.id);
      this.$nextTick(() => {
        if (value) {
          value.noteDetail = string;
          value.noteDetailHtml = html;
        }
      });
      this.handleSaveTempData();
    },
    handleCloseRepeat() {
      this.noteList.forEach((val) => {
        if (val.id == this.currentNoteData.id) val.isNoteCheckRepeat = false;
      });
      const noteContent = this.$refs.drawerNoteComp.noteContent.replace(/background-color: #e6f7ff/g, ' ') || '';
      this.$refs.drawerNoteComp.noteContent = noteContent;
      this.$refs.drawerNoteComp.editor.txt.html(noteContent);
    },
    handleCloseError() {
      this.noteList.forEach((val) => {
        if (val.id == this.currentNoteData.id) val.isNoteCheckError = false;
      });
    },
    handleTitleChange(title) {
      let value = this.noteList.find((val) => val.id === this.currentNoteData.id);
      this.$nextTick(() => {
        if (value) {
          value.noteTitle = title;
        }
      });
      this.handleSaveTempData();
    },
    handleTagListChange(xhsTopicList) {
      let value = this.noteList.find((val) => val.id === this.currentNoteData.id);
      this.$nextTick(() => {
        if (value) {
          value.xhsTopicList = xhsTopicList;
        }
      });
      this.handleSaveTempData();
    },
    handleAiRewrite(aiRewriteToolId, content) {
      const params = {
        content,
        aiRewriteToolId,
      };
      const id = Date.now().toString();
      const newNote = Object.assign({}, this.initNoteParams, {
        isDone: false,
        id,
        isAiStory: true,
        aiLoading: true,
      });
      this.noteList.push(newNote);
      this.currentNoteData = newNote;
      this.currentDataIndex = this.noteList.length;
      this.currentBtn = undefined;
      this.handleMakeAiStory(params, id);
    },
    async handleMakeAiStory(params, id) {
      let value = this.noteList.find((val) => val.id === id);
      const { data, code, message } = await api['getAiRewriteStory'](params);
      if (code === 200) {
        handleNoteCheck(data.rewriteContent)
          .then(({ newNote, oldNote }) => {
            value.noteDetail = newNote;
            value.noteDetailHtml = newNote.replace(/\n/g, '<br />');
            if (newNote !== oldNote) {
              value.isNoteCheckError = true;
            } else {
              value.isNoteCheckError = false;
            }
          })
          .catch(() => {
            value.noteDetail = rewriteContent;
            value.noteDetailHtml = rewriteContent.replace(/\n/g, '<br />');
            value.isNoteCheckError = false;
          })
          .finally(() => {
            this.autoAddTitleAndTagList(value, (callbackData) => {
              callbackData.isDone = true;
              callbackData.isAiStory = false;
              this.$message.success('AI改写完成');
              if (this.currentNoteData.id == id) {
                this.currentNoteData = JSON.parse(JSON.stringify(callbackData));
                this.renderEditor(this.currentNoteData);
              }
            });
          });
      } else {
        value.isDone = false;
        value.aiLoading = false;
        value.aiError = true;
        value.aiErrorMsg = message;
        this.$message.error(message);
      }
    },
    async autoAddTitleAndTagList(value, callbackFn) {
      // 自动添加AI标题以及热门话题
      const titleRes = await api.getContentTitle({
        note: value.noteDetail,
      });
      if (titleRes.code === 200) {
        value.noteTitle = titleRes.data[0];
      }
      if (value.noteTitle) {
        const tagRes = await api.getHashTagContent({
          noteDetail: value.noteDetail,
          noteTitle: value.noteTitle,
        });
        if (tagRes.code === 200) {
          value.xhsTopicList = tagRes.data.filter((val) => val.isHot);
        }
      }
      callbackFn(value);
    },
    async handleMakePre() {
      this.handleTrack('generate_text_again');
      const id = Date.now().toString();
      const newNote = Object.assign({}, this.initNoteParams, {
        isDone: false,
        id,
        isPreData: true,
        preLoading: true,
      });
      const isEmpty =
        !this.currentNoteData.noteDetailHtml &&
        !this.currentNoteData.noteTitle &&
        !this.currentNoteData.xhsTopicList.length;
      if (isEmpty) {
        this.noteList[this.currentDataIndex - 1] = newNote;
      } else {
        this.noteList.push(newNote);
        this.currentDataIndex = this.noteList.length;
      }
      this.currentNoteData = newNote;
      let value = this.noteList.find((val) => val.id === id);
      const { code, data, message } = await api.handleGetAiPre({
        authorId: this.detailForm.publishAccount,
        selectionTagId: this.tagId,
      });
      if (code === 200) {
        value.noteDetail = data[0]?.aigcAiRewriteToolRunVO?.rewrite_content || '';
        value.noteDetailHtml = value.noteDetail ? value.noteDetail.replace(/\n/g, '<br />') : '';
        value.xhsPreCopywritingId = data[0].id;
        await this.autoAddTitleAndTagList(value, (callbackData) => {
          this.$message.success('文案生产完成');
          value.isDone = true;
          value = callbackData;
        });
      } else {
        if (code === 1000) value.showJumpBtn = true;
        value.hideAgain = true;
        value.isDone = false;
        value.preError = true;
        value.preErrorMsg = message;
      }
      value.preLoading = false;
      if (this.currentNoteData.id == id) {
        this.currentNoteData = JSON.parse(JSON.stringify(value));
        value.isDone && this.renderEditor(this.currentNoteData);
      }
    },
    // --------------------------------------------------------------------------------图片相关
    updatePicList(picList) {
      this.picList = [];
      this.picList = picList;
      this.$nextTick(() => {
        picList.length && this.$refs.drawerPicComp && this.$refs.drawerPicComp.defineSortable();
      });
      this.handleSaveTempData();
    },
    handleTextToImg() {
      this.$refs.drawerNoteComp && this.$refs.drawerNoteComp.handleTextToImg();
      this.handleTrack('text_to_image');
    },
    handlePageOpen(page) {
      if (page == 'pintu') {
        const imageUrlList = this.picList.map((pic) => {
          return pic.fileUrl.replace(process.env.VUE_APP_PIC_OSS_DOMAIN, process.env.VUE_APP_PIC_OSS_CDN_DOMAIN);
        });
        const topDomain = getTopDomain();
        Cookies.set('templateImageList', JSON.stringify(imageUrlList), { domain: topDomain });
        window.open(`${process.env.VUE_APP_TEMPLATE_EDITOR}/home?tab=JigsawList&isTransferImage=true`, '_blank');
      }
      if (page == 'ai') {
        if (!this.tagId) return this.$message.info('请先在【笔记信息】选择当前选题');
        const { href } = this.$router.resolve({
          name: 'AIDrawing',
        });
        window.open(href, '_blank');
      }
    },
    handleTabChange(type) {
      if (this.noteType === type) return;
      this.noteType = type;
      this.handleSaveTempData();
    },
    async handlePicRefresh() {
      const list = await this.getPlanPicList();
      if (list.length) {
        this.picList.forEach((pic) => {
          list.forEach((lPic) => {
            if (pic.templateId === lPic.templateId) {
              pic.fileUrl = lPic.fileUrl;
              pic.fileId = lPic.fileId;
              pic.picId = lPic.picId;
            }
          });
        });
        this.handleSaveTempData();
      }
    },
    async handleRedoText() {
      if (!this.$refs.drawerNoteComp.noteString.trim()) return this.$message.info('请输入文案');
      const params = {
        content: this.$refs.drawerNoteComp.noteString,
        aiRewriteType: 'TEXT_TO_IMAGE',
      };
      const id = Date.now().toString();
      const newNote = Object.assign({}, this.initNoteParams, {
        isDone: false,
        id,
        isAiStory: true,
        aiLoading: true,
      });
      this.noteList.push(newNote);
      this.currentNoteData = newNote;
      this.currentDataIndex = this.noteList.length;
      this.currentBtn = undefined;
      this.handleMakeAiStory(params, id);
      this.handleTrack('retry_text_to_image');
    },
    // -------------------------------------------------------------------------------- 回显相关
    /**
     * 草稿回显
     */
    initDraftEdit() {
      return new Promise((resolve) => {
        const hide = this.$message.loading('查询中...', 0);
        this.handleRequest(
          'getCopywrittingDetail',
          async (datas) => {
            const data = JSON.parse(JSON.stringify(datas));
            this.handleUseData(data);

            const timeStr = Date.now().toString();
            this.noteList = [
              {
                id: timeStr,
                isDone: true,
                noteTitle: data.noteTitle,
                noteDetail: data.noteDetail,
                noteDetailHtml: data.noteDetailHtml,
                xhsTopicList: data.xhsTopicList || [],
                isPreData: data.xhsPreCopywritingId ? true : false,
                xhsPreCopywritingId: data.xhsPreCopywritingId || null,
              },
            ];
            this.currentNoteData = this.noteList[0];
            this.renderEditor(this.currentNoteData);
            // await this.getPlanDetail();
            if (data.xhsNotePublishPlanDetailVO?.id) {
              this.notePublishPlanId = data.xhsNotePublishPlanDetailVO.id;
              this.isFromExecution = true;
            }
            // 来自运营执行或者时间是否小于当前周
            if (
              data.xhsNotePublishPlanDetailVO &&
              (this.isFromExecution ||
                moment(data.xhsNotePublishPlanDetailVO?.planPublishDate)
                  .startOf('days')
                  .isBefore(moment().startOf('weeks')))
            ) {
              this.checkTimeRange = [
                data.xhsNotePublishPlanDetailVO?.planPublishDate,
                data.xhsNotePublishPlanDetailVO?.planPublishDate,
              ];
              this.isBeforePlan = true;
            }
            await this.searchMediaPlanList(this.notePublishPlanId);
            this.detailForm.topicSelectionTagIdList = data.topicSelectionTagList.map((item) => item.id).join('');
            this.tagId = this.detailForm.topicSelectionTagIdList ? this.detailForm.topicSelectionTagIdList : undefined;
            setTimeout(() => {
              this.startLog = true; // 开始记录缓存
            }, 3000);
            resolve();
          },
          this.$route.query.draftId
        ).finally(() => hide());
      });
    },
    // 运营计划回显
    async initPlanEdit() {
      // this.currentBtn = 1;
      await this.getPlanDetail();
      await this.getPlanPreNote();
    },
    async getPlanDetail() {
      const hide = this.$message.loading('查询中...', 0);
      return this.handleRequest(
        'getDetailByPlanId',
        async (data) => {
          if (!data) return this.$message.error('查询账号出错!');
          // this.detailForm.publishAccount = data.authorId;
          data.authorId && (await this.mediaConfirm(data.authorId));
          const planTime = data.planPublishDate ? moment(`${data.planPublishDate}T18:00:00`) : null;
          const now = moment();
          if (planTime && now.isBefore(planTime)) {
            // 定时发布
            this.detailForm.publishType = 2;
            this.detailForm.postTime = planTime;
          } else {
            // 立即发布
            this.detailForm.publishType = 1;
            this.detailForm.postTime = null;
          }
          this.detailForm.topicSelectionTagIdList = data.topicSelectionTagId || undefined;
          this.tagId = data.topicSelectionTagId || undefined;
          // console.log('getDetailByPlanId', data);
          setTimeout(() => {
            if (this.$refs.TopicTreeInput) {
              const res = this.$refs.TopicTreeInput.flattenTree(this.$refs.TopicTreeInput.tagList);
              this.currentTagName = res.find((tag) => tag.id === data.topicSelectionTagId)?.tagName || undefined;
            }
          }, 3000);
        },
        { planId: this.notePublishPlanId }
      ).finally(() => hide());
    },
    async getPlanPreNote() {
      const { code, data, message } = await api.getPreNoteByPlanId({ planId: this.notePublishPlanId });
      console.log('data ->', data);
      if (code !== 200) {
        this.$message.error(message);
        this.handleAddNote();
      } else {
        if (!data.length) {
          this.handleAddNote();
          return false;
        }
        this.preCopywritingId = data[0].id;
        if (this.preCopywritingId) {
          this.prePicData.status = data[0].status;
          if (data[0].status == 'IN_PRODUCTION') {
            this.prePicData.show = true;
            this.preNodeDone = false;
          } else if (data[0].status == 'FAIL') {
            this.prePicData.show = true;
            this.preNodeDone = false;
            this.prePicData.errMessage = data[0].errorMsg || '暂无报错信息';
          } else if (data[0].status == 'PRODUCED') {
            this.preNodeDone = true;
            await this.getPlanPicList();
            if (this.picList.length && this.detailForm.topicSelectionTagIdList === '1726922215343874050') {
              // 通用干货预生产物料展示不满意提示
              this.showTxt2ImgTip = true;
            }
          }
        }
        data.forEach((val) => {
          // status: IN_PRODUCTION-生产中， PRODUCED-已生产， FAIL-失败
          const newParams = {
            id: val.id,
            isPreData: true,
            isDone: val.status == 'PRODUCED' ? true : false,
            preLoading: val.status == 'IN_PRODUCTION' ? true : false,
            preError: val.status == 'FAIL' ? true : false,
            preErrorMsg: val.status == 'FAIL' && val.errorMsg ? val.errorMsg : '暂无报错信息',
            noteDetail: val.status == 'FAIL' ? undefined : val.content,
            noteDetailHtml: val.status == 'FAIL' ? undefined : val.content.replace(/\n/g, '<br />'),
            xhsPreCopywritingId: val.id,
            xhsTopicList: val.xhsTopicList || [],
            noteTitle: val.title || '',
          };
          const newNote = Object.assign({}, this.initNoteParams, newParams);
          this.noteList.push(newNote);

          // 若有进行中任务 打开定时器更新
          if (val.status == 'IN_PRODUCTION') this.preWaitIdList.push(val.id);
        });
        this.currentNoteData = this.noteList[0];
        if (this.currentNoteData.isDone) {
          this.renderEditor(this.currentNoteData);
        }
        this.preWaitIdList.length && this.openUpdateInterval();
        // console.log(this.currentNoteData, 'getPreNoteByPlanId', data);
      }
    },
    async getPlanPicList(inLoading = false) {
      if (!this.preCopywritingId) return [];
      let hide = () => {};
      if (!inLoading) {
        hide = this.$message.loading('请稍候...', 0);
      }
      const { code, data, message } = await api
        .getPrePicByPlanId({
          planId: this.notePublishPlanId,
          preCopywritingId: this.preCopywritingId,
        })
        .finally(() => hide());
      let returnList = [];
      if (code === 200) {
        const hasLoading = data?.find((p) => p.status === 'IN_PRODUCTION'); // 有图片处于生产中状态
        const hasFail = data?.find((p) => p.status === 'FAIL'); // 有图片生产错误
        if (hasLoading) {
          this.prePicData.show = true;
          this.prePicData.status = 'IN_PRODUCTION';
          setTimeout(() => {
            this.getPlanPicList(true);
          }, 5000);
          return [];
        } else if (hasFail) {
          this.prePicData.show = true;
          this.prePicData.status = 'FAIL';
          this.prePicData.errMessage = '图片生产错误';
          this.preNodeDone = true;
          return [];
        }

        returnList =
          data?.map((val) => {
            return {
              id: Math.random(),
              showHover: false,
              fileUrl: val.fileUrl,
              fileId: val.fileId,
              picId: val.id,
              templateId: val.userPosterId,
            };
          }) || [];
        returnList.length && this.updatePicList(returnList); // 获取预生产图片
        this.prePicData.show = false;
      } else {
        this.$message.error(message);
        return [];
      }
      return returnList;
    },
    openUpdateInterval() {
      if (this.timerInstance) return;
      this.timerInstance = setInterval(async () => {
        if (!this.preWaitIdList.length || !this.notePublishPlanId) {
          clearInterval(this.timerInstance);
          this.timerInstance = null;
          return;
        }
        const { code, data } = await api.getPreNoteByPlanId({ planId: this.notePublishPlanId });
        if (code == 200 && data.length) {
          data.forEach((noteItem) => {
            this.preWaitIdList.forEach((id, index) => {
              if (noteItem.id == id && noteItem.status != 'IN_PRODUCTION') {
                // 等待列表去除id
                this.preWaitIdList.splice(index, 1);

                const newParams = {
                  isDone: noteItem.status == 'PRODUCED' ? true : false,
                  preLoading: noteItem.status == 'IN_PRODUCTION' ? true : false,
                  preError: noteItem.status == 'FAIL' ? true : false,
                  preErrorMsg: noteItem.status == 'FAIL' && noteItem.errorMsg ? noteItem.errorMsg : '暂无报错信息',
                  noteDetail: noteItem.content,
                  noteDetailHtml: noteItem.content.replace(/\n/g, '<br />'),
                };
                let currentItem = this.noteList.find((note) => note.id == noteItem.id);
                currentItem = Object.assign(currentItem, newParams); // 更新指定数据

                // 当前更新数据 则渲染
                const fIndex = this.noteList.findIndex((note) => note.id == noteItem.id);
                const cIndex = this.noteList.findIndex((note) => note.id == this.currentNoteData.id);
                if (fIndex == cIndex) {
                  this.currentNoteData = JSON.parse(JSON.stringify(currentItem));
                  this.$nextTick(() => {
                    this.renderEditor(this.currentNoteData);
                  });
                }
              }
            });
          });
          const hasFail = data.filter((v) => v.status == 'FAIL');
          if (hasFail.length > 0 && this.prePicData.show) {
            this.preNodeDone = false;
            this.prePicData.status = 'FAIL';
            this.prePicData.errMessage = data[0].errorMsg || '暂无报错信息';
          }
          const hasLoading = data.filter((v) => v.status == 'IN_PRODUCTION');
          if (hasLoading.length > 0 && this.prePicData.show) {
            this.preNodeDone = false;
            this.prePicData.status = 'IN_PRODUCTION';
          }
          if (!hasFail.length && !hasLoading.length && this.prePicData.show) {
            this.prePicData.show = false;
            this.preNodeDone = true;
            this.getPlanPicList();
          }
        } else {
          this.prePicData.show = false;
          clearInterval(this.timerInstance);
          this.timerInstance = null;
        }
      }, 10000);
    },
    /**
     * 推送笔记回显
     */
    initPushEdit() {
      return new Promise((resolve) => {
        this.currentBtn = 4;
        const hide = this.$message.loading('查询中...', 0);
        this.handleRequest(
          'getNoteDetailByPushId',
          async (datas) => {
            const data = JSON.parse(JSON.stringify(datas));

            this.pushType = data.publishChannel;

            this.noteType = data.noteType;
            this.showVideoType = data.noteType === 'VIDEO' ? true : false;
            this.showImgType = data.noteType === 'IMAGE' ? true : false;
            this.handleUseData(data);
            this.notePublishPlanId = data.notePublishPlanId;
            const timeStr = Date.now().toString();
            this.noteList.push({
              id: timeStr,
              isDone: true,
              noteTitle: data.noteTitle,
              noteDetail: data.noteDetail,
              noteDetailHtml: data.noteDetailHtml,
              xhsTopicList: data.xhsTopicList || [],
            });
            this.currentNoteData = this.noteList[0];
            this.renderEditor(this.currentNoteData);
            resolve();
            setTimeout(() => {
              if (this.$refs.TopicTreeInput) {
                const res = this.$refs.TopicTreeInput.flattenTree(this.$refs.TopicTreeInput.tagList);
                this.currentTagName = data.topicSelectionTagIdList?.length
                  ? res.find((tag) => tag.id === data.topicSelectionTagIdList[0])?.tagName
                  : undefined;
              }
            }, 3000);
            if (data.xhsNotePublishPlanDetailVO?.id) this.notePublishPlanId = data.xhsNotePublishPlanDetailVO.id;
            // 来自运营执行或者时间是否小于当前周
            if (
              data.xhsNotePublishPlanDetailVO &&
              (this.isFromExecution ||
                moment(data.xhsNotePublishPlanDetailVO?.planPublishDate)
                  .startOf('days')
                  .isBefore(moment().startOf('weeks')))
            ) {
              this.checkTimeRange = [
                data.xhsNotePublishPlanDetailVO?.planPublishDate,
                data.xhsNotePublishPlanDetailVO?.planPublishDate,
              ];
              this.isBeforePlan = true;
            }
            await this.searchMediaPlanList(this.notePublishPlanId);
            this.detailForm.topicSelectionTagIdList = data.topicSelectionTagIdList.join('');
            this.tagId = this.detailForm.topicSelectionTagIdList ? this.detailForm.topicSelectionTagIdList : undefined;
            setTimeout(() => {
              this.startLog = true; // 开始记录缓存
            }, 5000);
          },
          { id: this.$route.query.pushId }
        ).finally(() => {
          hide();
        });
      });
    },
    // 图片回显
    handleGetLocalImg() {
      this.handleRequest(
        'getTempPicList',
        ({ data: { imageUrlList } }) => {
          this.picList =
            imageUrlList?.map((val) => {
              return {
                id: Math.random(),
                showHover: false,
                fileUrl: val.fileUrl,
                fileId: val.fileId,
              };
            }) || [];
          if (this.picList.length) {
            this.picList = this.picList.slice(0, 17); // 最多18张
            this.$nextTick(() => {
              this.$refs.drawerPicComp && this.$refs.drawerPicComp.defineSortable();
            });
          }
          console.log(imageUrlList);
        },
        this.$route.query.tempId
      );
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
    checkLimit() {
      return new Promise((resolve) => {
        let limited = false;
        const limitedRoleList = ['猛士运营'];
        let limitedName = '';
        this.$router.app.user.roles.forEach((v) => {
          if (limitedRoleList.indexOf(v.name) > -1) {
            limited = true;
            limitedName = v.name;
            return;
          }
        });
        resolve({ limited, limitedName });
      });
    },
    checkTempData(list_p, saveId) {
      return new Promise((resolve) => {
        const index = list_p.findIndex((v) => v.saveId === saveId);
        if (index != -1) {
          const infoMessage = '检测到该笔记有未保存的编辑内容，是否继续编辑？';
          const r = confirm(infoMessage);
          if (r) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    },
    useLocalData(data) {
      if (data && Object.keys(data).length) {
        this.handleUseData(data);
        // 图片
        this.picList =
          data.picList?.map((val) => {
            return {
              id: Math.random(),
              showHover: false,
              fileUrl: val.fileUrl,
              fileId: val.fileId,
            };
          }) || [];
        if (this.picList.length) {
          this.$nextTick(() => {
            this.$refs.drawerPicComp && this.$refs.drawerPicComp.defineSortable();
          });
        }
        const timeStr = Date.now().toString();

        this.noteList = [
          {
            id: timeStr,
            isDone: true,
            noteTitle: data.noteTitle,
            noteDetail: data.noteDetail,
            noteDetailHtml: data.noteDetailHtml,
            xhsTopicList: data.xhsTopicList || [],
            isPreData: false,
            xhsPreCopywritingId: null,
          },
        ];
        this.currentNoteData = this.noteList[0];
        this.renderEditor(this.currentNoteData);
      }
    },
    handleSaveTempData: debounce(
      async function () {
        const saveId = this.$route.query.draftId
          ? this.$route.query.draftId
          : this.$route.query.pushId
          ? this.$route.query.pushId
          : undefined;
        if (!saveId || !this.startLog) return;
        const avatar = this.mediaDetail.authorDetailInfoDTO?.avatar || '';
        const nickname = this.mediaDetail.authorDetailInfoDTO?.nickname || '';
        const authorId = this.detailForm.publishAccount || '';
        const postLoc = this.detailForm.position
          ? {
              name: this.currentPosition.name,
              poiId: this.currentPosition.poiId,
              poiType: this.currentPosition.poiType,
              subname: this.currentPosition.subname,
            }
          : {};
        const isArrayP = Array.isArray(this.detailForm.principalIdListData);
        const principalIdListData = isArrayP
          ? this.detailForm.principalIdListData[0]
          : this.detailForm.principalIdListData;
        const isArrayS = Array.isArray(this.detailForm.carSeriesIdListData);
        const carSeriesIdListData = isArrayS
          ? this.detailForm.carSeriesIdListData[0]
          : this.detailForm.carSeriesIdListData;
        const noteDetail = this.$refs.drawerNoteComp?.noteString;
        const noteDetailHtml = this.$refs.drawerNoteComp?.noteContent;
        const noteTitle = this.$refs.drawerNoteComp?.noteTitle.trim();
        const xhsTopicList = this.$refs.drawerNoteComp?.tagList;
        const allTopicList = this.$refs.TopicTreeInput.flattenTree(this.$refs.TopicTreeInput.tagList);
        const selectTag = allTopicList.find((val) => val.id == this.detailForm.topicSelectionTagIdList) || {};
        const topicSelectionTagList = selectTag?.id
          ? [
              {
                id: selectTag.id,
                tagName: selectTag.tagName,
              },
            ]
          : [];

        const saveData = {
          saveId,
          noteType: this.noteType || undefined,
          picList: this.picList || undefined,
          videoUrl: this.$refs.drawerVideoComp?.videoData?.url || undefined,
          videoCover: this.$refs.drawerVideoComp?.videoData?.cover || undefined,
          noteDetail,
          noteDetailHtml,
          noteTitle,
          xhsTopicList,
          avatar,
          nickname,
          authorId,
          postLoc,
          postTime:
            this.detailForm.publishType === 1
              ? ''
              : `${moment(this.detailForm.postTime).format('YYYY-MM-DD HH:mm')}:00`,
          privacy: this.detailForm.privacy,
          topicSelectionTagList,
          principalId: principalIdListData ? principalIdListData.split(',')[0] : '',
          principalName: principalIdListData ? principalIdListData.split(',')[1] : '',
          carSeriesId: carSeriesIdListData ? carSeriesIdListData.split(',')[0] : '',
          carSeriesName: carSeriesIdListData ? carSeriesIdListData.split(',')[1] : '',
        };
        const hasLocal = window.localStorage.getItem('tempNote_list');
        const localData = hasLocal ? JSON.parse(hasLocal) : [];
        const findIndex = localData.findIndex((val) => val.saveId === saveId);
        if (findIndex != -1) {
          localData.forEach((v) => {
            if (v.saveId === saveId) {
              Object.assign(v, saveData);
            }
          });
        } else {
          localData.push(saveData);
        }
        window.localStorage.setItem('tempNote_list', JSON.stringify(localData));
      },
      2000,
      { leading: true }
    ),

    handleClearLocalStorageData() {
      const findIndex = this.getLocalDraftIndex();
      if (findIndex != -1) {
        const list_s = window.localStorage.getItem('tempNote_list');
        const list_p = list_s ? JSON.parse(list_s) : undefined;
        list_p.splice(findIndex, 1);
        window.localStorage.setItem('tempNote_list', JSON.stringify(list_p));
      }
    },

    getLocalDraftIndex() {
      const saveId = this.$route.query.draftId
        ? this.$route.query.draftId
        : this.$route.query.pushId
        ? this.$route.query.pushId
        : undefined;
      const list_s = window.localStorage.getItem('tempNote_list');
      const list_p = list_s ? JSON.parse(list_s) : undefined;
      if (!saveId || !list_s || !list_p?.length) {
        return -1;
      }
      const findIndex = list_p.findIndex((val) => val.saveId === saveId);
      return findIndex;
    },

    /**
     * 添加草稿
     */
    async handleAddDraft() {
      if (this.gptLoading) return this.$message.info('请等待GPT生成');
      // if (!this.detailForm.topicSelectionTagIdList?.length) return this.$message.info('请选择选题');
      const isArrayP = Array.isArray(this.detailForm.principalIdListData);
      const principalIdListData = isArrayP
        ? this.detailForm.principalIdListData[0]
        : this.detailForm.principalIdListData;
      // if (!principalIdListData) return this.$message.info('请选择品牌');

      // const index = this.mediaList.findIndex((v) => v.authorId === this.detailForm.publishAccount);
      // if (this.detailForm.publishAccount && index === -1) return this.$message.info('请选择有效的发布账号');

      const avatar = this.mediaDetail.authorDetailInfoDTO?.avatar || '';
      const nickname = this.mediaDetail.authorDetailInfoDTO?.nickname || '';
      const authorId = this.detailForm.publishAccount || '';
      const authorCode = this.mediaDetail.authorDetailInfoDTO?.authorCode || '';
      const isArrayS = Array.isArray(this.detailForm.carSeriesIdListData);
      const carSeriesIdListData = isArrayS
        ? this.detailForm.carSeriesIdListData[0]
        : this.detailForm.carSeriesIdListData;

      const noteDetail = this.$refs.drawerNoteComp ? this.$refs.drawerNoteComp.noteString : undefined;
      const noteDetailHtml = this.$refs.drawerNoteComp ? this.$refs.drawerNoteComp.noteContent : undefined;
      const noteTitle = this.$refs.drawerNoteComp ? this.$refs.drawerNoteComp.noteTitle : undefined;
      const xhsTopicList = this.$refs.drawerNoteComp ? this.$refs.drawerNoteComp.tagList : [];
      const xhsPreCopywritingId = this.currentNoteData.isPreData ? this.currentNoteData.xhsPreCopywritingId : '';
      const postLoc = this.detailForm.position
        ? {
            name: this.currentPosition.name,
            poiId: this.currentPosition.poiId,
            poiType: this.currentPosition.poiType,
            subname: this.currentPosition.subname,
          }
        : {};
      const privacy = this.detailForm.privacy || '';
      const postTime =
        this.detailForm.publishType === 1 ? '' : `${moment(this.detailForm.postTime).format('YYYY-MM-DD HH:mm')}:00`;

      const materialArr = this.picList.map((val) => ({ fileId: val.fileId, previewUrl: val.fileUrl })) || [];
      let saveDraftParams = {
        name: '新建草稿',
        authorId,
        authorCode,
        avatar,
        nickname,
        noteType: this.noteType,
        topicSelectionTagIdList: this.detailForm.topicSelectionTagIdList?.length
          ? [this.detailForm.topicSelectionTagIdList]
          : [],
        principalId: principalIdListData ? principalIdListData.split(',')[0] : '',
        principalName: principalIdListData ? principalIdListData.split(',')[1] : '',
        carSeriesId: carSeriesIdListData ? carSeriesIdListData.split(',')[0] : '',
        carSeriesName: carSeriesIdListData ? carSeriesIdListData.split(',')[1] : '',
        topicSelectionTagList: [],
        materialArr,
        noteTitle,
        noteDetail,
        noteDetailHtml,
        xhsTopicList,
        videoUrl: '',
        postLoc,
        privacy,
        postTime,
        xhsPreCopywritingId,
      };

      if (this.notePublishPlanId) {
        saveDraftParams.planId = this.notePublishPlanId;
        saveDraftParams.name = '新建计划草稿';
      }

      if (this.currentNoteData.xhsAiContentId) {
        saveDraftParams.xhsAiContentId = this.currentNoteData.xhsAiContentId;
      }

      if (this.timerInstance) {
        clearInterval(this.timerInstance);
        this.timerInstance = null;
      }

      const saveParams = JSON.stringify({
        topicSelectionTagIdList: this.detailForm.topicSelectionTagIdList,
        principalIdListData,
        carSeriesIdListData,
        publishAccount: this.detailForm.publishAccount,
      });

      localStorage.setItem('addNewDraftParams', saveParams);

      this.addDraftLoading = true;
      await this.handleRequest(
        'handleSaveCopywriting',
        (draftId) => {
          this.$message.success('创建成功');
          const replaceLink = this.$router.resolve({
            name: 'articleDetail',
            query: this.isFromExecution
              ? { draftId, principalId: this.$route.query.principalId, principalName: this.$route.query.principalName }
              : { draftId },
          });
          this.$router.replace(replaceLink.href, () => {
            this.showAddDraftModal = false;
            this.handleClearLocalStorageData();
            this.startLog = true;
          });
          this.init();
        },
        saveDraftParams
      ).finally(() => (this.addDraftLoading = false));
    },

    async getLocalNewDraftParams() {
      const data_s = localStorage.getItem('addNewDraftParams');
      if (data_s) {
        const data_p = JSON.parse(data_s);
        this.detailForm.principalIdListData = data_p.principalIdListData || undefined;
        this.detailForm.carSeriesIdListData = data_p.carSeriesIdListData || undefined;
        // this.detailForm.topicSelectionTagIdList = data_p.topicSelectionTagIdList || undefined;
        // this.detailForm.publishAccount = data_p.publishAccount || undefined;
        // if (this.detailForm.publishAccount) {
        //   this.addDraftLoading = true;
        //   await this.handleMediaChange(this.detailForm.publishAccount);
        //   this.addDraftLoading = false;
        // }
      }
    },

    showHistoryDrawer() {
      const id = this.$route.query.draftId
        ? this.$route.query.draftId
        : this.$route.query.pushId
        ? this.$route.query.pushId
        : undefined;
      if (!id) return this.$message.error('当前笔记无历史记录');
      this.$refs.NoteHistoryListComp.openDrawer(id);
    },

    handleUseDraft(data) {
      this.handleUseData(data);
      const timeStr = Date.now().toString();
      const newNote = {
        id: timeStr,
        isDone: true,
        noteTitle: data.noteTitle,
        noteDetail: data.noteDetail,
        noteDetailHtml: data.noteDetailHtml,
        xhsTopicList: data.xhsTopicList || [],
        isPreData: data.xhsPreCopywritingId ? true : false,
        xhsPreCopywritingId: data.xhsPreCopywritingId || null,
      };
      this.noteList.push(newNote);
      this.currentNoteData = newNote;
      this.currentDataIndex = this.noteList.length;
      this.renderEditor(this.currentNoteData);
    },

    handleUseData(data) {
      if (!data || !Object.keys(data).length) return;
      // 名称
      this.detailForm.noteName = data.name || undefined;
      // 选题
      const tagList = data.topicSelectionTagList || data.topicSelectionTagIdList || [];
      this.detailForm.topicSelectionTagIdList = tagList.length ? tagList.map((item) => item.id || item)[0] : undefined;
      this.currentTagName = tagList.length ? tagList.map((item) => item.tagName || item)[0] : undefined;
      this.tagId = this.detailForm.topicSelectionTagIdList ? this.detailForm.topicSelectionTagIdList : undefined;
      // 品牌/车系
      this.detailForm.principalIdListData = data.principalId ? [`${data.principalId},${data.principalName}`] : [];
      this.detailForm.carSeriesIdListData = data.carSeriesId ? [`${data.carSeriesId},${data.carSeriesName}`] : [];
      this.noteType = data.noteType || 'IMAGE';
      if (data.noteType === 'IMAGE') {
        // 图片
        this.picList =
          data.materialArr?.map((val) => {
            return {
              id: Math.random(),
              showHover: false,
              fileUrl: val.previewUrl,
              fileId: val.fileId,
            };
          }) || [];
        if (this.picList.length) {
          this.$nextTick(() => {
            this.$refs.drawerPicComp && this.$refs.drawerPicComp.defineSortable();
          });
        }
      }
      if (data.noteType === 'VIDEO') {
        if (data.videoUrl) {
          this.$refs.drawerVideoComp.uploadStep = 2;
          this.$refs.drawerVideoComp.videoData = {
            uploading: false,
            isFail: false,
            cover:
              data.materialArr && data.materialArr[0]
                ? data.materialArr[0].previewUrl
                : data.videoCover
                ? data.videoCover
                : '',
            url: data.videoUrl,
            showHover: false,
          };
        }
      }
      // 发布信息
      // 发布账号
      this.detailForm.publishAccount = data.authorId;
      data.authorId && this.getMediaData(data.authorId);

      // 添加地点
      if (data.postLoc) {
        Object.keys(data.postLoc).length && this.getMediaLocation(data.postLoc);
      }

      // 权限
      this.detailForm.privacy = data.privacy || 'PUBLIC';

      // 方式&时间
      this.detailForm.publishType = data.postTime ? 2 : 1;
      if (data.postTime) {
        const planTime = data.postTime ? moment(data.postTime) : null;
        const afterHour = moment().add(1, 'hour').valueOf();
        // 时间校正至当前时间一个小时后
        if (planTime > afterHour) {
          // 定时发布
          this.detailForm.postTime = planTime;
        } else {
          this.$message.info('定时发布最早只能设置1小时之后');
          this.detailForm.postTime = moment().add(1, 'hour');
        }
      } else {
        // 立即发布
        this.detailForm.postTime = null;
      }
    },

    async getSuggestDraft(AutoOpen = false) {
      const isArrayP = Array.isArray(this.detailForm.principalIdListData);
      const principalIdListData = isArrayP
        ? this.detailForm.principalIdListData[0]
        : this.detailForm.principalIdListData;
      const isArrayS = Array.isArray(this.detailForm.carSeriesIdListData);
      const carSeriesIdListData = isArrayS
        ? this.detailForm.carSeriesIdListData[0]
        : this.detailForm.carSeriesIdListData;
      const topic =
        this.$refs.drawerNoteComp.noteTitle?.length > 5
          ? this.$refs.drawerNoteComp.noteTitle
          : this.detailForm.topic
          ? this.detailForm.topic
          : undefined;
      const params = {
        limit: 2,
        selectionTagId: this.detailForm.topicSelectionTagIdList?.length
          ? this.detailForm.topicSelectionTagIdList
          : undefined,
        principalId: principalIdListData ? principalIdListData.split(',')[0] : '',
        principalName: principalIdListData ? principalIdListData.split(',')[1] : '',
        carSeriesId: carSeriesIdListData ? carSeriesIdListData.split(',')[0] : '',
        carSeriesName: carSeriesIdListData ? carSeriesIdListData.split(',')[1] : '',
        topic,
        authorId: this.detailForm.publishAccount || undefined,
        generateMode: AutoOpen ? 'AUTO' : 'MANUAL',
      };
      if (!params.topic) return this.$message.info('请输入五个字以上的标题确认推荐主题');
      if (!principalIdListData) return this.$message.info('请选择品牌');
      if (!params.selectionTagId) return this.$message.info('请选择选题');
      await this.$refs.AISuggestNoteDrawerComp.handleGetList(params, AutoOpen);
    },
    updateLoading(val) {
      this.suggestLoading = val;
    },
    handleGetMore() {
      this.getSuggestDraft(false);
    },
    handleUpdateNew(val) {
      this.suggestNew = val;
    },
    handleAddTime() {
      // 获取1.5小时后的时间
      const afterTime = moment().add(1.5, 'hour').format('YYYY-MM-DD HH:mm');
      this.detailForm.postTime = afterTime;
    },
    handleCheckTimeChange(val) {
      if (val.length) {
        this.checkTimeRange = [moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')];
      } else {
        this.checkTimeRange = [null, null];
      }
      this.tagId = undefined;
      this.detailForm.topicSelectionTagIdList = undefined;
      this.searchMediaPlanList();
    },
    async searchMediaPlanList(notePublishPlanId) {
      this.searchMediaLoading = true;
      this.planList = [];
      this.notePublishPlanId = notePublishPlanId;
      if (!notePublishPlanId) {
        this.tagId = undefined;
        this.detailForm.topicSelectionTagIdList = undefined;
      }
      const params = {
        authorId: this.detailForm.publishAccount,
        startDate: this.checkTimeRange[0],
        endDate: this.checkTimeRange[1],
      };
      await this.handleRequest(
        'getPublishPlanList',
        (data) => {
          this.planList = data.map((item) => ({
            ...item,
            isRelationNote: item.isRelationNote && item.id !== notePublishPlanId,
          }));
        },
        params
      ).finally(() => (this.searchMediaLoading = false));
    },
    handlePlanClick(val) {
      if (this.isBeforePlan || (val.isRelationNote && val.id !== this.notePublishPlanId)) return;
      // this.checkTimeRange()
      if (this.notePublishPlanId !== val.id) {
        this.notePublishPlanId = val.id;
        this.detailForm.topicSelectionTagIdList = val.topicSelectionTagId;
        this.tagId = this.detailForm.topicSelectionTagIdList ? this.detailForm.topicSelectionTagIdList : undefined;
      } else {
        this.notePublishPlanId = undefined;
        this.detailForm.topicSelectionTagIdList = undefined;
      }
    },

    handleAddNext() {
      this.addDraftStep = 2;
      if (this.notePublishPlanId) this.initPlanEdit();
    },

    async handleSaveDraftConfirm() {
      let materialArr = [];
      if (this.noteType === 'IMAGE') {
        materialArr = this.picList.map((val) => ({ fileId: val.fileId, previewUrl: val.fileUrl }));
      }
      if (this.noteType === 'VIDEO') {
        materialArr = this.$refs.drawerVideoComp.videoData.cover
          ? [{ previewUrl: this.$refs.drawerVideoComp.videoData.cover, fileId: '' }]
          : [];
      }
      const videoUrl = this.noteType === 'VIDEO' ? this.$refs.drawerVideoComp.videoData.url : ''; // 视频
      const noteTitle = this.$refs.drawerNoteComp.noteTitle;
      const xhsTopicList = this.$refs.drawerNoteComp.tagList;
      const noteDetailHtml = this.$refs.drawerNoteComp.noteContent;
      const noteDetail = this.$refs.drawerNoteComp.noteString;

      const isArrayP = Array.isArray(this.detailForm.principalIdListData);
      const principalIdListData = isArrayP
        ? this.detailForm.principalIdListData[0]
        : this.detailForm.principalIdListData;
      const isArrayS = Array.isArray(this.detailForm.carSeriesIdListData);
      const carSeriesIdListData = isArrayS
        ? this.detailForm.carSeriesIdListData[0]
        : this.detailForm.carSeriesIdListData;

      const authorCode = this.mediaDetail.authorDetailInfoDTO.authorCode || '';
      const authorId = this.mediaDetail.authorDetailInfoDTO.authorId || '';
      const postLoc = this.detailForm.position
        ? {
            name: this.currentPosition.name,
            poiId: this.currentPosition.poiId,
            poiType: this.currentPosition.poiType,
            subname: this.currentPosition.subname,
          }
        : {};
      const privacy = this.detailForm.privacy || '';
      const postTime =
        this.detailForm.publishType === 1 ? '' : `${moment(this.detailForm.postTime).format('YYYY-MM-DD HH:mm')}:00`;

      const xhsPreCopywritingId = this.currentNoteData.isPreData ? this.currentNoteData.xhsPreCopywritingId : '';
      const nickName = this.mediaDetail.authorDetailInfoDTO?.nickname
        ? `${this.mediaDetail.authorDetailInfoDTO.nickname} - `
        : '';
      const name = this.detailForm.noteName
        ? this.detailForm.noteName
        : `${nickName}${
            this.detailForm.publishType === 1 ? '立即发布' : `定时${moment(this.detailForm.postTime).format('MM-DD')}`
          }`;
      let saveParams = {
        topicSelectionTagIdList: this.detailForm.topicSelectionTagIdList?.length
          ? [this.detailForm.topicSelectionTagIdList]
          : [],
        materialArr,
        principalId: principalIdListData ? principalIdListData.split(',')[0] : '',
        principalName: principalIdListData ? principalIdListData.split(',')[1] : '',
        carSeriesId: carSeriesIdListData ? carSeriesIdListData.split(',')[0] : '',
        carSeriesName: carSeriesIdListData ? carSeriesIdListData.split(',')[1] : '',
        noteTitle,
        noteDetail,
        noteDetailHtml,
        xhsTopicList,
        name,
        authorCode,
        authorId,
        postLoc,
        privacy,
        postTime,
        xhsPreCopywritingId, // 预生产文案Id
        videoUrl,
        noteType: this.noteType,
      };

      if (this.currentNoteData.xhsAiContentId) {
        saveParams.xhsAiContentId = this.currentNoteData.xhsAiContentId;
      }

      if (this.notePublishPlanId) saveParams.planId = this.notePublishPlanId;

      if (this.$route.query.draftId) {
        // 保存草稿
        saveParams.id = this.$route.query.draftId;
      }
      this.saveLoading = true;

      this.handleTrack('save_to_draftbox', {
        draft_id: this.$route.query.draftId || undefined,
        publish_time: postTime,
        media_platform: '小红书',
        media_code: authorCode,
        media_neckname: this.mediaDetail.authorDetailInfoDTO?.nickname || undefined,
        media_id: authorId,
        media_member: this.mediaDetail.matrixMemberName || undefined,
        media_brand: undefined,
        note_type: this.noteType === 'IMAGE' ? '图文笔记' : this.noteType === 'VIDEO' ? '视频笔记' : '其他',
        topic: this.currentTagName,
        note_brand: principalIdListData ? principalIdListData.split(',')[1] : undefined,
        note_series: carSeriesIdListData ? carSeriesIdListData.split(',')[1] : undefined,
      });

      await this.handleRequest(
        'handleSaveCopywriting',
        (draftId) => {
          if (!this.$route.query.draftId) {
            const replaceLink = this.$router.resolve({
              name: 'articleDetail',
              query: { draftId },
            });
            this.$router.replace(replaceLink.href, () => {});
            setTimeout(() => {
              this.startLog = true; // 开始记录缓存
            }, 3000);
          } else {
            this.handleClearLocalStorageData();
          }

          const link = this.$router.resolve({
            name: 'copywritingList',
          });
          const messageComp = this.$createElement(MessageTextComp, {
            props: { message: '已保存草稿，查看笔记可前往', pageName: '草稿箱', link: link.href },
          });
          this.$message.success({
            content: messageComp,
            key: 'darf',
            duration: 3,
          });
          this.initDraftEdit();
        },
        saveParams
      ).finally(() => (this.saveLoading = false));
    },

    handleNoteSaveClick() {
      if (this.saveLoading) return;
      const validate = this.handlePushValidate();
      if (validate) this.saveNotePopConfirm = true;
    },

    handleNoteSaveConfirm() {
      this.saveNotePopConfirm = false;
      this.handleNoteSave();
      this.handleTrack('sync_to_fhl', {
        note_id: this.$route.query.pushId,
      });
    },

    handlePushClick() {
      if (this.saveLoading) return;
      const validate = this.handlePushValidate();
      if (validate) this.pushPopConfirm = true;
    },

    async handleNotePushConfirm() {
      this.pushPopConfirm = false;
      const repeatValidate = await this.checkDraftRepeat();
      if (repeatValidate) {
        this.publishParams.aiInvolvementLevel = await this.handleGetAiCheck();
        if (!this.publishParams.aiInvolvementLevel) return;

        this.handleTrack('push_to_fhl', {
          publish_time: this.publishParams.postTime,
          media_platform: '小红书',
          media_code: this.publishParams.authorCode,
          media_neckname: this.publishParams.nickname,
          media_id: this.publishParams.authorId,
          media_member: this.publishParams.memberName,
          media_brand: undefined,
          note_type: this.noteType === 'IMAGE' ? '图文笔记' : this.noteType === 'VIDEO' ? '视频笔记' : '其他',
          topic: this.currentTagName,
          note_brand: this.publishParams.principalIdListData
            ? this.publishParams.principalIdListData.split(',')[1]
            : undefined,
          note_series: this.publishParams.carSeriesIdListData
            ? this.publishParams.carSeriesIdListData.split(',')[1]
            : undefined,
        });

        this.handleNoteSave();
      } else {
        return false;
      }
    },

    handlePushValidate() {
      try {
        if (this.$refs.drawerNoteComp.btnLoading) {
          this.$message.info('请稍后');
          return false;
        }
        // 参数赋值
        const authorCode = this.mediaDetail.authorDetailInfoDTO?.authorCode || '';
        const authorId = this.mediaDetail.authorDetailInfoDTO?.authorId || '';
        const avatar = this.mediaDetail.authorDetailInfoDTO?.avatar || '';
        const nickname = this.mediaDetail.authorDetailInfoDTO?.nickname || '';
        const dealerId = this.mediaDetail.dealerId || '';
        const dealerName = this.mediaDetail.dealerName || '';
        const memberId = this.mediaDetail.matrixMemberId || '';
        const memberCode = this.mediaDetail.matrixMemberCode || '';
        const memberName = this.mediaDetail.matrixMemberName || '';
        const noteDetail = this.$refs.drawerNoteComp.noteString;
        const noteDetailHtml = this.$refs.drawerNoteComp.noteContent;
        const noteTitle = this.$refs.drawerNoteComp.noteTitle.trim();
        const xhsTopicList = this.$refs.drawerNoteComp.tagList;
        const notePublishPlanId = this.notePublishPlanId || '';
        const postTime =
          this.detailForm.publishType === 1 ? '' : `${moment(this.detailForm.postTime).format('YYYY-MM-DD HH:mm')}:00`;
        const privacy = this.detailForm.privacy || '';
        const xhsPreCopywritingId = this.currentNoteData.isPreData ? this.currentNoteData.xhsPreCopywritingId : '';
        const videoUrl = this.noteType === 'VIDEO' ? this.$refs.drawerVideoComp.videoData.url : ''; // 视频
        let materialArr = [];
        if (this.noteType === 'VIDEO') {
          materialArr = this.$refs.drawerVideoComp?.videoData?.cover
            ? [{ previewUrl: this.$refs.drawerVideoComp.videoData.cover, fileId: '' }]
            : [];
        } else {
          materialArr = this.picList.map((val) => ({ fileId: val.fileId, previewUrl: val.fileUrl })) || [];
        }
        const postLoc = this.detailForm.position
          ? {
              name: this.currentPosition.name,
              poiId: this.currentPosition.poiId,
              poiType: this.currentPosition.poiType,
              subname: this.currentPosition.subname,
            }
          : {};
        const isArrayP = Array.isArray(this.detailForm.principalIdListData);
        const principalIdListData = isArrayP
          ? this.detailForm.principalIdListData[0]
          : this.detailForm.principalIdListData;
        const isArrayS = Array.isArray(this.detailForm.carSeriesIdListData);
        const carSeriesIdListData = isArrayS
          ? this.detailForm.carSeriesIdListData[0]
          : this.detailForm.carSeriesIdListData;
        const topicSelectionTagIdList = this.detailForm.topicSelectionTagIdList?.length
          ? [this.detailForm.topicSelectionTagIdList]
          : [];

        let pushParams = {
          authorCode,
          authorId,
          avatar,
          nickname,
          topicSelectionTagIdList,
          materialArr,
          principalId: principalIdListData ? principalIdListData.split(',')[0] : '',
          principalName: principalIdListData ? principalIdListData.split(',')[1] : '',
          carSeriesId: carSeriesIdListData ? carSeriesIdListData.split(',')[0] : '',
          carSeriesName: carSeriesIdListData ? carSeriesIdListData.split(',')[1] : '',
          noteTitle,
          noteDetail,
          noteDetailHtml,
          xhsTopicList,
          copyWritingContentId: this.$route.query.draftId || '',
          dealerId,
          dealerName,
          memberId,
          memberCode,
          memberName,
          notePublishPlanId,
          postTime,
          privacy,
          xhsPreCopywritingId,
          postLoc,
          videoUrl,
          noteType: this.noteType,
        };
        if (this.$route.query.pushId) pushParams.id = this.$route.query.pushId; // 修改逻辑 传id

        // 参数校验
        if (!this.$refs.drawerNoteComp.noteString.trim()) {
          this.$message.info('请输入文案');
          return false;
        }
        if (!this.detailForm.publishAccount) {
          this.$message.info('请选择发布账号');
          this.currentBtn = 1;
          return false;
        }
        if (!dealerId) {
          this.$message.error('该媒体号没有绑定经销商');
          return false;
        }
        if (this.noteType === 'VIDEO' && !videoUrl) {
          this.$message.info('请选择视频');
          return false;
        }
        if (!topicSelectionTagIdList.length) {
          this.$message.info('请选择选题');
          return false;
        }
        if (!noteTitle) {
          this.$message.info('请输入标题');
          return false;
        }
        if (!noteDetail) {
          this.$message.info('请输入文案');
          return false;
        }
        if (!materialArr.length) {
          this.$message.info('请输入图片');
          return false;
        }
        if (!pushParams.principalId) {
          this.$message.info('请选择品牌');
          this.currentBtn = 1;
          return false;
        }
        if (this.detailForm.publishType === 2 && !this.detailForm.postTime) {
          this.$message.info('请选择发布时间');
          this.currentBtn = 4;
          return false;
        }
        let isMin = false;
        if (this.detailForm.publishType === 2) {
          const afterHour = moment().add(1, 'hour').valueOf();
          isMin = this.detailForm.postTime < afterHour;
        }
        if (isMin) {
          this.$message.info('必须选取1个小时后的时间');
          this.currentBtn = 4;
          return false;
        }
        this.publishParams = cloneDeep(pushParams);
        return true;
      } catch (e) {
        return false;
      }
    },

    async handleNoteSave() {
      const pushParams = { ...this.publishParams };
      if (this.currentNoteData.xhsAiContentId) {
        pushParams.xhsAiContentId = this.currentNoteData.xhsAiContentId;
      }
      this.saveLoading = true;
      await this.handleRequest(
        'handlePushToMember',
        async () => {
          this.publishParams = {};
          this.handleClearLocalStorageData();
          const tagList = this.$refs.drawerNoteComp.tagList?.length ? this.$refs.drawerNoteComp.tagList : [];
          localStorage.setItem('localTagList', JSON.stringify(tagList));
          if (pushParams.id && this.fromRouterName == 'operateExecution') {
            this.$router.replace({ name: 'operateExecution' });
            return;
          }
          this.$router.replace({ name: 'xhsNotePush' });
        },
        pushParams
      ).finally(() => (this.saveLoading = false));
    },

    checkDraftRepeat() {
      return new Promise(async (resolve) => {
        const { code, data, message } = await api.getDraftPushStatus({ draftId: this.$route.query.draftId });
        if (code == 200) {
          if (data) {
            this.$message.info('该草稿已被发布');
            resolve(false);
          } else {
            resolve(true);
          }
        } else {
          this.$message.info(message);
          resolve(false);
        }
      });
    },

    handlePublishClick() {
      if (this.saveLoading) return;
      const validate = this.handleNotePublishValidate();
      if (validate) this.publishPopConfirm = true;
    },

    async handlePublishConfirm() {
      this.publishPopConfirm = false;
      const repeatValidate = await this.checkDraftRepeat();
      if (repeatValidate) {
        this.publishParams.aiInvolvementLevel = await this.handleGetAiCheck();
        if (!this.publishParams.aiInvolvementLevel) return;

        this.handleTrack('publish_from_bgz', {
          publish_time: this.publishParams.postTime,
          media_platform: '小红书',
          media_code: this.publishParams.authorCode,
          media_neckname: this.publishParams.nickname,
          media_id: this.publishParams.authorId,
          media_member: this.publishParams.memberName,
          media_brand: undefined,
          note_type: this.noteType === 'IMAGE' ? '图文笔记' : this.noteType === 'VIDEO' ? '视频笔记' : '其他',
          topic: this.currentTagName,
          note_brand: this.publishParams.principalIdListData
            ? this.publishParams.principalIdListData.split(',')[1]
            : undefined,
          note_series: this.publishParams.carSeriesIdListData
            ? this.publishParams.carSeriesIdListData.split(',')[1]
            : undefined,
        });

        this.handleNotePublish();
      } else {
        return false;
      }
    },

    async handleNotePublish() {
      if (this.noteType === 'VIDEO') this.showVideoPublish = true;
      const publishParams = { ...this.publishParams };
      this.saveLoading = true;
      await this.handleRequest(
        'publishNoteFormDraft',
        () => {
          this.publishParams = {};
          const tagList = this.$refs.drawerNoteComp.tagList?.length ? this.$refs.drawerNoteComp.tagList : [];
          localStorage.setItem('localTagList', JSON.stringify(tagList));
          this.$message.success('发布成功');
          this.$router.replace({ name: 'xhsNoteAnalysis' });
          this.handleClearLocalStorageData();
        },
        publishParams
      ).finally(() => {
        this.showVideoPublish = false;
        this.saveLoading = false;
      });
    },

    handleNotePublishValidate() {
      try {
        if (this.$refs.drawerNoteComp.btnLoading) {
          this.$message.info('请稍后');
          return false;
        }

        if (!this.$refs.drawerNoteComp.noteString.trim()) {
          this.$message.info('请输入文案');
          return false;
        }
        const noteDetail = this.$refs.drawerNoteComp.noteString;
        const noteTitle = this.$refs.drawerNoteComp.noteTitle.trim();
        const xhsTopicList = this.$refs.drawerNoteComp.tagList;

        let materialArr = []; // 图片 封面
        if (this.noteType === 'IMAGE') {
          materialArr = this.picList.map((val) => ({ fileId: val.fileId, previewUrl: val.fileUrl }));
        }
        if (this.noteType === 'VIDEO') {
          materialArr = this.$refs.drawerVideoComp.videoData.cover
            ? [{ previewUrl: this.$refs.drawerVideoComp.videoData.cover, fileId: '' }]
            : [];
        }
        if (!this.detailForm.publishAccount) {
          this.$message.info('请选择发布账号');
          this.currentBtn = 1;
          return false;
        }
        const videoUrl = this.noteType === 'VIDEO' ? this.$refs.drawerVideoComp.videoData.url : ''; // 视频
        if (this.noteType === 'VIDEO' && !videoUrl) {
          this.$message.info('请选择视频');
          return false;
        }

        const avatar = this.mediaDetail.authorDetailInfoDTO?.avatar || '';

        const nickname = this.mediaDetail.authorDetailInfoDTO?.nickname || '';

        const authorId = this.detailForm.publishAccount || '';

        const postLoc = this.detailForm.position
          ? {
              name: this.currentPosition.name,
              poiId: this.currentPosition.poiId,
              poiType: this.currentPosition.poiType,
              subname: this.currentPosition.subname,
            }
          : {};
        const postNoteSetting = {
          postLoc,
          postTime:
            this.detailForm.publishType === 1
              ? ''
              : `${moment(this.detailForm.postTime).format('YYYY-MM-DD HH:mm')}:00`,
          privacy: this.detailForm.privacy,
        };

        const isArrayP = Array.isArray(this.detailForm.principalIdListData);
        const principalIdListData = isArrayP
          ? this.detailForm.principalIdListData[0]
          : this.detailForm.principalIdListData;

        const isArrayS = Array.isArray(this.detailForm.carSeriesIdListData);
        const carSeriesIdListData = isArrayS
          ? this.detailForm.carSeriesIdListData[0]
          : this.detailForm.carSeriesIdListData;

        if (!this.detailForm.topicSelectionTagIdList) {
          this.$message.info('请选择选题');
          return false;
        }
        const allTopicList = this.$refs.TopicTreeInput.flattenTree(this.$refs.TopicTreeInput.tagList);
        const selectTag = allTopicList.find((val) => val.id == this.detailForm.topicSelectionTagIdList) || {};
        if (!selectTag.id) {
          this.$message.info('当前选题有误!');
          return false;
        }
        const topicSelectionTagList = [
          {
            id: selectTag.id,
            tagName: selectTag.tagName,
          },
        ];

        let publishParams = {
          authorId,
          avatar,
          nickname,
          topicSelectionTagList,
          materialArr,
          principalId: principalIdListData ? principalIdListData.split(',')[0] : '',
          principalName: principalIdListData ? principalIdListData.split(',')[1] : '',
          carSeriesId: carSeriesIdListData ? carSeriesIdListData.split(',')[0] : '',
          carSeriesName: carSeriesIdListData ? carSeriesIdListData.split(',')[1] : '',
          noteTitle,
          noteDetail,
          xhsTopicList,
          postNoteSetting,
          videoUrl,
          noteType: this.noteType,
          copyWritingContentId: this.$route.query.draftId || '',
        };

        if (this.notePublishPlanId) {
          publishParams.planId = this.notePublishPlanId;
        }

        if (!publishParams.principalId) {
          this.$message.info('请选择品牌');
          return false;
        }

        if (this.currentNoteData.xhsAiContentId) {
          publishParams.xhsAiContentId = this.currentNoteData.xhsAiContentId;
        }

        if (!noteTitle) {
          this.$message.info('请输入标题');
          return false;
        }

        if (!noteDetail) {
          this.$message.info('请输入文案');
          return false;
        }

        if (!materialArr.length) {
          this.$message.info('请输入图片');
          return false;
        }

        if (this.detailForm.publishType === 2 && !this.detailForm.postTime) {
          this.$message.info('请选择发布时间');
          return false;
        }

        let isMin = false;
        if (this.detailForm.publishType === 2) {
          const afterHour = moment().add(1, 'hour').valueOf();
          isMin = this.detailForm.postTime < afterHour;
        }
        if (isMin) {
          this.$message.info('必须选取1个小时后的时间');
          return false;
        }
        this.publishParams = cloneDeep(publishParams);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
  computed: {
    principalIds() {
      if (!this.detailForm.principalIdListData) return [];
      const isArray = Array.isArray(this.detailForm.principalIdListData);
      let principalIdListData = this.detailForm.principalIdListData;
      if (!isArray) {
        principalIdListData = [this.detailForm.principalIdListData];
      }
      return principalIdListData.length > 0
        ? principalIdListData.map((item) => {
            const arr = item.split(',');
            return arr.length > 0 ? arr[0] : '';
          })
        : [];
    },

    btnsList() {
      let list = [
        {
          value: 4,
          isCurrent: false,
          text: '发布信息',
        },
        {
          value: 1,
          isCurrent: false,
          text: '笔记信息',
        },
        {
          value: 2,
          isCurrent: false,
          text: '笔记参考',
        },
        {
          value: 3,
          isCurrent: false,
          text: 'Ai作文 Pro',
        },
        {
          value: 5,
          isCurrent: false,
          text: '智能推荐',
        },
      ];
      if (this.limited) {
        list = list.filter((item) => item.value == 1 || item.value == 3);
      }
      return list;
    },
    editType() {
      let type = '草稿';
      if (this.$route.query.pushId) type = '已推送的笔记';
      return type;
    },
  },
};
</script>

<style scoped lang="less">
.articleDetail {
  position: relative;
  display: flex;
  min-height: 750px;

  .article_left {
    display: flex;
    background-color: #fff;
    min-height: 775px;

    .btn_col {
      position: relative;
      width: 100px;
      border: 1px solid #ccc;
      border-right: 0;

      .btns {
        text-align: center;
        height: 60px;
        line-height: 60px;
        border: 1px solid #d7d7d7;
        cursor: pointer;
        user-select: none;

        // &:hover {
        //   color: #fff;
        //   background-color: rgba(95, 95, 221, 0.233);
        // }
      }

      .ai_loading {
        position: absolute;
        z-index: 1;
        width: 160px;
        background-color: #fff;
      }

      .current_btn {
        color: #fff;
        background-color: #1890ff;
        border: 0;
      }
    }

    .tool_col {
      padding: 15px;
      padding-right: 0;
      width: 345px;
      border: 1px solid #ccc;
      border-left: 0;
      overflow-y: auto;
    }
  }
}

.article_right {
  flex: 1;
  display: flex;
  justify-content: center;

  .right_wrap {
    width: 830px;

    .upload_wrap {
      width: 100%;
      margin-bottom: 15px;
      background: #fff;

      .upload_top {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        border-bottom: 1px solid #d7d7d7;

        .tab-line {
          .tab_item {
            width: 100px;
            height: 36px;
            line-height: 36px;
            font-size: 15px;
            text-align: center;
            cursor: pointer;
          }

          .currentTab {
            color: #1890ff;
            border-bottom: 1px solid #1890ff;
          }
        }
      }

      .upload_bottom {
        width: 100%;
        padding: 0 20px;
        background: #fff;
      }
    }

    .note_wrap {
      padding: 0 20px;
      background: #fff;
    }
  }
}

/deep/ .ant-form-item {
  margin-bottom: 0;
}

.scroll_wrap {
  max-height: 745px;
  overflow-y: auto;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;

    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}

.color-gray {
  color: #8e8e8e;
}

.higher_scroll {
  max-height: 900px;
}

.pre_data_wrap {
  height: 608px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8e8e8e;

  .pre_text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.previewContainer {
  position: relative;
  width: 100%;
  height: 680px;
  background-image: url('../../../../assets/images/xhs_bg.png');
  background-repeat: no-repeat;
  background-size: cover;

  .pBottom {
    position: absolute;
    top: 77px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 535px;
    overflow-y: auto;

    .picArousal {
      width: 100%;
      height: 180px;

      .picArousalItems {
        height: 180px;
        overflow: hidden;

        img {
          margin: 0 auto;
          max-width: 100%;
          max-height: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .previewTitle {
      margin-top: 5px;
      padding: 0 10px;
      font-weight: 600;
      font-size: 12px;
      color: #333;
    }

    .previewContent {
      padding: 0 10px;
      margin: 5px 0;
      outline: none;
      font-size: 13px;
      word-wrap: break-word;
      white-space: pre-wrap;
    }

    .previewTag {
      display: flex;
      flex-wrap: wrap;
      padding: 0 10px;
      margin: 5px 0;
      font-size: 12px;

      .pTags {
        margin-right: 3px;
        color: #3a64ff;
      }
    }

    .previewTime {
      padding: 0 10px;
      font-size: 12px;
      margin: 5px 0;
      color: #666;
      font-size: 12px;
    }

    .fakeComment {
      margin: 0 auto;
      width: 98%;
      height: 196px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.ant-carousel {
  & .slick-arrow {
    width: 25px;
    height: 25px;
    color: #ccc;
    font-size: 25px;

    &::before {
      display: none;
    }
  }

  & .slick-next {
    right: 0;
  }
}

.upload_dialog {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000bd;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .upload_tips {
    width: 600px;
    transform: translate(-150px, 0);
    // min-height: 260px;
    border-radius: 8px;
    background-color: #fff;

    .title {
      margin: 20px;
      font-size: 20px;
      font-weight: bold;
    }

    .text {
      display: flex;
      align-items: center;
      margin: 50px;
      font-size: 18px;
      font-weight: normal;
    }

    .add_form {
      padding: 0 25px 15px;
    }
  }
}

.pre_pic {
  padding: 10px;
  height: 185px;
  width: 100%;

  .pre_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 100%;
    color: #7f7f7f;
    background-color: #f2f2f2;
    user-select: none;
  }

  .pre_fail {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.fix_tips {
  position: fixed;
  padding: 5px 13px;
  top: 18px;
  left: 73%;
  font-weight: bold;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 8px 7px 19px 0px #00000054;
  user-select: none;
}

.gpt_btn {
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  width: 100px;
  border: 1px solid #d7d7d7;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  line-height: 20px;

  &.loading {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
}

.icons {
  width: 15px;
  height: 15px;
}

.addition_tag {
  margin-right: 10px;
  color: #1890ff;
  cursor: pointer;
  user-select: none;
}

.plan-list {
  display: flex;
  border: 1px solid #d7d7d7;
  flex-direction: column;
  border: 1px solid #d7d7d7;
  max-height: 200px;
  overflow-y: auto;

  .plan-item {
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    border-bottom: 1px #ccc solid;
    align-items: center;
    padding: 0 10px;
    user-select: none;
  }

  .unable-item {
    cursor: not-allowed;
    background-color: #f2f2f2;
  }

  .able-item {
    cursor: pointer;
  }

  .current-item {
    background-color: #0d57df3d;
  }

  :last-child {
    border-bottom: 0;
  }
}

.tips-line {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: bolder;
}
</style>
