<template>
  <div class="drawer_right">
    <div class="note_list" ref="article">
      <div class="note_item" v-for="v in articleList" :key="v.strId">
        <template v-if="v.status === 'DONE'">
          <div v-if="v.tagContentArr.length > 0" style="margin-bottom: 10px; padding: 0 15px">
            <span v-for="(t, i) in v.tagContentArr" :key="i">
              <a-tag color="blue" style="margin-bottom: 5px">
                {{ t.tagName }}
              </a-tag>
              <span style="margin: 0 5px 0 -3px" v-show="i !== v.tagContentArr.length - 1">+</span>
            </span>
            <a-icon
              v-show="
                (v.promotionArr && v.promotionArr.length) ||
                (v.aiProductDescriptionArr && v.aiProductDescriptionArr.length)
              "
              style="margin-left: 10px; color: #1890ff; user-select: none; cursor: pointer"
              type="arrows-alt"
              @click="openSellingDetail(v)"
            />
          </div>
          <div class="item_text">{{ v.text }}</div>
          <div class="item_bottom">
            <span class="text_count">{{ v.text.length }}字</span>
            <span>
              <a-tooltip placement="top" title="存入草稿箱">
                <a-icon
                  type="save"
                  style="margin-right: 15px; font-size: 18px; color: #1890ff; cursor: pointer"
                  @click="handleSaveDraft(v)"
                /> </a-tooltip
              ><a-tooltip placement="top" title="复制">
                <a-icon
                  type="copy"
                  style="font-size: 18px; color: #1890ff; cursor: pointer"
                  v-clipboard:copy="v.text"
                  v-clipboard:success="onCopySuccess"
                  v-clipboard:error="onCopyError"
                />
              </a-tooltip>
            </span>
            <!-- <span style="cursor: pointer;"> <a-checkbox></a-checkbox> 选择文案 </span> -->
            <a-button type="link" icon="check" @click="handleChooseText(v)">选择文案</a-button>
          </div>
        </template>
        <template v-if="v.status === 'LOADING'">
          <div style="width: 100%; height: 100px; display: flex; align-items: center; justify-content: center">
            <a-spin :spinning="true"> </a-spin>
          </div>
        </template>
        <template v-if="v.status === 'FAILED'">
          <div class="item_text" style="color: #f00">生成错误：{{ v.message }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MessageTextCompVue from '@/components/MessageTextComp.vue';
import utils from '@/utils/Tool';
import { debounce } from 'lodash';
import api from '@/api/xhsAgencyApi';

export default {
  name: '',
  props: {
    articleList: {
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    openSellingDetail(data) {
      this.$emit('openSellingDetailFromComp', data);
    },

    handleSaveDraft: debounce(async function (v) {
      const params = {
        topicSelectionTagIdList: [],
        materialArr: [],
        principalId: '',
        principalName: '',
        carSeriesId: '',
        carSeriesName: '',
        noteTitle: '',
        noteDetail: v.text,
        noteDetailHtml: v.text,
        xhsTopicList: [],
        name: v.text.slice(0, 15),
        authorCode: '',
        authorId: '',
        postLoc: {},
        privacy: 'PUBLIC',
        postTime: '',
        xhsPreCopywritingId: '',
        videoUrl: '',
        noteType: 'IMAGE',
        xhsAiContentId: v.id,
      };
      const { code, message } = await api.handleSaveCopywriting(params);
      if (code === 200) {
        const link = this.$router.resolve({
          name: 'copywritingList',
        });
        const messageComp = this.$createElement(MessageTextCompVue, {
          props: { message: '已保存草稿，查看笔记可前往', pageName: '草稿箱', link: link.href },
        });
        this.$message.success({
          content: messageComp,
          key: 'darf',
          duration: 3,
        });
      } else {
        this.$message.error(message);
      }
    }, 1000),

    handleChooseText: debounce(
      function ({ text, id }) {
        api.chooseAiContent(id);
        this.$emit('chooseTextFromComp', {
          title: '',
          content: text,
          AiId: id,
        });
      },
      1000,
      { leading: true }
    ),

    scrollToBottom() {
      const item = this.$refs.article;
      utils.scrollBottom(item);
    },

    onCopySuccess() {
      this.$message.success('已复制文案');
    },
    onCopyError() {
      this.$message.error('复制失败');
    },
  },
};
</script>

<style lang="less" scoped>
.drawer_right {
  flex: 1;
  height: 100%;

  .note_list {
    height: 100%;
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 10px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #00152900;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #00152900;
    }

    &::-webkit-scrollbar-corner {
      background: #fff;
    }

    .note_item {
      margin-bottom: 20px;
      padding-top: 10px;
      min-height: 130px;
      border-radius: 8px;
      box-shadow: 1px 2px 6px 1px #00000038;
      border: 1px solid #d7d7d7;

      .item_text {
        margin-bottom: 20px;
        padding: 0 15px;
        min-height: 45px;
        white-space: pre-wrap;
        word-break: break-all;
      }

      .item_bottom {
        position: relative;
        display: flex;
        padding: 0 15px;
        height: 45px;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f2f2f2;

        .text_count {
          position: absolute;
          top: -23px;
          right: 20px;
          font-size: 13px;
          color: #999;
        }
      }
    }
  }
}
</style>
