<template>
  <div class="ai_suggest_note_drawer">
    <a-drawer class="drawer_contain" title="文案" width="450" placement="right" :visible="show" @close="onCloseDrawer">
      <div v-for="value in dataList" :key="value.strId">
        <a-tag v-show="value.type" :color="value.type === 'rewrite' ? '#68cc36' : '#23c6c6'">
          {{ value.type === 'rewrite' ? '爆款改写' : 'Ai作文Pro生成' }}
        </a-tag>
        <template v-if="value.status === 'loading'">
          <div class="contain_item">
            <a-spin></a-spin>
          </div>
        </template>
        <template v-if="value.status === 'error'">
          <div class="contain_item" style="color: #d9001b">
            <div>生成失败</div>
            <div>{{ value.msg }}</div>
          </div>
        </template>
        <template v-if="value.status === 'success'">
          <div class="contain_item" style="justify-content: flex-start">
            <div :class="['note_text', value.showAll ? 'show_all' : '']">{{ value.note }}</div>
            <div class="show_btn" v-show="!value.showAll"></div>
            <div class="btn_line">
              <a-button type="link" @click="value.showAll = !value.showAll">{{
                value.showAll ? '收起' : '展开'
              }}</a-button>
              <a-button type="link" icon="copy" @click="handleCopy(value)">复制</a-button>
            </div>
          </div>
        </template>
      </div>
      <div style="text-align: center; margin: 10px 0" v-show="!dataList.length">暂无数据</div>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          zIndex: 1,
        }"
      >
        <a-button type="primary" :loading="loading" @click="handleGetMore">推荐更多</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  name: 'AiSuggestNoteDrawerComp',
  props: {},
  components: {},
  mixins: [],
  data() {
    return {
      show: false,
      loading: false,
      dataList: [],
    };
  },
  methods: {
    openDrawer() {
      this.show = true;
      this.handleUpdateNew(false);
    },
    async handleGetList(params, autoOpenDrawer) {
      return new Promise(async (resolve) => {
        const strId = new Date().getTime();
        this.dataList.unshift({
          strId,
          status: 'loading',
        });
        this.$nextTick(() => {
          const item = document.getElementsByClassName('ant-drawer-wrapper-body');
          if (item && item.length) {
            item[0].scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        });
        const articleItem = this.dataList.find((v) => v.strId === strId);
        this.$emit('updateLoading', true);
        this.loading = true;
        const { code, data, message } = await api
          .getRecommendDraft(params)
          .catch((err) => {
            articleItem.status = 'error';
            articleItem.msg = err.data;
            return;
          })
          .finally(() => (this.loading = false));
        if (code && code == 200) {
          let newList = [];
          if (data.hotGenerate && data.hotGenerate.length) {
            data.hotGenerate.map((v) => {
              const strIds = Math.random().toString(36).substring(3, 10);
              if (v.isSuccess) {
                newList.push({
                  strId: strIds,
                  status: 'success',
                  type: 'rewrite',
                  note: v.aigcAiRewriteToolRunVO.rewrite_content,
                  showAll: false,
                  generateLogId: v.generateLogId || undefined,
                });
              } else {
                newList.push({
                  strId: strIds,
                  type: 'rewrite',
                  status: 'error',
                  msg: v.errorMsg,
                });
              }
            });
          }
          if (data.xhsAiContentGenerateVOList && data.xhsAiContentGenerateVOList.length) {
            data.xhsAiContentGenerateVOList.map((v) => {
              const strIds = Math.random().toString(36).substring(3, 10);
              if (v.isSuccess) {
                newList.push({
                  strId: strIds,
                  status: 'success',
                  type: 'aiPro',
                  note: v.finalAiContent,
                  showAll: false,
                  generateLogId: v.generateLogId || undefined,
                });
              } else {
                newList.push({
                  strId: strIds,
                  type: 'aiPro',
                  status: 'error',
                  msg: v.errorMsg,
                });
              }
            });
          }
          const index = this.dataList.findIndex((item) => item.strId === strId);
          if (newList.length) {
            this.dataList.splice(index, 1, ...newList);
            if (!autoOpenDrawer && !this.show) this.handleUpdateNew(true);
          } else {
            articleItem.status = 'error';
            articleItem.msg = '生成失败';
          }
        } else {
          articleItem.status = 'error';
          articleItem.msg = message;
          this.$message.error(message || '请求失败');
        }
        if (autoOpenDrawer) this.show = true;
        this.$emit('updateLoading', false);
        resolve();
      });
    },
    handleGetMore() {
      this.$emit('getMore');
    },
    async handleCopy(data) {
      this.$copyText(data.note)
        .then(() => {
          this.$message.success('复制成功');
        })
        .catch(() => {
          this.$message.error('复制失败');
        });
      data.generateLogId && api.handleRecommendCopy(data.generateLogId);
    },
    handleUpdateNew(val) {
      this.$emit('updateNew', val);
    },
    onCloseDrawer() {
      this.show = false;
      this.handleUpdateNew(false);
    },
  },
};
</script>

<style lang="less" scoped>
.contain_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin-bottom: 10px;
  min-height: 100px;
  border: 1px solid #ccc;
  white-space: break-spaces;
  position: relative;

  .note_text {
    min-height: 100px;
    max-height: 200px;
    overflow: hidden;
  }

  .show_all {
    max-height: none;
  }
}

.show_btn {
  height: 50px;
  line-height: 50px;
  width: 100%;
  position: absolute;
  bottom: 40px;
  left: 0;
  text-align: center;
  background-image: linear-gradient(to bottom, #ffffff70 0%, #fff 100%);
}

.btn_line {
  display: flex;
  justify-content: space-between;
}

/deep/ .ant-drawer-body {
  padding: 24px 24px 50px;
}
</style>
