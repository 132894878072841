var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ai_suggest_note_drawer"},[_c('a-drawer',{staticClass:"drawer_contain",attrs:{"title":"文案","width":"450","placement":"right","visible":_vm.show},on:{"close":_vm.onCloseDrawer}},[_vm._l((_vm.dataList),function(value){return _c('div',{key:value.strId},[_c('a-tag',{directives:[{name:"show",rawName:"v-show",value:(value.type),expression:"value.type"}],attrs:{"color":value.type === 'rewrite' ? '#68cc36' : '#23c6c6'}},[_vm._v(" "+_vm._s(value.type === 'rewrite' ? '爆款改写' : 'Ai作文Pro生成')+" ")]),(value.status === 'loading')?[_c('div',{staticClass:"contain_item"},[_c('a-spin')],1)]:_vm._e(),(value.status === 'error')?[_c('div',{staticClass:"contain_item",staticStyle:{"color":"#d9001b"}},[_c('div',[_vm._v("生成失败")]),_c('div',[_vm._v(_vm._s(value.msg))])])]:_vm._e(),(value.status === 'success')?[_c('div',{staticClass:"contain_item",staticStyle:{"justify-content":"flex-start"}},[_c('div',{class:['note_text', value.showAll ? 'show_all' : '']},[_vm._v(_vm._s(value.note))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!value.showAll),expression:"!value.showAll"}],staticClass:"show_btn"}),_c('div',{staticClass:"btn_line"},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){value.showAll = !value.showAll}}},[_vm._v(_vm._s(value.showAll ? '收起' : '展开'))]),_c('a-button',{attrs:{"type":"link","icon":"copy"},on:{"click":function($event){return _vm.handleCopy(value)}}},[_vm._v("复制")])],1)])]:_vm._e()],2)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dataList.length),expression:"!dataList.length"}],staticStyle:{"text-align":"center","margin":"10px 0"}},[_vm._v("暂无数据")]),_c('div',{style:({
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        zIndex: 1,
      })},[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleGetMore}},[_vm._v("推荐更多")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }