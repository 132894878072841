<template>
  <!-- 文案素材 -->
  <div class="writting_template">
    <div class="scroll_wrap higher_scroll">
      <a-spin :spinning="spinning" size="large" tip="加载中...">
        <div class="template_wrap" v-for="(val, i) in templateList" :key="i">
          <template v-if="val.title">
            <div style="white-space: pre-wrap">{{ val.title }}</div>
            <div class="dash_line"></div>
          </template>
          <div style="white-space: pre-wrap">
            {{ val.content }}
          </div>
          <div style="display: flex; justify-content: flex-end; margin-top: 5px">
            <a-space>
              <a-button v-clipboard:copy="val.content" v-clipboard:success="onCopy" v-clipboard:error="onCopyError"
                >复制</a-button
              >
              <a-button @click="handlePush(val)">填充至笔记</a-button>
            </a-space>
          </div>
        </div>
        <div style="text-align: center; color: #ccc; height: 100px; line-height: 100px" v-show="!templateList.length">
          该选题暂无模版
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  components: {},
  props: {
    tagId: {
      default: undefined,
    },
    searchKeyword: {
      default: '',
    },
    principalIdListData: {
      default: () => [],
    },
    carSeriesIdListData: {
      default: () => [],
    },
  },
  data() {
    return {
      templateList: [],
      spinning: false,
    };
  },
  computed: {},
  methods: {
    async getDataList() {
      if (this.spinning) return;
      if (!this.tagId) return (this.templateList = []);
      this.spinning = true;
      const principalId = this.principalIdListData?.length ? this.principalIdListData.split(',')[0] : undefined;
      const carSeriesId = this.carSeriesIdListData?.length ? this.carSeriesIdListData.split(',')[0] : undefined;
      const params = { tagId: this.tagId, limit: 8, keyword: this.searchKeyword, principalId, carSeriesId };
      const { code, data, message } = await api.getRandomTemplateList(params).finally(() => (this.spinning = false));
      if (code == 200) {
        const reg = new RegExp('\r\n', 'g');
        this.templateList = data.map((val) => {
          val.content = val.template?.replace(reg, '<br >') || '';
          return val;
        });
      } else {
        this.$message.error(message);
      }
    },
    onCopy() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.error('复制失败，该浏览器不支持自动复制');
    },
    handlePush(val) {
      this.$emit('useTemplate', {
        title: val.title.trim() || null,
        content: val.template.trim(),
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dash_line {
  margin: 10px 0;
  height: 1px;
  border-top: 1px dashed #0000008e;
}

.scroll_wrap {
  max-height: 713px;
  overflow-y: auto;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}

.higher_scroll {
  max-height: 900px;
}

.template_wrap {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #e9caca;
}
</style>
